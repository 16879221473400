<template>
    <div class="contentbar" v-bind:class="{ 'contentbar-open': content_bar.type, 'notification-open': notification_open }">
        <div class="contentbar-close" v-on:click="close"><v-icon name="x"></v-icon></div>
        <FieldAdd v-if="content_bar.type=='field.add'" :data="content_bar.data"></FieldAdd>
        <FieldEdit v-if="content_bar.type=='field.edit'" :data="content_bar.data"></FieldEdit>
        <RelationAdd v-if="content_bar.type=='relation.add'" :data="content_bar.data"></RelationAdd>
        <RelationEdit v-if="content_bar.type=='relation.edit'" :data="content_bar.data"></RelationEdit>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FieldAdd from '@/components/ContentBar/FieldAdd.vue'
import FieldEdit from '@/components/ContentBar/FieldEdit.vue'
import RelationAdd from '@/components/ContentBar/RelationAdd.vue'
import RelationEdit from '@/components/ContentBar/RelationEdit.vue'

export default {
    data: function () {
        return {
            type: 'field'
        }
    },
    mounted: function () {
        //
    },
    computed: {
        ...mapState({
            content_bar: state => state.ui.content_bar,
            notification_open: state => state.ui.notification
        })
    },
    methods: {
        close: function () {
            this.$store.commit('ui/setContentBar', { type: null, id: null })
        }
    },
    components: {
        FieldAdd,
        FieldEdit,
        RelationAdd,
        RelationEdit
    }
}
</script>