<template>
    <div class="fileuploader-files-file fade-in" v-bind:class="{ 'deleting': deleting, 'file': type === 'file' }">
        <ul class="fileuploader-files-file-actions" v-if="!uploading">
            <li v-tooltip="file.name" v-on:click="previewURL"><v-icon name="search"></v-icon></li>
            <li v-if="0" v-tooltip="'Download'"><v-icon name="download"></v-icon></li>
            <li v-if="multiple" class="draggable-handle" v-tooltip="'Reorder'"><v-icon name="menu"></v-icon></li>
            <li v-if="!multiple" v-on:click="selectFile()" v-tooltip="'Swap'"><v-icon name="repeat"></v-icon></li>
            <li v-on:click="deleteFileTile()" v-tooltip="'Remove'"><v-icon name="x"></v-icon></li>
        </ul>
        <div v-if="type === 'image'">
            <div class="fileuploader-files-file-thumbnail" v-bind:class="{ 'loading': uploading }" :style="{ 'background-image': 'url(' + file.url + ')' }"></div>
            <div class="fileuploader-files-file-loader" v-bind:class="{ 'active': uploading }"></div>
        </div>
        <div v-else>
            <div class="fileuploader-files-file-thumbnail" v-bind:class="{ 'file': type === 'file' }" v-bind:style="{ 'background': color }">
                {{ extension }}
            </div>
            <div class="fileuploader-files-file-loader" v-bind:class="{ 'active': uploading }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['file', 'type', 'multiple', 'deleteFile', 'selectFile', 'gallery'],
    data: function () {
        return {
            images: [],
            deleting: false
        }
    },
    created: function() {
        this.setGallery()
    },
    computed: {
        uploading: function () {
            return this.file.file_id.includes('$')
        },
        color: function () {
            switch(this.file.extension) {
                case 'doc':
                    return '#5270C8'
                case 'jpg':
                    return '#EAB684'
                case 'webp':
                    return '#FF6600'
                case 'png':
                    return '#704F7A'
                case 'psd':
                    return '#4C4EF6'
                case 'eps':
                    return '#FF6666'
                case 'cdr':
                    return '#009934'
                case 'txt':
                    return '#666666'
                case 'gif':
                    return '#FF999A'
                case 'ppt':
                    return '#F37F49'
                case 'mp3':
                    return '#00CCFF'
                case 'wav':
                    return '#27B8B5'
                case 'ai':
                    return '#FF6600'
                case 'mov':
                    return '#2374A8'
                case 'exe':
                    return '#F0C000'
                case 'dmg':
                    return '#B6B6B6'
                case 'rar':
                    return '#663331'
                case 'pdf':
                    return '#E72D2F'
                case 'zip':
                default:
                    return '#983365'
            }
        },
        extension: function () {
            return this.file.extension ? '.' + this.file.extension : '.exe'
        }
    },
    methods: {
        setGallery: function() {
            if(this.type != 'image') return

            this.images.push(this.file.url.replace('/previews', ''))
            if(this.gallery && Array.isArray(this.gallery)) {
                this.images = this.images.concat(this.gallery.map(image => image.type == 'image' && image.url ? image.url.replace('/previews', '') : null).filter(Boolean))
            }
        },
        previewURL: function () {
            if (this.type != 'image') {
                window.open(this.file.url, '_blank');
                return
            }
            this.$viewerApi({
                options: {
                    toolbar: false,
                    title: false,
                    navbar: false,
                    movable: false,
                    rotatable: false
                },
                images: this.images
            })
        },
        deleteFileTile: function () {
            this.deleting = true
            setTimeout(() => {
                this.deleteFile(this.file.id)
            }, 150)
        }
    }
}
</script>