<template>
    <div>
        <BasePreloader v-if="loading"></BasePreloader>
        <div v-else-if="fields.length">
            <div class="editor-topbar" v-bind:class="{ 'editor-topbar-modal': modal }" v-if="show_field_forms">
                <div class="editor-topbar-bar" v-bind:class="{'editor-topbar-bar-compact': compact_topbar }">
                    <div class="editor-topbar-bar-buttons" v-if="!modal">
                        <a class="button button-icon-only m-l-2" v-if="entry_id" v-on:click="deleteEntry" v-tooltip="'Remove entry'"><v-icon name="trash"></v-icon></a>
                        <a class="button p-l-6 p-r-6" v-on:click="saveFieldsData" v-shortkey="['meta', 's']" v-on:shortkey="saveFieldsData">Save entry</a>
                    </div>
                    <h3 class="m-0" v-if="entry_id">Entry</h3>
                    <h3 class="m-0" v-else>Data</h3>

                    <ul class="editor-topbar-bar-translatable" v-if="languages.length">
                        <li v-for="language in languages"
                            :key="language.code"
                            :class="{active: isActiveLanguage(language.code)}"
                            v-on:click="setActiveLanguage(language.code)"
                            v-text="language.name"
                        ></li>
                    </ul>
                </div>
            </div>
            <form class="form padding p-t-0" action="submit" v-on:submit.prevent="saveFieldsData" :class="{ 'form-saving': saving }">
                <div class="layout" v-bind:class="{ 'layout-entry': relations.length }">
                    <div v-if="show_field_forms">
                        <FieldForm v-for="field in ungrouped_fields" :entry="entry" :key="field.id" :field="field" :active_language="active_language"></FieldForm>
                    </div>
                    <div>
                        <EntryRelationForm v-for="relation in relations" :key="relation.id" :relation="relation" :entry="entry"></EntryRelationForm>
                    </div>
                </div>
                <input class="button" :class="{'m-l-6': modal, 'hidden': !modal}" type="submit" value="Save entry">
            </form>
        </div>
        <div class="padding" v-else>
            <BaseNoResults>
                <v-icon name="alert-triangle"></v-icon>
                <div v-if="can('create-field')">
                    <h4 class="m-b-3">Go to fields</h4>
                    <p>Please add at least one field first.</p>
                    <router-link :to="module.link + '/fields?add=true'" class="button">To fields</router-link>
                </div>
                <div v-else>
                    <h4 class="m-b-3">No fields created</h4>
                    <p>Please contact an administrator.</p>
                </div>
            </BaseNoResults>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Project from '@/models/Project.js'
import Entry from '@/models/Entry.js'
import Field from '@/models/Field.js'
import Relation from '@/models/Relation.js'
import RelationEntry from '@/models/RelationEntry.js'
import FieldData from '@/models/FieldData.js'
import FieldForm from '@/components/FieldForm.vue'
import EntryRelationForm from '@/components/EntryRelationForm.vue'
import Event from '@/libraries/eventbus'
import Languages from '@/definitions/languages.js'

export default {
    props: ['module', 'entry_id', 'modal'],
    data: function() {
        return {
            loading: false,
            saving: false,
            entry: null,
            active_language: null
        }
    },
    created: function () {
        if(this.is_list && !this.can('view-entry')) {
            return this.$router.push(this.module.link + '/entries')
        }
        this.initEntry()
        // this.active_language = this.project ? this.project.language_code : null
    },
    beforeDestroy: function () {
        Field.update({ where(field) { return field.has_error }, data: { has_error: false }})
    },
    computed: {
        ...mapState({
            content_scroll_position: state => state.ui.content_scroll_position
        }),
        compact_topbar: function () {
            return this.content_scroll_position > 30
        },
        is_list: function () {
            return this.module.module_type === 'lists'
        },
        project: function () {
            return Project.slug(this.$route.params.project)
        },
        fields: function () {
            return Field.query()
                .where('module_id', this.module.id)
                .where('module_type', this.module.module_type)
                .orderBy('position')
                .withAll()
                .get()
        },
        ungrouped_fields: function () {
            return this.fields.filter(field => !field.group_id)
        },
        show_field_forms: function () {
            return this.entry || ! this.is_list
        },
        relations: function () {
            const relation_ids = this.module.relations ? this.module.relations.map(relation => relation.id) : []
            return Relation.query().whereIdIn(relation_ids).orderBy('position').withAll().get()
        },
        languages: function () {
            if(!this.project || !this.project.project_languages.length) {
                return []
            }

            let project_languages = [ ...this.project.project_languages ]
            project_languages.unshift(this.project.language_code)

            const languages = []

            for(let i=0; i < project_languages.length; i ++) {
                let language = Languages.find(language => language.code == project_languages[i])
                if(language) {
                    languages.push(language)
                }
            }

            return languages
        },
        project_language: function () {
            return this.project ? this.project.language_code : null
        }
    },
    methods: {
        initEntry: function () {
            if(! this.is_list) return this.$emit('loaded')
            if(! this.entry_id) return this.insertEntry()
            return this.loadEntry()
        },
        insertEntry: function () {
            this.loading = true
            Entry.insert({
                data: {
                    list_id: this.module.id
                }
            }).then(entities => {
                this.loading = false
                this.entry = entities['entries'][0]
                this.$emit('loaded')
            })
        },
        loadEntry: function () {
            this.loading = true
            Entry.dispatch('single', {
                id: this.entry_id,
                with: [
                    'field_data.field',
                    'field_data.field_data_files',
                    'field_data.parent_field_data_images',
                    'field_data.field_data_translations.project_language',
                ],
                where_has: { list: this.module.id }
            }).then(() => {
                this.entry = Entry.find(this.entry_id)
                this.loading = false
                this.$emit('loaded')
            })
        },
        deleteEntry: function () {
            if(! this.entry_id) {
                return false
            }

            this.$confirm({
                message: 'Are you sure you want to remove this entry?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        Entry.dispatch('remove', this.entry).then(() => {
                            Event.dispatch('entry.deleted', this.entry)
                        })
                        this.$emit('deleted')
                    }
                }
            })
        },
        saveFieldsData: function () {
            if(this.saving) return false
            this.saving = true
            Field.update({ where(field) { return field.has_error }, data: { has_error: false }})
            Event.dispatch('field_form.update_field_data.' + this.module.id)
            const field_data = {}

            var entry_id = this.entry ? this.entry.id : null
            this.fields.filter(field => field.savable_value).forEach(field => {
                const result = FieldData.query()
                    .where('field_id', field.id)
                    .where('entry_id', entry_id)
                    .orderBy(field_data => field_data.creating)
                    .first()

                field_data[field.id] = {
                    field_id: field.id,
                    entry_id: this.entry_id,
                    value: result ? result.value : field.value,
                    translations: result ? result.translations : {}
                }
            })

            Field.dispatch('saveFieldsData', field_data).then(response => {
                if (response.success) {
                    Event.dispatch('field_data.saved', response.data)
                } else {
                    Event.dispatch('field.errors', this.transmuteErrors(response.data.errors))
                }
                const original_entry_id = entry_id
                var new_entry = entry_id !== this.entry_id
                entry_id = response.success && response.data[0].entry ? response.data[0].entry.id : null
                this.$emit('saved', { ...response, ...{ entry_id: entry_id, new_entry: new_entry, }})

                this.saving = false

                if(!new_entry) {
                    return
                }

                this.addRelationEntries(original_entry_id, entry_id)
            })
        },
        addRelationEntries: function (original_entry_id, entry_id) {
            const relation_entries = RelationEntry.query().where('target_entry_id', original_entry_id).get()
            let success_count = 0
            const relation_entry_count = relation_entries.length
            for(let i = 0; i < relation_entry_count; i++) {
                let relation_entry = relation_entries[i]
                var relation_id = relation_entry.relation_id
                Relation.dispatch('saveEntry', {
                    id: relation_entry.relation_id,
                    entry_id: relation_entry.entry_id,
                    target_entry_id: entry_id
                }).then((response) => {
                    if(response.success) {
                        success_count +=1
                        if(relation_id && success_count == relation_entry_count) {
                            Event.dispatch('relation.entry.created.' + relation_id)
                        }
                    }
                })
            }

        },
        transmuteErrors: function(original_errors) {
            const errors = {}
            for (const key in original_errors) {
                const actual_key = key.replace('.value', '')
                const original_error = original_errors[key]

                const field = Field.find(actual_key)
                Field.update({ where: field.id, data: { has_error: true } })
                if(!field) return

                errors[field.name] = original_error.map(error => error.replace(key, field.name.toLowerCase()))
            }

            return errors
        },
        setActiveLanguage: function (code) {
            if(code == this.project_language) {
                code = null
            }
            if(!this.isActiveLanguage(code)) {
                this.active_language = code
            }
        },
        isActiveLanguage: function (code) {
            return this.active_language == code || (!this.active_language && this.project_language == code)
        }
    },
    components: {
        FieldForm,
        EntryRelationForm
    }
}
</script>
