import Notification from '@/models/Notification.js'

export default {
    getters: {
        //
    },
    actions: {
        launch: function (store, payload) {
            Notification.insert({
                data: payload
            })
        },
    }
}