<template>
     <div class="top-notification" v-bind:class="{ 'top-notification-open': notification }" v-html="notification"></div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {  
        computed: {
            ...mapState({
                notification: state => state.ui.notification
            })
        }
    }
</script>