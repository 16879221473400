<template>
    <div class="notification-errors">
        <div class="notification-errors-error" v-for="(error, field) in errors" v-bind:key="field">
            <span class="field">{{ field }}</span> {{ error[0] }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['errors']
}
</script>