import './bootstrap'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/sass/app.scss'
import './router/guards'
import './events'
import './listeners'

Vue.config.productionTip = true
Vue.config.devtools = true

store.dispatch('auth/user').then(() => {
	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app')
})
