<template>
    <div class="contentbar-contents">
        <div class="fade-in">
            <div class="padding">
                <h3>Relations</h3>

                <ul class="field-types" v-if="lists.length">
                    <li class="field-type" v-for="list in lists" v-bind:key="list.id" v-on:click="createRelationFromList(list)">
                        <div class="field-type-icon"><v-icon :name="list.icon"></v-icon></div>
                        <div class="field-type-name">{{ list.name }}</div>
                    </li>
                </ul>
                <div v-else>
                    <p>There are no available lists to add as a relation.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import List from '@/models/List'
import Relation from '@/models/Relation'

export default {
    props: ['data'],
    computed: {
        lists: function () {
            return List.query().where('project_id', this.data.module.project_id).where(list => list.id !== this.data.module.id || list.module_type !== this.data.module.module_type).orderBy('position').get()
        }
    },
    methods: {
        createRelationFromList: function (list) {
            Relation.insert({
                data: {
                    name: list.name,
                    icon: list.icon,
                    module_id: this.data.module.id,
                    module_type: this.data.module.module_type,
                    related_module_id: list.id,
                    related_module_type: list.module_type
                }
            }).then((entities) => {
                this.$store.commit('ui/setContentBar', { type: 'relation.edit', data: entities['relations'][0].id })
            })
        }
    }
}
</script>