const plugin = {
    install (components) {
        components.Model.slug = function (slug) {
            var query = this.query()
            query.where('slug', slug)
            query.withAll()
            // query.withAllRecursive()
            return query.first()
        }
    }
}

export default plugin