<template>
    <div>
        <form class="form" action="submit" v-on:submit.prevent="save">
            <div class="form-group">
                <label>Name<small>Required</small></label>
                <input class="text-input" type="text" v-model="field.name" v-autofocus>
                <div class="slug-input fade-in" v-if="field.slug && !slug_editor">Slug: <span class="slug-input-slug" v-on:click="toggleSlugEditor">{{ field.slug ? field.slug : 'new-field' }}</span></div>
            </div>
            <div class="form-group fade-in" v-if="slug_editor">
                <label>Slug<small>Unique</small></label>
                <input class="text-input" type="text" v-model="field.slug" autofocus>
                <p class="small m-t-1">Unique slug for building queries</p>
            </div>
            <div class="form-group">
                <label>Description<small>Optional</small></label>
                <input class="text-input" type="text" v-model="field.description">
                <p class="small m-t-1">Show extra information for this field</p>
            </div>
            <input class="button hidden" type="submit">
        </form>
    </div>
</template>

<script>
export default {
    props: ['field', 'save'],
    data: function () {
        return {
            custom_slug: false,
            slug_editor: false
        }
    },
    methods: {
        toggleSlugEditor: function () {
            this.slug_editor = !this.slug_editor
        }
    },
    watch: {
        'field.name': function (input) {
            if(!input) return null
            if(!this.field.creating && this.field.slug) return null
            if (this.custom_slug || this.slug_editor) return null
            return this.field.slug = this.slug(input ? input : '')
        }
    }
}
</script>
