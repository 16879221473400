<template>
    <div class="page" v-if="can('update-project') && can('view-project-users')">
        <div class="page-contents">
            <div class="limit-content-width">
                <div class="padding">
                    <div class=" flex flex-center m-b-3">
                        <h2 class="flex-1 m-b-0">Project members</h2>
                        <a class="button" v-on:click="openNewMemberModal()">Add member</a>
                    </div>

                    <p class="lead">Here you can add or update members to work on this project.</p>

                    <div class="table table-border" v-if="has_members">
                        <div class="table-head">
                            <div class="table-tr">
                                <div class="table-td">Member</div>
                                <div class="table-td">Role</div>
                                <div class="table-td">Status</div>
                                <div class="table-td text-align-right"></div>
                            </div>
                        </div>
                        <div class="table-body">
                            <div class="table-tr" v-for="member in members" :key="member_component_key + '_' + member.id">
                                <Member :project="project" :member="member" v-on:toggle-dropdown="toggleDropDown"></Member>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Member from '@/components/Tables/ProjectMember.vue'
import Modal from '@/models/Modal.js'

export default {
    props: ['project'],
    data: function () {
        return {
            members: [],
            member_component_key: 0,
            modals: {
                new_member: false
            }
        }
    },
    created: function () {
        if(this.project) {
            this.setMembers(this.project.users)
        }
    },
    computed: {
        has_members: function() {
            return this.members && this.members.length
        }
    },
    methods: {
        openNewMemberModal: function () {
            Modal.dispatch('launch', {
                type: 'member.editor',
                name: 'Add new member',
                variant: 'small',
                data: {
                    type: 'project',
                    project_id: this.project.id,
                }
            })
        },
        toggleDropDown: function(member_id) {
            const members = this.members.map(member => {
                member.actions_active = member.id == member_id ? !member.actions_active : false
                return member
            })

            this.$nextTick(function () {
                this.members = members
                this.member_component_key ++
            })
        },
        setMembers: function(users) {
            const members = users && users.length ? users : []
            this.members = members.map(member => {
                member.actions_active = false
                return member
            })
        }
    },
    watch: {
        'project.users': function (users) {
            this.setMembers(users)
        }
    },
    components: {
        Member
    }
}
</script>
