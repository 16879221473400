<template>
    <div class="icon-selector">
        <div class="icon-selector-filter">
            <form class="form" action="submit" v-on:submit.prevent="takeFirstFiltered">
                <div class="icon-selector-filter-surprise-me" v-on:click="supriseMe">Suprise me</div>
                <div class="icon-selector-filter-search-icon"><v-icon name="search"></v-icon></div>
                <input type="text" class="icon-selector-filter-input" v-model="filter" placeholder="Search icons" v-autofocus="use_autofocus">
            </form>
        </div>
        <div class="icon-selector-list">
            <div class="icon-selector-list-icon" v-for="icon in icons" :key="icon" v-bind:class="{ 'selected': icon === selected }" v-on:click="select(icon)"><v-icon :name="icon" width="40"></v-icon></div>
            <div v-if="!icons.length" class="p-t-1 p-b-1">Nothing found</div>
        </div>
    </div>
</template>

<script>
import IconDefinitions from '@/definitions/icons.js'

export default {
    props: ['selected', 'select'],
    data: function () {
        return {
            filter: ''
        }
    },
    computed: {
        icons: function () {
            return IconDefinitions.filter(str => str.match( "^" + this.filter, "i"));
        },
        use_autofocus: function () {
            return this.selected ? true : false
        }
    },
    methods: {
        takeFirstFiltered: function () {
            if (!this.icons.length || !this.filter) return
            this.select(this.icons[0])
        },
        supriseMe: function () {
            var icon = IconDefinitions[Math.floor(Math.random() * IconDefinitions.length)];
            this.select(icon)
        }
    }
}
</script>
