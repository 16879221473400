<template>
    <div v-if="field">
        <div class="contentbar-tabs">
            <ul>
                <li v-bind:class="{ 'active': tab == 'edit' }" v-on:click="setTab('edit')"><v-icon name="edit"></v-icon></li>
                <li v-bind:class="{ 'active': tab == 'options' }" v-on:click="setTab('options')" v-if="show_options"><v-icon name="list"></v-icon></li>
                <li v-bind:class="{ 'active': tab == 'versions' }" v-on:click="setTab('versions')" v-if="show_versions"><v-icon name="layers"></v-icon></li>
                <li v-bind:class="{ 'active': tab == 'validation' }" v-on:click="setTab('validation')" v-if="show_validation && 0"><v-icon name="check-square"></v-icon></li>
                <li v-bind:class="{ 'active': tab == 'settings' }" v-on:click="setTab('settings')" v-if="show_settings"><v-icon name="settings"></v-icon></li>
            </ul>
        </div>
        <div class="contentbar-save" v-bind:class="{ 'active': unsaved_changes }" v-on:click="saveField">
            Save
        </div>
        <div class="contentbar-contents contentbar-contents-tab">
            <div v-if="tab == 'edit'">
                <div class="padding fade-in">
                    <h3>Edit <small v-if="unsaved_changes">Unsaved</small></h3>
                    <FieldEditGlobal :field="field" :save="saveField"></FieldEditGlobal>
                </div>
            </div>
            <div v-if="show_options && tab == 'options'">
                <div class="padding fade-in">
                    <h3>Options <small v-if="unsaved_changes">Unsaved</small></h3>
                    <FieldEditOptions :field="field" :save="saveField"></FieldEditOptions>
                </div>
            </div>
            <div v-if="show_versions && tab == 'versions'">
                <div class="padding fade-in">
                    <h3>Versions <small v-if="unsaved_changes">Unsaved</small></h3>
                    <FieldEditVersions :field="field" :save="saveField"></FieldEditVersions>
                </div>
            </div>
            <div v-if="show_validation && tab == 'validation'">
                <div class="padding fade-in">
                    <h3>Validation rules <small v-if="unsaved_changes">Unsaved</small></h3>
                    <FieldEditValidation :field="field" :save="saveField"></FieldEditValidation>
                </div>
            </div>
            <div v-if="show_settings && tab == 'settings'">
                <div class="padding fade-in">
                    <h3>Settings <small v-if="unsaved_changes">Unsaved</small></h3>
                    <FieldEditSettings :field="field" :save="saveField"></FieldEditSettings>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Event from '@/libraries/eventbus.js'
import Field from '@/models/Field.js'
import FieldEditGlobal from '@/components/ContentBar/FieldEditGlobal.vue'
import FieldEditOptions from '@/components/ContentBar/FieldEditOptions.vue'
import FieldEditVersions from '@/components/ContentBar/FieldEditVersions.vue'
import FieldEditValidation from '@/components/ContentBar/FieldEditValidation.vue'
import FieldEditSettings from '@/components/ContentBar/FieldEditSettings.vue'

export default {
    props: ['data'],
    data: function () {
        return {
            tab: 'edit',
            field: null,
            saving: false
        }
    },
    mounted: function () {
        this.field = this.clone(this.field_instance)
        this.field.creating = this.field_instance.creating
    },
    beforeDestroy: function () {
        if(this.field.creating) Field.delete(this.field.id)
    },
    computed: {
        field_instance: function () {
            return Field.find(this.data)
        },
        show_options: function () {
            if(this.field.type == 'select') return true
            return false
        },
        show_versions: function () {
            if(this.field.type == 'image') return true
            return false
        },
        show_validation: function () {
            if(this.field.type == 'text') return true
            if(this.field.type == 'textarea') return true
            return false
        },
        show_settings: function () {
            if(this.field.type == 'group') return false
            return true
        },
        unsaved_changes: function () {
            return !_.isEqual(this.field, this.clone(this.field_instance)) && !this.saving
        }
    },
    methods: {
        setTab: function (tab) {
            this.tab = tab
        },
        saveField: function () {
            if (!this.unsaved_changes) return

            this.saving = true
            if (this.field.creating) this.createField()
            if (!this.field.creating) this.updateField()
        },
        createField: function () {
            Field.dispatch('post', this.field).then(response => {
                this.saving = false
                if (response.success) {
                    Event.dispatch('field.saved', this.field)
                    this.field.creating = false
                    this.$store.commit('ui/setContentBar', { type: 'field.edit', data: response.data.id })
                } else {
                    Event.dispatch('field.errors', response.data.errors)
                }
            })
        },
        updateField: function () {
            Field.dispatch('patch', this.field).then(response => {
                this.saving = false
                if (response.success) {
                    this.field = this.clone(this.field_instance)
                    Event.dispatch('field.saved', this.field)
                } else {
                    Event.dispatch('field.errors', response.data.errors)
                }
            })
        }
    },
    watch: {
        field_instance: function (data, previous) { 
            if (!this.field_instance) return
            if(data && previous && data.id === previous.id) return
            this.field = this.clone(this.field_instance)
            if(data && previous && data.type !== previous.type) this.tab = 'edit'
            if(previous && previous.creating) Field.delete(previous.id)
        }
    },
    components: {
        FieldEditGlobal,
        FieldEditOptions,
        FieldEditVersions,
        FieldEditValidation,
        FieldEditSettings
    }
}
</script>