<template>
    <div class="contents" v-bind:class="{ 'sidebar-open': sidebar_open, 'contentbar-open': content_bar_open, 'notification-open': notification_open }">
        <BasePreloader v-if="projects_loading"></BasePreloader>
        <router-view v-else class="fade-in"></router-view>
    </div>
</template>
 
<script>
    import { mapState } from 'vuex'
    import Project from '@/models/Project.js'

    export default {
        computed: {
            active_project: function () {
                if(!this.$route.params.project) return null
                return Project.query().first()
            },
            sidebar_open: function () {
                return this.active_project ? true : false
            },
            ...mapState({
                projects_loading: state => state.entities.projects.loading,
                content_bar_open: state => state.ui.content_bar.type,
                notification_open: state => state.ui.notification
            })
        }
    }
</script>