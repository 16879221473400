export default {
    namespaced: true,
    state: {
        notification: null,
        // notification: 'Welcome to the beta version of Vume, we hope you enjoy your stay. <a href="mailto:hello@vume.io">Contact us for questions</a>',
        content_bar: {
            type: null,
            data: {}
        },
        project_menu: {
            open: false
        },
        user_menu: {
            open: false
        },
        dark_mode: false,
        dragging: false,
        content_scroll_position: 0
    },
    getters: {
        //
    },
    actions: {
        //
    },
    mutations: {
        setContentBar: function (state, payload) {
            state.content_bar = payload
        },
        closeContentBar: function (state) {
            state.content_bar.type = null
            state.content_bar.data = {}
        },
        setProjectMenuOpen: function (state, value) {
            state.project_menu.open = value
        },
        setUserMenuOpen: function (state, value) {
            state.user_menu.open = value
        },
        setDarkMode: function (state, value) {
            state.dark_mode = value
        },
        setDragging: function (state, value) {
            state.dragging = value
        },
        setContentScrollPosition: function (state, value) {
            state.content_scroll_position = value
        }
    }
}
