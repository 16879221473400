<template>
    <div class="json-editor">
        Work in progress
    </div>
</template>

<script>
export default {
    props: ['field', 'value', 'rows'],
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    }
}
</script>