<template>
    <div class="loadmore" v-if="show_button" v-on:click="loadMore" v-observe-visibility="visibilityChanged">
        <div class="loadmore-label">
            <v-icon name="arrow-down-circle"></v-icon> Load more results
        </div>
    </div>
</template>

<script>
export default {
    props: ['pagination', 'loadMore', 'autoload'],
    computed: {
        show_button: function () {
            return this.pagination.current_page < Math.ceil(this.pagination.total_results/this.pagination.results_page)
        }
    },
    methods: {
        visibilityChanged: function (state) {
            if(state && this.autoload) this.loadMore()
        }
    }
}
</script>