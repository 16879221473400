<template>
    <div class="notification" v-bind:class="classes">
        <div class="notification-icon"><v-icon :name="settings.icon"></v-icon></div>
        <div class="notification-contents">
            <div v-if="notification.type == 'project.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The project is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'project.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'project.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The project is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'field.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The field is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'field.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The field is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'field.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'field.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'section.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The section is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'section.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The section is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'section.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'list.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The list is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'list.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The list is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'list.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'field_data.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The field data is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'entry.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The entry is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'clipboard.access_token'">
                <h6 class="m-b-1">Alright!</h6>
                <p class="m-0">The access token is copied to your clipboard.</p>
            </div>
            <div v-if="notification.type == 'fileupload.filelimit_exceeded'">
                <h6 class="m-b-1">Whoops</h6>
                <p class="m-0">Only one file allowed.</p>
            </div>
            <div v-if="notification.type == 'fileupload.invalid_filetype'">
                <h6 class="m-b-1">Whoops</h6>
                <p class="m-0">The file you are trying to upload is invalid.</p>
            </div>
            <div v-if="notification.type == 'fileupload.filesize_exceeded'">
                <h6 class="m-b-1">Whoops</h6>
                <p class="m-0">Maximum filesize has been exceeded.</p>
            </div>
            <div v-if="notification.type == 'fileupload.upload_failed'">
                <h6 class="m-b-1">Something went wrong</h6>
                <p class="m-0">The file could not be uploaded due to an unknown error.</p>
            </div>
            <div v-if="notification.type == 'relation.saved'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The relation is successfully saved.</p>
            </div>
            <div v-if="notification.type == 'relation.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'relation.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The relation is successfully deleted.</p>
            </div>
            <div v-if="notification.type == 'relation.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'relation_entry.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'entry.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'team_member.saved'">
                <h6 class="m-b-1">Invited</h6>
                <p class="m-0">The user has been invited to the team.</p>
            </div>
            <div v-if="notification.type == 'team_member.reinvited'">
                <h6 class="m-b-1">User reinvited</h6>
                <p class="m-0">The invitation has successfully been sent.</p>
            </div>
            <div v-if="notification.type == 'team_member.role_updated'">
                <h6 class="m-b-1">Role updated</h6>
                <p class="m-0">The role of the member has been updated.</p>
            </div>
            <div v-if="notification.type == 'team_member.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The team member is succesfully deleted.</p>
            </div>
            <div v-if="notification.type == 'team_member.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'project.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'project_user.saved'">
                <h6 class="m-b-1">Invited</h6>
                <p class="m-0">The user has been invited to the project.</p>
            </div>
            <div v-if="notification.type == 'project_user.reinvited'">
                <h6 class="m-b-1">User reinvited</h6>
                <p class="m-0">The invitation has successfully been sent.</p>
            </div>
            <div v-if="notification.type == 'project_user.role_updated'">
                <h6 class="m-b-1">Role updated</h6>
                <p class="m-0">The role of the user has been updated.</p>
            </div>
            <div v-if="notification.type == 'project_user.deleted'">
                <h6 class="m-b-1">Deleted</h6>
                <p class="m-0">The project user is succesfully deleted.</p>
            </div>
            <div v-if="notification.type == 'project_user.errors'">
                <h6 class="m-b-1">Validation errors</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
            <div v-if="notification.type == 'section.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'list.positions_updated'">
                <h6 class="m-b-1">Saved</h6>
                <p class="m-0">The positions are updated succesfully.</p>
            </div>
            <div v-if="notification.type == 'invitation.errors'">
                <h6 class="m-b-1">Invitation could not be sent</h6>
                <NotificationTileErrors :errors="notification.data"></NotificationTileErrors>
            </div>
        </div>
    </div>
</template>

<script>
    import Notification from '@/models/Notification.js'
    import NotificationTileErrors from '@/components/Notifications/NotificationTileErrors.vue'

    export default {
        props: ['notification'],
        data: function () {
            return {
                destroying: false
            }
        },
        created: function () {
            this.destroyWhenNeeded()
        },
        computed: {
            settings: function () {
                switch(this.notification.type) {
                    case 'project.saved':
                    case 'field.saved':
                    case 'field_data.saved':
                    case 'relation.saved':
                        return { type: 'success', icon: 'check-circle', auto_destroy: 2000 }
                    case 'project.deleted':
                    case 'field.deleted':
                    case 'entry.deleted':
                    case 'relation.deleted':
                        return { type: '', icon: 'trash', auto_destroy: 2000 }
                    case 'field.positions_updated':
                    case 'relation.positions_updated':
                    case 'entry.positions_updated':
                    case 'project.positions_updated':
                    case 'section.positions_updated':
                    case 'list.positions_updated':
                    case 'team_member.reinvited':
                    case 'team_member.role_updated':
                    case 'team_member.saved':
                    case 'project_user.reinvited':
                    case 'project_user.role_updated':
                    case 'project_user.saved':
                        return { type: 'success', icon: 'check-circle', auto_destroy: 3000 }
                    case 'section.saved':
                    case 'list.saved':
                        return { type: 'success', icon: 'check-circle', auto_destroy: 2000 }
                    case 'section.deleted':
                    case 'list.deleted':
                    case 'team_user.deleted':
                    case 'project_user.deleted':
                    case 'team_member.deleted':
                        return { type: '', icon: 'trash', auto_destroy: 2000 }
                    case 'project.errors':
                    case 'section.errors':
                    case 'list.errors':
                    case 'field.errors':
                    case 'relation.errors':
                    case 'invitation.errors':
                    case 'team_member.errors':
                    case 'project_user.errors':
                    case 'relation_entry.errors':
                        return { type: 'error', icon: 'alert-circle', auto_destroy: 5000 }
                    case 'clipboard.access_token':
                        return { type: 'success', icon: 'copy', auto_destroy: 2000 }
                    case 'fileupload.filelimit_exceeded':
                    case 'fileupload.invalid_filetype':
                    case 'fileupload.filesize_exceeded':
                    case 'fileupload.upload_failed':
                        return { type: 'error', icon: 'slash', auto_destroy: 4000 }
                }
                return { icon: 'help-circle', type: '', auto_destroy: 2000 }
            },
            classes: function () {
                return {
                    'notification-success': this.settings.type === 'success',
                    'notification-warning': this.settings.type === 'warning',
                    'notification-danger': this.settings.type === 'error',
                    'notification-destroying': this.destroying
                }
            }
        },
        methods: {
            destroyWhenNeeded: function () {
                if (!this.settings.auto_destroy) return
                setTimeout(function () {
                    this.destroy()
                }.bind(this), this.settings.auto_destroy)
            },
            destroy: function () {
                this.destroying = true
                setTimeout(function () {
                    Notification.delete(this.notification.id)
                }.bind(this), 500)
            }
        },
        components: {
            NotificationTileErrors
        }
    }
</script>