import Event from '@/libraries/eventbus.js'
import Notification from '@/models/Notification.js'
import Project from '@/models/Project.js'
import store from '@/store'

Event.on('team.changed', () => {
	Project.deleteAll()
	Project.dispatch('query')
})

Event.on('project.saved', () => {
	Notification.dispatch('launch', { type: 'project.saved' })
})

Event.on('project.errors', (errors) => {
	Notification.dispatch('launch', { type: 'project.errors', data: errors })
})

Event.on('project.deleted', () => {
	Notification.dispatch('launch', { type: 'project.deleted' })
})

Event.on('field.saved', () => {
	Notification.dispatch('launch', { type: 'field.saved' })
})

Event.on('field.deleted', () => {
	Notification.dispatch('launch', { type: 'field.deleted' })
	store.commit('ui/closeContentBar')
})

Event.on('field.errors', (errors) => {
	Notification.dispatch('launch', { type: 'field.errors', data: errors })
})

Event.on('field.positions_updated', () => {
	Notification.dispatch('launch', { type: 'field.positions_updated' })
})

Event.on('section.saved', () => {
	Notification.dispatch('launch', { type: 'section.saved' })
})

Event.on('section.deleted', () => {
	Notification.dispatch('launch', { type: 'section.deleted' })
})

Event.on('section.errors', (errors) => {
	Notification.dispatch('launch', { type: 'section.errors', data: errors })
})

Event.on('list.saved', () => {
	Notification.dispatch('launch', { type: 'list.saved' })
})

Event.on('list.deleted', () => {
	Notification.dispatch('launch', { type: 'list.deleted' })
})

Event.on('list.errors', (errors) => {
	Notification.dispatch('launch', { type: 'list.errors', data: errors })
})

Event.on('field_data.saved', () => {
	Notification.dispatch('launch', { type: 'field_data.saved' })
})

Event.on('entry.deleted', () => {
	Notification.dispatch('launch', { type: 'entry.deleted' })
	store.commit('ui/closeContentBar')
})

Event.on('clipboard.access_token', () => {
	Notification.dispatch('launch', { type: 'clipboard.access_token' })
})

Event.on('fileupload.filelimit_exceeded', () => {
	Notification.dispatch('launch', { type: 'fileupload.filelimit_exceeded' })
})

Event.on('fileupload.invalid_filetype', () => {
	Notification.dispatch('launch', { type: 'fileupload.invalid_filetype' })
})

Event.on('fileupload.filesize_exceeded', () => {
	Notification.dispatch('launch', { type: 'fileupload.filesize_exceeded' })
})

Event.on('fileupload.upload_failed', () => {
	Notification.dispatch('launch', { type: 'fileupload.upload_failed' })
})

Event.on('relation.saved', () => {
	Notification.dispatch('launch', { type: 'relation.saved' })
})

Event.on('relation.errors', (errors) => {
	Notification.dispatch('launch', { type: 'relation.errors', data: errors })
})

Event.on('relation.deleted', () => {
	Notification.dispatch('launch', { type: 'relation.deleted' })
	store.commit('ui/closeContentBar')
})

Event.on('relation.positions_updated', () => {
	Notification.dispatch('launch', { type: 'relation.positions_updated' })
})

Event.on('relation_entry.errors', (errors) => {
	Notification.dispatch('launch', { type: 'relation_entry.errors', data: errors })
})

Event.on('entry.positions_updated', () => {
	Notification.dispatch('launch', { type: 'entry.positions_updated' })
})

Event.on('project.positions_updated', () => {
	Notification.dispatch('launch', { type: 'project.positions_updated' })
})

Event.on('section.positions_updated', () => {
	Notification.dispatch('launch', { type: 'section.positions_updated' })
})

Event.on('list.positions_updated', () => {
	Notification.dispatch('launch', { type: 'list.positions_updated' })
})

Event.on('invitation.errors', (errors) => {
	Notification.dispatch('launch', { type: 'invitation.errors', data: errors })
})

Event.on('team_member.errors', (errors) => {
	Notification.dispatch('launch', { type: 'team_member.errors', data: errors })
})

Event.on('team_member.saved', () => {
    Notification.dispatch('launch', { type: 'team_member.saved' })
})

Event.on('team_member.role_updated', () => {
    Notification.dispatch('launch', { type: 'team_member.role_updated' })
})

Event.on('team_member.deleted', () => {
    Notification.dispatch('launch', { type: 'team_member.deleted' })
})

Event.on('team_member.reinvited', () => {
    Notification.dispatch('launch', { type: 'team_member.reinvited' })
})

Event.on('project_user.errors', (errors) => {
	Notification.dispatch('launch', { type: 'project_user.errors', data: errors })
})

Event.on('project_user.saved', () => {
    Notification.dispatch('launch', { type: 'project_user.saved' })
})

Event.on('project_user.role_updated', () => {
    Notification.dispatch('launch', { type: 'project_user.role_updated' })
})

Event.on('project_user.deleted', () => {
    Notification.dispatch('launch', { type: 'project_user.deleted' })
})

Event.on('project_user.reinvited', () => {
    Notification.dispatch('launch', { type: 'project_user.reinvited' })
})