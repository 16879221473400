import ApiService from '@/libraries/api'
import User from '@/models/User'

export default {
    namespaced: true,
    state: {
        authenticated: false
    },
    getters: {
        user (state) {
            if (!state.authenticated) return null
            return User.query().withAllRecursive().find(state.authenticated)
        }
    },
    actions: {
        async signIn ({ dispatch }, credentials) {
            await ApiService.get('/sanctum/csrf-cookie')
            return await ApiService.post('/login', credentials).then(() => {
                return dispatch('user')
            }).catch(() => {
                return false
            })
        },
        async signOut ({ dispatch }) {
            await ApiService.post('/logout').then()
            return dispatch('user')
        },
        async requestPasswordReset (store, params) {
            // await ApiService.get('/sanctum/csrf-cookie')
            return await ApiService.post('/passwordforgot', params)
            .then(response => response)
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },
        async passwordReset (store, params) {
            return await ApiService.post('/passwordreset', params)
            .then(response => response)
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },
        async registerByInvitation ( store , params) {
            return ApiService.post('/invitations/' + params.token + '/register', params)
            .then(response => response)
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },
        async user ({ commit }) {
            const parameters = {
                //with: ['teams.user_projects']
            }

            return ApiService.get('/user', { params: parameters }).then((response) => {
                User.insert(response.data)
                commit('setAuthenticated', response.data.data.id)
                return true
            }).catch(() => {
                commit('setAuthenticated', false)
                return false
            })
        }
    },
    mutations: {
        setAuthenticated: function (state, value) {
            state.authenticated = value
        }
    }
}
