import Vue from 'vue'

const EventBus = new Vue()

export default {
	dispatch (event, payload) {
		return EventBus.$emit(event, payload)
    },
    on (event, payload) {
		return EventBus.$on(event, payload)
    },
    off (event) {
		return EventBus.$off(event)
    }
}