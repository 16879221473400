<template>
    <div class="fade-in">
        <div class="table m-b-3">
            <div class="table-head">
                <div class="table-tr">
                    <div class="table-td" v-for="column in columns" v-bind:key="column.field">{{ column.label }}</div>
                </div>
            </div>
            <Draggable class="table-body table-body-clickable" v-model="rows" handle=".draggable-handle" ghost-class="ghost" group="entries" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                <div class="table-tr" v-for="row in rows" v-bind:key="row.id" v-on:click="openEntry(row)">
                    <div class="table-td" v-for="(column, index) in columns" v-bind:key="column.field">
                        <ul class="table-actions" v-if="!index && sortable">
                            <li v-tooltip="'Reorder'" class="draggable-handle"><v-icon name="menu"></v-icon></li>
                        </ul>
                        <div class="table-td-contents">
                            <TableImage v-if="column.type=='image'" :images="getFiles(row[column.field])"></TableImage>
                            <TableFile v-else-if="column.type=='file'" :files="getFiles(row[column.field])"></TableFile>
                            <span v-else>{{ row[column.field] }}</span>
                        </div>
                    </div>
                </div>
            </Draggable>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Field from '@/models/Field'
import Entry from '@/models/Entry'
import TableImage from '@/components/Tables/TableImage.vue'
import TableFile from '@/components/Tables/TableFile.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['list', 'entries'],
    data: function () {
        return {
            dragging: false
        }
    },
    computed: {
        columns: function () {
            return this.table_fields.map(field_id => {
                var field = Field.find(field_id)
                return {
                    label: field.name,
                    type: field.type,
                    field: field_id,
                    sortable: field.table_options.sortable,
                    html: true
                }
            })
        },
        rows: {
            get() {
                return this.entries.map(entry => {
                    var row = {}
                    row.id = entry.id
                    row.link = entry.link

                    this.table_fields.map(field_id => {
                        var field_data = entry.field_data.find(field_data => field_data.field_id === field_id)
                        row[field_id] = field_data ? field_data.table_value : "<span>No data</span>"
                    })
                    return row
                })
            },
            set(entries) {
                Entry.dispatch('updatePositions', { entries: entries })
            }
        },
        table_fields: function () {
            if(this.list.table_fields.length) return this.list.table_fields
            var fields = this.clone(this.list.fields)

            return _.orderBy(fields, ['position']).map(field => field.id).slice(0, 5)
        },
        sortable: function () {
            return this.list.sort_direction === 'manually'
        }
    },
    methods: {
        openEntry: function (row) {
            this.$router.push(row.link)
        },
        getFiles: function (files) {
            return Array.isArray(files) ? files : []
        },
        setDragging: function (state) {
            this.dragging = state
        }
    },
    components: {
        TableImage,
        TableFile,
        Draggable
    }
}
</script>