<template>
    <div class="searchfield" v-click-outside="deactivateField" v-on:click="activateField" v-bind:class="{ 'searchfield-active': active }">
        <v-icon name="search"></v-icon>
        <div class="searchfield-form" v-if="active">
            <input type="text" placeholder="Type to search" v-autofocus v-on:change="search" v-model="query">
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                query: null,
                active: false
            }
        },
        methods: {
            activateField: function () {
                this.active = true
            },
            deactivateField: function () {
                this.active = false
            },
            search: function () {
                this.$emit('search', this.query)
            }
        }
    }
</script>