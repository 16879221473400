<template>
    <div class="page">
        <div class="page-contents">
            <div class="padding">
                <div v-if="sections.length">
                    <h2>Sections</h2>
                    <Draggable class="grid grid-auto" v-if="sections.length" v-model="sections" ghost-class="ghost" group="sections" :disabled="!can('update-section')" :options="{ draggable: '.draggable' }" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                        <ModuleTile v-for="section in sections" :key="section.id" :module="section"></ModuleTile>
                        <div class="box box-border box-border-dashed box-transparent box-no-shadow cursor-pointer text-align-center fade-in" v-on:click="addSection" v-if="can('create-section') && !dragging">
                            <div class="box-padding p-t-10 p-b-10">
                                <div class="box-icon"><v-icon name="plus"></v-icon></div>
                                <h4 class="m-b-0">Add section</h4>
                            </div>
                        </div>
                    </Draggable>
                </div>
                <div v-else>
                    <BaseNoResults>
                        <v-icon name="layers"></v-icon>
                        <div v-if="can('create-section')">
                            <h4 class="m-b-3">Add your first section</h4>
                            <p>Create you first section in this project.</p>
                            <a class="button" v-on:click="addSection">Add section</a>
                        </div>
                        <div v-else>
                            <h4 class="m-b-3">No sections created</h4>
                            <p>Please contact an administrator.</p>
                        </div>
                    </BaseNoResults>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Section from '@/models/Section.js'
import ModuleTile from '@/components/ModuleTile.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['project'],
    data: function () {
        return {
            dragging: false
        }
    },
    computed: {
        sections: {
            get() {
                return Section.query()
                    .where('project_id', this.project.id)
                    .withAll()
                    .orderBy('position')
                    .get()
            },
            set(sections) {
                Section.dispatch('updatePositions', { sections: sections })
            }
        }
    },
    methods: {
        addSection: function () {
            this.$router.push({
                path: this.project.link + '/add/section'
            })
        },
        setDragging: function(state) {
            this.dragging = state
        }
    },
    components: {
        ModuleTile,
        Draggable
    }
}
</script>
