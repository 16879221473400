import ApiService from '@/libraries/api'
import Project from '@/models/Project'

export default {
    getters: {
        //
    },
    actions: {
        inviteToTeam: function (store,  params) {
            return ApiService.post(`/invitations/teams/${params.team_id}/${params.role_id}`, params)
                .then(response => response)
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },
        inviteToProject: function (store,  params) {
            return ApiService.post(`/invitations/projects/${params.project_id}/${params.role_id}`, params)
                .then(response => {
                    if(!response.error) {
                        Project.insertOrUpdate(response.data)
                    }
                    return response
                })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },
        reinviteToTeam: function (store,  params) {
            return ApiService.post(`/invitations/teams/${params.team_id}/reinvite/${params.user_id}`)
                .then(response => response)
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },
        reinviteToProject: function (store,  params) {
            return ApiService.post(`/invitations/projects/${params.project_id}/reinvite/${params.user_id}`)
                .then(response => response)
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },
        accept: function (store, route) {
            return ApiService.post(route)
                .then(response => response)
                .catch(error => error.response)
                .then(response => response)
        },
    }
}