<template>
    <div class="page page-light">
        <div class="page-contents">
            <div class="padding">
                <div v-if="loading" class="text-align-center">
                    <h3>Accepting invitation</h3>
                    <p>Please wait while we process your invitation</p>
                    <BasePreloader v-if="loading"></BasePreloader>
                </div>
                <div v-else-if="failed_message" class="text-align-center">
                    <h3>Invitation acceptance failure</h3>
                    <p>The invitation could not be processed due to the following reason: <b v-text="failed_message"></b></p>
                </div>
                <InvitationCredentialsForm v-else-if="show_form"></InvitationCredentialsForm>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Invitation from '@/models/Invitation.js'
import InvitationCredentialsForm from './InvitationCredentialsForm.vue'

export default {
    data: function () {
        return {
            loading: true,
            failed_message: null,
            show_form: false
        }
    },
    created: function() {
        this.acceptInvitation()
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    methods: {
        acceptInvitation: function() {
            Invitation.dispatch('accept', this.$route.fullPath).then(response => {
                this.loading = false
                if(!response.status) {
                    this.failed_message = 'The invitation could not be processed.'
                    return
                }
                switch(response.status) {
                    case 200:
                    case 201:
                    case 204:
                        this.$store.dispatch('auth/user').then(res => {
                            if(res) {
                                this.$router.push('/')
                            } else {
                                this.show_form = true
                            }
                        })
                        break
                    case 403:
                        this.doActionDependingOnMessage(response.data.message)
                        break
                    case 404:
                    case 500:
                        this.failed_message = 'The invitation could not be processed.'
                        break
                }
            })
        },
        doActionDependingOnMessage(message) {
            switch(message) {
                case 'invalid_signature':
                    this.failed_message = 'The signature is invalid.'
                    break
                case 'acceptance_failed':
                    this.failed_message = 'The acceptance was rejected.'
                    break
                case 'invalid_user':
                    this.$router.push('/')
                    break
            }
        }
    },
    components: {
        InvitationCredentialsForm
    }
}
</script>
