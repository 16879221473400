import router from '@/router'
import store from '@/store'

router.beforeEach((to, from, next) => {

    // Close content bar
    store.commit('ui/setContentBar', { type: null, id: null })

    // Set ui content scroll position to 0
    store.commit('ui/setContentScrollPosition', 0)

    // Protect routes
    if (to.matched[0] && to.matched[0].meta.guest) {
        // Guests
        if (store.getters['auth/user']) {
            return next('/')
        }
        next()
    } else {
        // Authenticated route
        if (!store.getters['auth/user']) {
            return next('/signin')
        }
        next()
    }
})
