<template>
    <div class="page">
        <div class="page-contents">
            <div class="padding">
                <div v-if="lists.length">
                    <h2>Lists</h2>
                    <Draggable class="grid grid-auto" v-if="lists.length" v-model="lists" ghost-class="ghost" group="lists" :disabled="!can('update-list')" :options="{ draggable: '.draggable' }" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                        <ModuleTile v-for="list in lists" :key="list.id" :module="list"></ModuleTile>
                        <div class="box box-border box-border-dashed box-transparent box-no-shadow cursor-pointer text-align-center fade-in" v-on:click="addList" v-if="can('create-list') && !dragging">
                            <div class="box-padding p-t-10 p-b-10">
                                <div class="box-icon"><v-icon name="plus"></v-icon></div>
                                <h4 class="m-b-0">Add list</h4>
                            </div>
                        </div>
                    </Draggable>
                </div>
                <div v-else>
                    <BaseNoResults>
                        <v-icon name="layers"></v-icon>
                        <div v-if="can('create-list')">
                            <h4 class="m-b-3">Add your first list</h4>
                            <p>Create you first list in this project.</p>
                            <a class="button" v-on:click="addList">Add list</a>
                        </div>
                        <div v-else>
                            <h4 class="m-b-3">No lists created</h4>
                            <p>Please contact an administrator.</p>
                        </div>
                    </BaseNoResults>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import List from '@/models/List.js'
import ModuleTile from '@/components/ModuleTile.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['project'],
    data: function () {
        return {
            dragging: false
        }
    },
    computed: {
        lists: {
            get() {
                return List.query()
                    .where('project_id', this.project.id)
                    .withAll()
                    .orderBy('position')
                    .get()
            },
            set(lists) {
                List.dispatch('updatePositions', { lists: lists })
            }
        }
    },
    methods: {
        addList: function () {
            this.$router.push({
                path: this.project.link + '/add/list'
            })
        },
        setDragging: function(state) {
            this.dragging = state
        }
    },
    components: {
        ModuleTile,
        Draggable
    }
}
</script>
