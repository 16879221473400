import { Model } from '@vuex-orm/core'

export default class Modal extends Model {
    static get entity () {
        return 'modals'
    }

    static state () {
        return {
            //
        }
    }

    static fields () {
        return {
            id: this.attr(),
            type: this.attr(),
            variant: this.attr(null),
            name: this.attr(null),
            data: this.attr({})
        }
    }
}
