import { Model } from '@vuex-orm/core'
import Project from './Project'
import Field from './Field'
import Relation from './Relation'

export default class Section extends Model {
    static get entity () {
        return 'sections'
    }

    static state () {
        return {
            loading: false
        }
    }

    static fields () {
        return {
            id: this.attr(),
            project_id: this.attr(),
            name: this.attr(),
            slug: this.attr(),
            icon: this.attr('file'),
            position: this.number(),
            menu: this.boolean(),
            admin_only: this.boolean(false),

            // Relations
            project: this.belongsTo(Project, 'project_id'),
            fields: this.morphMany(Field, 'module_id', 'module_type'),
            relations: this.morphMany(Relation, 'module_id', 'module_type')
        }
    }

    get module_type () {
        return 'sections'
    }

    get link () {
        if(!this.project) return null
        return this.project.link + '/sections/' + this.slug
    }

    get identifier () {
        if(!this.project) return null
        return this.slug
    }
}
