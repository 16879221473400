<template>
    <div class="entry-relation-form">
        <div class="box box-border" v-bind:class="{ 'box-editing': editor }">
            <div v-if="!editor">
                <div class="box-padding box-padding-small">
                    <div class="entry-relation-form-actions" v-if="has_actions">
                        <div class="entry-relation-form-actions-action" v-on:click="toggleEditor()" v-if="can_add_entries">
                            <v-icon name="plus-circle" v-tooltip="'Add entries'"></v-icon>
                        </div>
                    </div>
                    <h5 class="m-0">{{ relation.name }}</h5>
                    <p v-if="relation.description" class="m-t-1 m-b-0 fade-in">{{ relation.description }}</p>
                </div>
                <BasePreloader :compact="true" v-if="loading"></BasePreloader>
                <div v-else class="fade-in">
                    <div v-if="rows.length">
                        <div class="relation-table">
                            <div class="relation-table-thead">
                                <div class="relation-table-tr">
                                    <div class="relation-table-td" v-for="column in columns" v-bind:key="column.field">{{ column.label }}</div>
                                    <div class="relation-table-td"></div>
                                </div>
                            </div>
                            <Draggable class="relation-table-tbody" v-model="rows" handle=".draggable-handle" ghost-class="ghost" :group="'relation-' + relation.id">
                                <div class="relation-table-tr" v-for="row in rows" v-bind:key="row.id">
                                    <div class="relation-table-td" v-for="column in columns" v-bind:key="column.field">
                                        <div class="relation-reorder draggable-handle" v-tooltip="'Reorder'" v-if="sortable"><v-icon name="menu"></v-icon></div>
                                        <TableImage v-if="column.type=='image' && 0">Image</TableImage>
                                        <span v-else>{{ row[column.field] }}</span>
                                    </div>
                                    <div class="relation-table-td text-align-right">
                                        <ul class="relation-actions">
                                            <li v-on:click="openEntry(row)" v-tooltip="'Edit'"><v-icon name="edit"></v-icon></li>
                                            <li v-tooltip="'Unlink'" v-on:click="unlinkEntry(row)"><v-icon name="trash"></v-icon></li>
                                        </ul>
                                    </div>
                                </div>
                            </Draggable>
                        </div>
                    </div>
                    <div class="box-padding box-padding-small p-t-0" v-else>
                        <p class="m-0 small">No entries added</p>
                    </div>
                </div>

                <div v-if="show_pagination" class="load-more">
                    <a v-on:click="loadMore()">Load more</a>
                </div>
            </div>
            <div v-else>
                <div class="box-padding box-padding-small">
                    <div class="entry-relation-form-actions">
                        <div class="entry-relation-form-actions-action" v-on:click="toggleEditor()">
                            <v-icon name="x" v-tooltip="'Close'"></v-icon>
                        </div>
                    </div>
                    <h5 class="m-0">{{ relation.name }}</h5>
                    <div class="fade-in">
                        <p class="m-t-1 m-b-0"><b><a v-on:click="addNewEntry()">Add new entry</a></b> or link existing entries below.</p>
                    </div>
                </div>
                <div class="fade-in">
                    <div class="entry-relation-form-search">
                        <v-icon name="search"></v-icon>
                        <input type="text" class="entry-relation-form-search-input" v-model="query" placeholder="Type to search">
                    </div>

                    <BasePreloader :compact="true" v-if="loading"></BasePreloader>
                    <div v-else>
                        <p class="m-0 p-l-4 small" v-if="!search_entries.length && !query">No entries available</p>
                        <p class="m-0 p-l-4 small" v-else-if="!search_entries.length && query">No entries found</p>
                        <div class="relation-table-tbody" v-else>
                            <div class="relation-table-tr cursor-pointer" v-for="row in search_rows" v-bind:key="row.id" v-on:click="addEntry(row)">
                                <div class="relation-table-td" v-for="column in columns" v-bind:key="column.field">
                                    <TableImage v-if="column.type=='image' && 0"></TableImage>
                                    <span v-else>{{ row[column.field] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="show_search_pagination" class="load-more">
                        <a v-on:click="loadMoreUnrelatedEntries()">Load more</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
// import Pagination from '@/components/Pagination.vue'
import Field from '@/models/Field.js'
import Entry from '@/models/Entry.js'
import Modal from '@/models/Modal.js'
import Relation from '@/models/Relation.js'
import Event from '@/libraries/eventbus.js'
import Draggable from 'vuedraggable'

export default {
    props: ['relation', 'entry'],
    data: function () {
        return {
            loading: false,
            relation_entries: [],
            editor: false,
            query: '',
            pagination: {
                current_page: 1,
                results_page: 10,
                total_results: 0
            },
            search_pagination: {
                current_page: 1,
                results_page: 10,
                total_results: 0
            }
        }
    },
    created: function() {
        this.initListeners()
        this.loadRelationEntries()
        this.searchEntries()
    },
    beforeDestroy: function () {
        Event.off('relation.entry.created.' + this.relation.id)
    },
    computed: {
        has_actions: function () {
            return this.can_add_entries
        },
        can_add_entries: function () {
            if(this.relation_type == 'hasOne') {
                return !this.entries || !this.entries.length
            }

            return true
        },
        entries: function() {
            return Entry.query()
                .whereIdIn(this.relation_entries)
                .withAll()
                .orderBy('relation_position')
                .get()
        },
        search_entries: function() {
            return Entry.query()
                .where('list_id', this.relation.related_module_id)
                .where(entry => !this.relation_entries.includes(entry.id) && !entry.id.startsWith('$uid'))
                .has('field_data')
                .withAll()
                .orderBy('position')
                .get()
        },
        relation_type: function () {
            return this.relation ? this.relation.type  : 'hasMany'
        },
        rows: {
            get() {
                return this.entries.map(entry => {
                    var row = {}
                    row.id = entry.id
                    row.link = entry.link

                    this.table_fields.map(field_id => {
                        var field_data = entry.field_data.find(field_data => field_data.field_id === field_id)
                        row[field_id] = field_data ? field_data.table_value : "<span>No data</span>"
                    })
                    return row
                })
            },
            set(entries) {
                const entry_id = this.entry ? this.entry.id : null
                Relation.dispatch('updateEntryPositions', { relation: this.relation, entries: entries, target_entry_id: entry_id })
            }
        },
        search_rows: function () {
            return this.search_entries.map(entry => {
                var row = {}
                row.id = entry.id
                row.link = entry.link

                this.table_fields.map(field_id => {
                    var field_data = entry.field_data.find(field_data => field_data.field_id === field_id)
                    row[field_id] = field_data ? field_data.table_value : "<span>No data</span>"
                })

                return row
            })
        },
        columns: function () {
            return this.table_fields.map(field_id => {
                var field = Field.find(field_id)
                return {
                    label: field.name,
                    type: field.type,
                    field: field_id,
                    sortable: field.table_options.sortable,
                    html: true
                }
            })
        },
        table_fields: function () {
            const related_module = this.relation.related_module
            if(!related_module) return []

            const fields = Field.query()
                .where('module_id', related_module.id)
                .where('module_type', related_module.module_type)
                .get()

            return _.orderBy(fields, ['position']).map(field => field.id).slice(0, 1)
        },
        show_pagination: function () {
            if (!this.pagination.total_results) return false
            return this.pagination.total_results > this.entries.length
        },
        show_search_pagination: function () {
            if (!this.search_pagination.total_results) return false
            return this.search_pagination.total_results > this.search_entries.length
        },
        sortable: function () {
            return this.relation.sort_direction === 'manually' && this.relation_type != 'hasOne'
        }
    },
    methods: {
        initListeners: function () {
            Event.on('relation.entry.created.' + this.relation.id, (payload) => {
                this.relation_entries.push(payload.entry_id)
                this.editor = false
            })
        },
        loadMore: function () {
            if(!this.show_pagination) return
            this.pagination.current_page ++
            this.loadRelationEntries()
        },
        loadMoreUnrelatedEntries: function () {
            if(!this.show_search_pagination) return
            this.search_pagination.current_page ++
            this.searchEntries()
        },
        loadRelationEntries: function() {
            this.loading = true
            var filter = {
                where_has: { relations: this.relation.id },
                with: ['field_data.field.module', 'relations.target_entries'],
                page: this.pagination.current_page,
                results_page: this.pagination.results_page
            }

            if(this.entry) {
                filter.scope = {
                    hasTargetEntry: this.entry.id
                }
            }

            // const relation_entries = Entry.query()
            //     .whereHas('relations', (query) => {
            //         query.where('id', this.relation.id)
            //     })
            //     .get()
            //     .map(entry => entry.id)

            // Entry.delete(entry => relation_entries.includes(entry.id))

            Entry.dispatch('query', filter).then(response => {
                // this.relation_entries = []
                this.loading = false
                if(response.success) {
                    this.relation_entries = this.relation_entries.concat(response.data.map(entry => entry.id))
                    this.pagination.total_results = response.meta.total
                }
            })
        },
        searchEntries: function() {
            this.loading = true
            if(this.search_entries && this.search_entries.length) {
                const search_entries = this.search_entries.map(entry => entry.id)
                Entry.delete(entry => search_entries.includes(entry.id))
            }

            var filter = {
                where_has: {
                    list: this.relation.related_module_id
                },
                // where_doesnt_have: {
                //     relations: this.relation.id
                // },
                search: this.query,
                with: ['field_data.field', 'list'],
                page: this.search_pagination.current_page,
                results_page: this.search_pagination.results_page
            }

            if(this.entry) {
                filter.scope = {
                    doesntHaveTargetEntry: this.entry.id
                }
            } else {
                filter.where_doesnt_have = {
                    relations: this.relation.id
                }
            }

            Entry.dispatch('query', filter).then(response => {
                if(response.success) {
                    if(this.query) {
                        Entry.delete(entry => !response.data.map(entry => entry.id).includes(entry.id) && !this.relation_entries.includes(entry.id))
                    }
                    this.search_pagination.total_results = response.meta.total
                }

                this.loading = false
            })
        },
        setPage: function (page) {
            this.pagination.current_page = page
            this.loadRelationEntries()
        },
        toggleEditor: function () {
            this.editor = ! this.editor
        },
        addNewEntry: function () {
            Modal.dispatch('launch', {
                type: 'relation.editor',
                name: this.relation.name,
                data: {
                    relation_id: this.relation.id,
                    target_entry_id: this.entry ? this.entry.id : null
                }
            })
        },
        openEntry: function (entry) {
            Modal.dispatch('launch', {
                type: 'relation.editor',
                name: this.relation.name,
                data: {
                    relation_id: this.relation.id,
                    entry_id: entry.id,
                    target_entry_id: this.entry ? this.entry.id : null
                }
            })
        },
        addEntry: function (entry) {
            Relation.dispatch('saveEntry', {
                id: this.relation.id,
                entry_id: entry.id,
                target_entry_id: this.entry ? this.entry.id : null
            }).then(response => {
                if(response.success) {
                    this.relation_entries.push(entry.id)
                    this.toggleEditor()
                } else {
                   Event.dispatch('relation_entry.errors', response.data.errors)
                }
            })
        },
        unlinkEntry: function (entry) {
            this.$confirm({
                message: 'Are you sure you want to unlink this entry?',
                button: {
                    no: 'Keep',
                    yes: 'Unlink'
                },
                callback: confirm => {
                    if (confirm) {
                        Relation.dispatch('unlinkEntry', {
                            id: this.relation.id,
                            entry_id: entry.id,
                            target_entry_id: this.entry ? this.entry.id : null
                        }).then(response => {
                            if(response && response.success) {
                                Entry.update({ where: entry.id, data: { relation_position: null } })
                                this.relation_entries = this.relation_entries.filter(relation_entry => relation_entry !== entry.id)
                            }
                        })
                    }
                }
            })

        }
    },
    watch: {
        query: function(query) {
            this.query = query
            this.searchEntries()
        },
    },
    components: {
        // Pagination,
        Draggable
    }
}
</script>