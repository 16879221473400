import ApiService from '@/libraries/api'
import List from '@/models/List'
import Event from '@/libraries/eventbus'

export default {
    actions: {
        async query(state, parameters) {
            List.commit((state) => {
                state.loading = true
            })

            List.deleteAll()
            return ApiService.get('/lists', { params: parameters }).then(response => List.insertOrUpdate(response.data))
        },

        async single(state, parameters) {
            List.commit((state) => {
                state.loading = true
            })

            return ApiService.get(`/lists/${parameters.id}`, { params: parameters }).then(response => List.insertOrUpdate(response.data))
        },

        async post(store, params) {
            return ApiService.post('/lists', params).then(response => {
                if (!response.error) {
                    List.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        },

        async patch(store, params) {
            return ApiService.patch('/lists/' + params.id, params).then(response => {
                if (!response.error) {
                    List.insertOrUpdate(response.data)
                    return response
                }
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async remove(store, params) {
            return ApiService.delete('/lists/' + params.id).then(response => {
                if (!response.error) {
                    List.delete(params.id)
                    return response
                }
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async updatePositions(store, payload) {
            const params = {
                lists: payload.lists.map(list => list.id)
            }

            const data = payload.lists.map((list, index) => {
                return {
                    id: list.id,
                    position: index + 1
                }
            })

            List.update({ data })

            return ApiService.post('/lists/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('list.positions_updated')
                    return response.data
                }
            }).catch(error => error.response)
        },
    }
}