<template>
    <div v-if="relation">
         <div class="contentbar-tabs">
            <ul>
                <li class="active"><v-icon name="edit"></v-icon></li>
            </ul>
        </div>
        <div class="contentbar-save" v-bind:class="{ 'active': unsaved_changes }" v-on:click="saveRelation">
            Save
        </div>
        <div class="contentbar-contents contentbar-contents-tab">
            <div class="fade-in">
                <div class="padding">
                    <h3>Relation</h3>
                    <form class="form" action="submit" v-on:submit.prevent="saveRelation">
                        <div class="form-group">
                            <label>Name<small>Required</small></label>
                            <input class="text-input" type="text" v-model="relation.name" v-autofocus>
                            <div class="slug-input fade-in" v-if="relation.slug && !slug_editor">Slug: <span class="slug-input-slug" v-on:click="toggleSlugEditor">{{ relation.slug ? relation.slug : 'new-field' }}</span></div>
                        </div>
                        <div class="form-group fade-in" v-if="slug_editor">
                            <label>Slug<small>Unique</small></label>
                            <input class="text-input" type="text" v-model="relation.slug" autofocus>
                            <p class="small m-t-1">Unique slug for building queries</p>
                        </div>
                        <div class="form-group">
                            <label>Description<small>Optional</small></label>
                            <input class="text-input" type="text" v-model="relation.description">
                            <p class="small m-t-1">Show extra information for this relation</p>
                        </div>
                        <div class="form-group">
                            <label>Relation type</label>
                            <select class="select-input" v-model="relation.type">
                                <option value="hasOne">Has One</option>
                                <option value="hasMany">Has Many</option>
                            </select>
                        </div>
                        <div class="form-group fade-in" v-if="relation.type === 'hasMany'">
                            <label>Sorting options</label>
                            <select class="select-input" v-model="relation.sort_direction">
                                <option value="recent_first">Most recent first</option>
                                <option value="recent_last">Most recent last</option>
                                <option value="manually">Sort manually</option>
                            </select>
                        </div>
                        <input class="button hidden" type="submit">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Relation from '@/models/Relation'
import Event from '@/libraries/eventbus.js'

export default {
    props: ['data'],
    data: function () {
        return {
            relation: null,
            saving: false,
            slug_editor: false,
            creating: false
        }
    },
    mounted: function () {
        this.relation = this.clone(this.relation_instance)
        this.creating = this.relation_instance.creating
    },
    beforeDestroy: function () {
        if(this.creating) Relation.delete(this.relation_instance.id)
    },
    computed: {
        relation_instance: function () {
            return Relation.find(this.data)
        },
        relations: function () {
            return Relation.query().get()
        },
        unsaved_changes: function () {
            return !_.isEqual(this.relation, this.clone(this.relation_instance)) && !this.saving
        }
    },
    methods: {
        saveRelation: function () {
            if (!this.unsaved_changes) return

            this.saving = true
            if (this.creating) this.createRelation()
            if (!this.creating) this.updateRelation()
        },
        createRelation: function () {
            Relation.dispatch('post', this.relation).then(response => {
                this.saving = false
                if(response.success) {
                    Event.dispatch('relation.saved', this.relation)
                    this.creating = false
                    this.$store.commit('ui/setContentBar', { type: 'relation.edit', data: response.data.id })
                } else {
                    Event.dispatch('relation.errors', response.data.errors)
                }
            })
        },
        updateRelation: function () {
            Relation.dispatch('patch', this.relation).then(response => {
                this.saving = false
                if(response.success) {
                    this.relation = this.clone(this.relation_instance)
                    Event.dispatch('field.saved', this.field)
                } else {
                    Event.dispatch('field.errors', response.data.errors)
                }
            })
        },
        toggleSlugEditor: function () {
            this.slug_editor = !this.slug_editor
        }
    },
    watch: {
        relation_instance: function (data, previous) {
            if (!this.relation_instance) return
            if(data && previous && data.id === previous.id) return
            this.relation = this.clone(this.relation_instance)
            if(previous && previous.creating) Relation.delete(previous.id)
        },
        'relation.name': function (input) {
            if(!input) return null
            if(!this.relation.creating && this.relation.slug) return null
            return this.relation.slug = this.slug(input ? input : '')
        }
    }
}
</script>