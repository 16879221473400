<template>
    <div class="page page-light">
        <div class="page-contents">
            <div class="padding">

                <div v-if="team">
                    <router-link :to="'/add/project'" class="button button-icon-only button-icon-only-success float-right">
                        <v-icon name="plus"></v-icon>
                    </router-link>
                    <h2>{{ team.name }}</h2>
                    <p class="lead">Projects that belong to {{ team.name }}. In our beta fase you have to <a href="mailto:support@vume.io">contact support</a> to add a new project.</p>
                    
                    <Draggable v-if="projects.length" class="grid grid-4" v-model="projects" ghost-class="ghost" group="projects">
                        <ProjectTile v-for="project in projects" :key="project.id" :project="project"></ProjectTile>
                    </Draggable>
                    
                    <BaseNoResults v-else>
                        <v-icon name="cloud"></v-icon>
                        <h4 class="m-b-3">There are no projects available.</h4>
                        <p class="m-0">At this time it is not yet possible to create a new project.</p>
                    </BaseNoResults>
                </div>
                <div v-else>
                    <h2>External projects</h2>
                    <p class="lead">Projects from external teams that are assigned to you.</p>
                    <div class="grid grid-4" v-if="projects.length">
                        <ProjectTile v-for="project in projects" :key="project.id" :project="project" :webmaster="true"></ProjectTile>
                    </div>
                    <BaseNoResults v-else>
                        <v-icon name="cloud"></v-icon>
                        <h4 class="m-b-3">You have not been assigned to any projects.</h4>
                        <p class="m-0">To create a project select or create a team.</p>
                    </BaseNoResults>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Project from '@/models/Project.js'
import ProjectTile from '@/components/ProjectTile.vue'
import Draggable from 'vuedraggable'

export default {
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        projects: {
            get() {
                return Project.query().with('team').orderBy('position').get()
            },
            set(projects) {
                Project.dispatch('updatePositions', { projects: projects })
            }
        },
        team: function () {
            return this.user.team
        }
    },
    components: {
        ProjectTile,
        Draggable
    }
}
</script>
