<template>
    <div class="page">
        <BasePreloader v-if="loading"></BasePreloader>
        <div v-else>
            <router-view :project="project" v-if="project"></router-view>
            <NotFound v-else>
                <div slot="title">Project not found</div>
                <div slot="p">The requested project has not been found.</div>
            </NotFound>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Project from '@/models/Project.js'
import NotFound from '@/components/NotFound.vue'

export default {
    title: (data) => data.project.name,
    data: function () {
        return {
            loading: true
        }
    },
    created: function() {
        this.loadProject()
    },
    computed: {
        project_slug: function () {
            return this.$route.params.project
        },
        project: function () {
            return Project.slug(this.$route.params.project)
        },
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    methods: {
        loadProject: function() {
            const project = this.project ? this.project : Project.slug(this.$route.params.project)
            if (!project) {
                return this.$router.push('/')
            }

            Project.dispatch('single', {
                id: project.id,
                with: ['sections', 'lists', 'team'],
                where_has: { team: this.user.selected_team_id },
            }).then(() => {
                this.loading = false
            })
        }
    },
    watch: {
        project_slug: function (newValue, oldValue) {
            if(newValue != oldValue) {
                this.loadProject()
            }
        }
    },
    components: {
        NotFound
    }
}
</script>
