<template>
    <div>
        <TheModal></TheModal>
        <TheNotification></TheNotification>
        <TheTopbar></TheTopbar>
        <TheSidebar></TheSidebar>
        <TheContentBar></TheContentBar>
        <TheContents></TheContents>
        <TheNotifications></TheNotifications>
        <vue-confirm-dialog class="vue-confirm-dialog"></vue-confirm-dialog>
    </div>
</template>

<script>
    import TheModal from '@/components/TheModal.vue'
    import TheNotification from '@/components/TheNotification.vue'
    import TheTopbar from '@/components/TheTopbar.vue'
    import TheSidebar from '@/components/TheSidebar.vue'
    import TheContentBar from '@/components/TheContentBar.vue'
    import TheContents from '@/components/TheContents.vue'
    import TheNotifications from '@/components/TheNotifications.vue'

    export default {
        created: function () {

        },
        components: {
            TheModal,
            TheNotification,
            TheTopbar,
            TheSidebar,
            TheContentBar,
            TheContents,
            TheNotifications
        }
    }
</script>