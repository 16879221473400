<template>
    <div>
        <form class="form" action="submit" v-on:submit.prevent="save">
            <div class="form-group" v-if="optionExists('placeholder')">
                <label>Placeholder</label>
                <input class="text-input" type="text" v-model="field.options.placeholder" placeholder="Empty" v-autofocus>
            </div>
            <div class="form-group" v-if="optionExists('default_value')">
                <label>Default value</label>
                <input class="text-input" type="text" v-model="field.options.default_value" placeholder="Empty">
            </div>
            <div class="form-group" v-if="optionExists('checkbox_label')">
                <label>Checkbox label</label>
                <input class="text-input" type="text" v-model="field.options.checkbox_label" placeholder="Empty">
            </div>
            <div class="form-group" v-if="optionExists('content_type') && field.type=='text'">
                <label>Content type</label>
                <select class="select-input" v-model="field.options.content_type">
                    <option value="text">Text</option>
                    <option value="integer">Integer</option>
                    <option value="email">Email</option>
                    <option value="ip">IP address</option>
                    <option value="url">URL</option>
                </select>
            </div>
            <div class="form-group" v-if="optionExists('content_type') && field.type=='textarea'">
                <label>Content type</label>
                <select class="select-input" v-model="field.options.content_type">
                    <option value="plain">Plain</option>
                    <option value="markdown">Markdown</option>
                    <option value="wysiwyg">Wysiwyg</option>
                </select>
            </div>
            <div class="form-group" v-if="optionExists('editor_height') && 0">
                <label>Editor height</label>
                <select class="select-input" v-model="field.options.editor_height">
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                    <option value="xlarge">Extra large</option>
                </select>
            </div>
            <div class="form-group" v-if="optionExists('selection_type')">
                <label class="m-b-3">Display options as</label>
                <label class="radiobutton"><input type="radio" v-model="field.options.selection_type" value="dropdown"> <span>Dropdown</span></label>
                <label class="radiobutton"><input type="radio" v-model="field.options.selection_type" value="radio-buttons"> <span>Radio buttons</span></label>
            </div>
            <div class="form-group" v-if="optionExists('translatable')">
                <Toggle v-model="field.options.translatable">Translatable field</Toggle>
            </div>
            <div class="form-group" v-if="optionExists('required')">
                <Toggle v-model="field.options.required">Required field</Toggle>
            </div>
            <div class="form-group" v-if="optionExists('default_state')">
                <Toggle v-model="field.options.default_state">Checked by default</Toggle>
            </div>
            <div class="form-group" v-if="optionExists('gallery')">
                <Toggle v-model="field.options.gallery">Allow multiple images</Toggle>
            </div>
            <div class="form-group" v-if="optionExists('multiple')">
                <Toggle v-model="field.options.multiple">Allow multiple files</Toggle>
            </div>
            <input class="button hidden" type="submit">
        </form>
    </div>
</template>

<script>
import Toggle from '@/components/FormElements/Toggle.vue'

export default {
    props: ['field', 'save'],
    components: {
        Toggle
    },
    methods: {
        optionExists: function (property) {
            return property in this.field.options
        }
    }
}
</script>
