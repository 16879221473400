<template>
    <div class="table-images">
        <div class="table-images-image" v-for="(file, index) in files_to_show" v-bind:key="index">
            <div class="fileuploader-files-file-thumbnail fileuploader-files-file-thumbnail-sm file" v-bind:style="{ 'background': getColor(file) }">
                {{ getExtension(file) }}
            </div>
        </div>
        <div class="table-images-more" v-if="show_more_indicator">{{ total_indicator }}</div>
    </div>
</template>

<script>
export default {
    props: ['files'],
    computed: {
        visible_files: function () {
            return 3
        },
        files_to_show: function () {
            return this.files.slice(0, this.visible_files)
        },
        show_more_indicator: function () {
            return this.files.length > this.visible_files
        },
        total_indicator: function () {
            return '+' + (this.files.length - this.visible_files)
        }
    },
    methods: {
        getColor: function (file) {
            switch(this.getExtension(file)) {
                case 'doc':
                    return '#5270C8'
                case 'jpg':
                    return '#EAB684'
                case 'webp':
                    return '#FF6600'
                case 'png':
                    return '#704F7A'
                case 'psd':
                    return '#4C4EF6'
                case 'eps':
                    return '#FF6666'
                case 'cdr':
                    return '#009934'
                case 'txt':
                    return '#666666'
                case 'gif':
                    return '#FF999A'
                case 'ppt':
                    return '#F37F49'
                case 'mp3':
                    return '#00CCFF'
                case 'wav':
                    return '#27B8B5'
                case 'ai':
                    return '#FF6600'
                case 'mov':
                    return '#2374A8'
                case 'exe':
                    return '#F0C000'
                case 'dmg':
                    return '#B6B6B6'
                case 'rar':
                    return '#663331'
                case 'pdf':
                    return '#E72D2F'
                case 'zip':
                default:
                    return '#983365'
            }
        },
        getExtension: function (file) {
            return file.split(/[#?]/)[0].split('.').pop().trim();
        }
    }
}
</script>