<template>
    <div class="page" v-if="list_editor && can('create-list')">
        <div class="page-contents">
            <div class="limit-content-width">
                <div class="padding">
                    <h2>Add list</h2>
                    <p class="lead">A list represents a table of entries such as articles, posts or other datasets your project requires.</p>
                    <form class="form" action="submit" v-on:submit.prevent="saveList">
                        <div class="form-group">
                            <label>Name<small>Required</small></label>
                            <input class="text-input" type="text" v-model="list_editor.name" v-autofocus>
                        </div>
                        <div class="form-group">
                            <label>Icon</label>
                            <div class="iconbox fade-in" v-on:click="toggleSelectIcon" v-if="!select_icon"><v-icon :name="list_editor.icon" width="40"></v-icon></div>
                            <IconSelector :selected="list_editor.icon" :select="selectIcon" v-else class="fade-in"></IconSelector>
                        </div>
                        <input class="button" type="submit" value="Save list">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import List from '@/models/List.js'
import Event from '@/libraries/eventbus.js'
import IconSelector from '@/components/IconSelector.vue'

export default {
    props: ['project'],
    data: function () {
        return {
            list_editor: null,
            select_icon: false
        }
    },
    created: function () {
        if(!this.can('create-list')) {
            this.$router.push(this.project.link + '/lists')
        }

        this.list_editor = this.clone(new List({
            project_id: this.project.id
        }))
    },
    methods: {
        toggleSelectIcon: function () {
            this.select_icon = !this.select_icon
        },
        selectIcon: function (icon) {
            this.list_editor.icon = icon
            this.select_icon = false
        },
        saveList: function () {
            List.dispatch('post', this.list_editor).then(response => {
                if(! response.data.errors) {
                    Event.dispatch('list.saved', response.data)
                    this.$router.push(List.slug(response.data.slug).link + '/fields')
                } else {
                    Event.dispatch('list.errors', response.data.errors)
                }
            })
        }
    },
    components: {
        IconSelector
    }
}
</script>
