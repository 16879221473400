import { Model } from '@vuex-orm/core'
import Section from './Section'
import List from './List'
import Team from './Team'

export default class Project extends Model {
    static get entity() {
        return 'projects'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            team_id: this.attr(),
            name: this.attr(),
            slug: this.attr(),
            initials: this.attr(),
            language_code: this.attr(),
            color: this.attr(),
            position: this.number(),
            type: this.attr(),
            access_token: this.attr(),
            cache_webhook: this.attr(null),
            role: this.attr(),
            capabilities: this.attr([]),
            available_roles: this.attr([]),
            project_languages: this.attr([]),
            users: this.attr([]),

            // Relations
            sections: this.hasMany(Section, 'project_id'),
            lists: this.hasMany(List, 'project_id'),
            team: this.belongsTo(Team, 'team_id'),
        }
    }

    get link() {
        return '/projects/' + this.slug
    }
}
