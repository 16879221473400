export default [
	{
		type: 'text',
		icon: 'type',
		category: 'field',
		creating_label: 'New text',
		default_options: {
			required: false,
			placeholder: '',
			default_value: '',
			content_type: 'text',
			translatable: false,
			validation_rules: [
			]
		}
	},
	{
		type: 'textarea',
		icon: 'align-left',
		category: 'field',
		creating_label: 'New textarea',
		default_options: {
			required: false,
			placeholder: '',
			default_value: '',
			content_type: 'plain',
			editor_height: 'medium',
			translatable: false,
			validation_rules: [

			]
		}
	},
	{
		type: 'checkbox',
		icon: 'toggle-left',
		category: 'field',
		creating_label: 'New checkbox',
		default_options: {
			checkbox_label: '',
			default_state: false
		}
	},
	{
		type: 'select',
		icon: 'list',
		category: 'field',
		creating_label: 'New select',
		default_options: {
			required: false,
			placeholder: '',
			selection_type: 'dropdown',
			options: []
		}
	},
	{
		type: 'image',
		icon: 'image',
		category: 'field',
		creating_label: 'New image',
		default_options: {
			required: false,
			gallery: false,
			versions: []
		}
	},
	{
		type: 'file',
		icon: 'upload-cloud',
		category: 'field',
		creating_label: 'New file upload',
		default_options: {
			required: false,
			multiple: false
		}
	},
	// {
	// 	type: 'date',
	// 	icon: 'calendar',
	// 	category: 'field',
	// 	creating_label: 'New date',
	// 	default_options: {
	// 		required: false,
	// 		placeholder: '',
	// 		default_value: ''
	// 	}
	// },
	{
		type: 'group',
		icon: 'folder',
		category: 'field',
		creating_label: 'New group',
		default_options: {}
	},
	{
		type: 'list',
		icon: 'layers',
		category: 'relation',
		creating_label: 'New list',
		default_options: {}
	}
]