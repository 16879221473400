<template>
    <div>
        <div class="message message-dashed m-b-3" v-if="!versions_editor.length">No versions available</div>
        <div v-else>
            <div class="field-versions">
                <FieldEditVersionsVersion v-for="(version, index) in versions_editor" :version="version" :key="index" :index="index" :save="saveVersion"></FieldEditVersionsVersion>
            </div>
        </div>
        <a class="link-icon" v-on:click="addVersion"><v-icon name="plus-square"></v-icon> Version</a>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import FieldEditVersionsVersion from '@/components/ContentBar/FieldEditVersionsVersion.vue'

export default {
    props: ['field', 'save'],
    data: function () {
        return {
            versions_editor: []
        }
    },
    created: function () {
        this.versions_editor = this.clone(this.field.options.versions)
    },
    methods: {
        addVersion: function () {
            this.versions_editor.push({
                id: uuidv4(),
                name: null,
                identifier: null,
                resize: {
                    width: 500,
                    height: 500,
                    type: 'fit'
                },
                filters: []
            })
        },
        saveVersion: function (version, index) {
            if (!version.name) {
                this.versions_editor.splice(index, 1)
            } else {
                this.versions_editor.splice(index, 1, version)
            }
            this.field.options.versions = this.versions_editor
            this.save()
        }
    },
    components: {
        FieldEditVersionsVersion
    }
}
</script>
