<template>
    <div class="box box-no-shadow m-b-0 box-border" :class="{ 'box-creating': relation.creating, 'box-editing': editing }">
        <div class="tile-actions field-tile-actions">
            <ul v-if="!relation.creating">
                <li v-tooltip="'Edit'" v-on:click="openEditor"><v-icon name="edit"></v-icon></li>
                <li v-tooltip="'Remove'" v-on:click="removeRelation"><v-icon name="trash"></v-icon></li>
                <li v-tooltip="'Reorder'" class="draggable-handle"><v-icon name="menu"></v-icon></li>
            </ul>
        </div>
        <div class="box-padding p-t-7 p-b-7">
            <div class="relation-tile-icon">
                <v-icon :name="relation.icon" v-tooltip="relation.type"></v-icon>
            </div>
            <div v-if="!relation.creating">
                <h4 class="m-b-1">{{ relation.name }}</h4>
                <h5><small class="l-0">{{ relation.identifier }}</small></h5>

                <RelationTag v-if="related_module" :type="relation.type" :target="relation.related_module.identifier"></RelationTag>
            </div>
            <div v-else>
                <h5 class="m-b-1 font-weight-lighter lightest">Creating "{{ relation.name }}" relation</h5>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Relation from '@/models/Relation.js'
import RelationTag from '@/components/RelationTag.vue'
import Event from '@/libraries/eventbus.js'

export default {
    props: ['relation'],
    computed: {
        ...mapState({
            content_bar: state => state.ui.content_bar
        }),
        related_module: function () {
            return this.relation.related_module
        },
        editing: function () {
            return this.content_bar.type === 'relation.edit' && this.content_bar.data === this.relation.id
        }
    },
    methods: {
        openEditor: function () {
            this.$store.commit('ui/setContentBar', { type: 'relation.edit', data: this.relation.id })
        },
        removeRelation: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this relation?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        Relation.dispatch('remove', this.relation).then(() => {
                            Event.dispatch('relation.deleted', this.field)
                        })
                    }
                }
            })
        }
    },
    components: {
        RelationTag
    }
}
</script>
