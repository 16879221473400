<template>
    <div>
        <input v-show="!active_language" class="text-input" type="text" v-bind:class="{ 'text-input-error': field.has_error }" v-model="inputVal" :placeholder="field.options.placeholder">
        <div v-for="(translation, language) in translations" :key="language">
            <div v-show="language === active_language">
                <input class="text-input" type="text" v-autofocus :value="inputTranslations[language]" v-on:input="updateTranslation(language, $event.target.value)" :disabled="!isTranslatable">
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    props: ['field', 'value', 'translations', 'active_language'],
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        inputTranslations: {
            get() {
                return this.translations
            },
            set() {
                //
            }
        },
        isTranslatable: function () {
            if (!this.field.options.translatable) return false
            if (!_.size(this.translations)) return false

            return true
        }
    },
    methods: {
        updateTranslation: function (language, value) {
            const translations = this.translations
            translations[language] = value
            this.$emit('translate', translations)
        }
    }
}
</script>