<template>
    <div class="modal-box-contents modal-box-contents-padding">
        <div class="fade-in form">
            <div class="form-group">
                <label>Email address<small>Required</small></label>
                <input class="text-input" type="email" v-bind:class="{ 'text-input-error': errors.email }" v-model="form.email" placeholder="Enter the email address">
            </div>

            <div class="form-group" v-if="has_multiple_roles">
                <label>Role<small>Required</small></label>
                <select v-if="roles" class="select-input" v-bind:class="{ 'select-input-error': errors.role_id }" v-model="form.role_id">
                    <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                </select>
            </div>

            <input type="hidden" v-model="form.role_id">

            <div>
                <a class="button" v-on:click="save">Add member</a>
            </div>
        </div>
    </div>
</template>

<script>
import Event from '@/libraries/eventbus.js'

import Invitation from '@/models/Invitation.js'
import Project from '@/models/Project.js'
import Role from '@/models/Role.js'
import Team from '@/models/Team.js'

export default {
    props: ['modal', 'close'],
    data: function () {
        return {
            form: {
                firstname: '',
                lastname: '',
                email: '',
                role_id: ''
            },
            errors: {
                firstname: false,
                lastname: false,
                email: false,
                role_id: false
            }
        }
    },
    created: function () {
        this.loadRoles()
    },
    computed: {
        data: function () {
            return this.modal.data
        },
        team: function () {
            return Team.find(this.data.team_id)
        },
        project: function () {
            return Project.find(this.data.project_id)
        },
        type: function () {
            return this.data.team_id ? 'team' : 'project'
        },
        roles: function () {
            return Role.query()
                .orderBy('position')
                .get()
        },
        has_multiple_roles: function () {
            return this.roles && this.roles.length > 1
        },
        params: function () {
            if(this.type === 'team') {
                return {
                    scope: {
                        available_for_teams: true
                    }
                }
            }

            return {
                scope: {
                    available_for_projects: true
                }
            }
        }
    },
    methods: {
        loadRoles: function () {
            Role.dispatch('query', this.params)
            .then(response => {
                if (response.success) {
                    if(!this.form.role_id && this.roles.length) {
                        this.form.role_id = this.roles[0].id
                    }
                }
            })
        },
        save: function () {
            this.close(this.modal.id)
            switch(this.type) {
                case 'team':
                    return this.inviteToTeam()
                case 'project':
                    return this.inviteToProject()
            }
        },
        inviteToTeam: function () {
            Invitation.dispatch('inviteToTeam', { ... { team_id: this.team.id }, ... this.form }).then(response => {
                if(response.success) {
                    Event.dispatch('team_member.saved')
                } else {
                    Event.dispatch('invitation.errors', response.data.errors)
                }
            })
        },
        inviteToProject: function() {
            Invitation.dispatch('inviteToProject', { ... { project_id: this.project.id }, ... this.form }).then(response => {
                if(response.success) {
                    Event.dispatch('project_user.saved')
                } else {
                    Event.dispatch('invitation.errors', response.data.errors)
                }
            })
        }
    }
}
</script>
