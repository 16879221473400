import ApiService from '@/libraries/api'
import Section from '@/models/Section'
import Event from '@/libraries/eventbus'

export default {
    actions: {
        async query(state, parameters) {
            Section.commit((state) => {
                state.loading = true
            })

            Section.deleteAll()
            return ApiService.get('/sections', { params: parameters }).then(response => Section.insertOrUpdate(response.data))
        },

        async single(state, parameters) {
            Section.commit((state) => {
                state.loading = true
            })

            return ApiService.get(`/sections/${parameters.id}`, { params: parameters }).then(response => Section.insertOrUpdate(response.data))
        },

        async post(store, params) {
            return ApiService.post('/sections', params).then(response => {
                if (!response.error) {
                    Section.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        },

        async patch(store, params) {
            return ApiService.patch('/sections/' + params.id, params).then(response => {
                if (!response.error) {
                    Section.insertOrUpdate(response.data)
                    return response
                }
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async remove(store, params) {
            return ApiService.delete('/sections/' + params.id).then(response => {
                if (!response.error) {
                    Section.delete(params.id)
                    return response
                }
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async updatePositions(store, payload) {
            const params = {
                sections: payload.sections.map(section => section.id)
            }

            const data = payload.sections.map((section, index) => {
                return {
                    id: section.id,
                    position: index + 1
                }
            })

            Section.update({ data })

            return ApiService.post('/sections/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('section.positions_updated')
                    return response.data
                }
            }).catch(error => error.response)
        },
    }
}