<template>
    <div class="field-options-option" v-bind:class="{ 'default': option.default }">
        <div class="field-options-option-display" v-if="!editor">
            <div class="tile-actions field-options-option-tile-actions">
                <ul>
                    <li v-tooltip="'Edit'" v-on:click="openEditor"><v-icon name="edit"></v-icon></li>
                    <li v-tooltip="'Delete'" v-on:click="deleteOption"><v-icon name="trash"></v-icon></li>
                    <li v-tooltip="'Reorder'" class="draggable-handle"><v-icon name="menu"></v-icon></li>
                </ul>
            </div>
            <h6 class="m-0">{{ option.name }}<small>{{ option.value }}</small></h6>
        </div>
        <div class="field-options-option-display" v-else>
            <form class="form m-0" action="submit" v-on:submit.prevent="submit">
                <div class="form-group m-b-2">
                    <label>Name</label>
                    <input class="text-input" type="text" v-model="name" v-autofocus>
                    <div class="slug-input fade-in" v-if="value && !custom_value" v-on:click="useCustomValue">Value: <span class="slug-input-slug">{{ value }}</span></div>
                </div>
                <div class="form-group m-b-2 fade-in" v-if="custom_value">
                    <label>Value</label>
                    <input class="text-input" type="text" v-model="value">
                </div>
                <div class="clearfix">
                    <label><input type="checkbox" v-model="default_selected"> Set as default</label>
                    <input class="button button-small float-right" type="submit" value="Save">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['option', 'index', 'save'],
    data: function () {
        return {
            editor: false,
            name: '',
            value: '',
            custom_value: false,
            default_selected: false
        }
    },
    mounted: function () {
        if (!this.option.value) this.editor = true
        this.adoptValues()
        if (this.slug(this.name) !== this.value) this.custom_value = true
    },
    methods: {
        adoptValues: function () {
            this.name = this.option.name ? this.option.name : ''
            this.value = this.option.value ? this.option.value : ''
            this.default_selected = this.option.default ? this.option.default : false
        },
        submit: function () {
            this.editor = false
            this.option.name = this.name
            this.option.value = this.slug(this.value)
            this.option.default = this.default_selected

            this.save(this.option, this.index)
        },
        deleteOption: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this option?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        this.save({}, this.index)
                    }
                }
            })
        },
        openEditor: function () {
            this.adoptValues()
            this.editor = true
        },
        useCustomValue: function () {
            this.custom_value = true
        }
    },
    watch: {
        name: function (value) {
            if (!value || this.custom_value) return
            this.value = this.slug(value)
        }
    }
}
</script>
