<template>
    <div class="dropdown-menu" v-if="active">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['active']
    }
</script>