<template>
    <div class="relation-tag">
        <div class="relation-tag-type">{{ type }}</div>
        <div class="relation-tag-target">{{ target.replace(/(.{17})..+/, "$1&hellip;") }}</div>
    </div>
</template>

<script>
export default {
	props: ['type', 'target']
}
</script>
