import _ from 'lodash'
import FieldDefinitions from '@/definitions/fields.js'
import { Model } from '@vuex-orm/core'
import FieldData from './FieldData'
import File from './File'

export default class Field extends Model {
    static get entity () {
        return 'fields'
    }

    static state () {
        return {
            loading: false
        }
    }

    static fields () {
        return {
            id: this.attr(),
            name: this.attr(null),
            description: this.attr(null),
            slug: this.attr(),
            custom_icon: this.attr(),
            type: this.attr(null),
            position: this.attr(),
            options: this.attr({}),
            value: this.attr(null),
            has_error: this.boolean(false),

            // Relations
            module_id: this.attr(null),
            module_type: this.attr(null),
            module: this.morphTo('module_id', 'module_type'),

            group_id: this.attr(null),
            group: this.belongsTo(Field, 'group_id'),

            field_data: this.hasMany(FieldData, 'field_id'),
            files: this.hasMany(File, 'field_id'),
        }
    }

    static beforeCreate (model)
    {
        var field_definition = _.find(FieldDefinitions, field_definition => field_definition.type === model.type)
        model.options = { ...field_definition.default_options, ...model.options }
        if(field_definition.no_value) model.no_value = true
    }

    static beforeUpdate (model)
    {
        var field_definition = _.find(FieldDefinitions, field_definition => field_definition.type === model.type)
        model.options = { ...field_definition.default_options, ...model.options }
    }

    static afterDelete (model)
    {
        Field.delete(field => field.group_id === model.id)
    }

    get identifier () {
        return this.group ? this.group.identifier + '-' + this.slug : this.slug
    }

    get link () {
        if (!this.module) return null
        return this.module.link + '/fields/' + this.slug
    }

    get creating () {
        return this.id.includes('$')
    }

    get creating_label () {
        if (!this.creating) return null

        var field_definition = _.find(FieldDefinitions, field_definition => field_definition.type === this.type)
        return field_definition ? field_definition.creating_label : 'New field'
    }

    get icon () {
        if (this.custom_icon) return this.custom_icon

        var field_definition = _.find(FieldDefinitions, field_definition => field_definition.type === this.type)
        return field_definition ? field_definition.icon : 'help-circle'
    }

    get is_gallery () {
        return this.options && this.options.gallery
    }

    get is_multiple () {
        return this.options && this.options.multiple
    }

    get table_options () {
        return {
            sortable: this.type === 'text'
        }
    }

    // Is this field value savable
    get savable_value () {
        return !['group'].includes(this.type)
    }
}
