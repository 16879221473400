import ApiService from '@/libraries/api'
import User from '@/models/User'

export default {
    actions: {
        async query (state, parameters) {
            User.commit((state) => {
                state.loading = true
            })

            await ApiService.get('/users', { params: parameters }).then(response => User.insertOrUpdate(response.data))

            User.commit((state) => {
                state.loading = false
            })
        },

        async patch (store, params) {
            return ApiService.patch('/user', params).then(response => {
                if (!response.error) {
                    User.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        },
    }
}
