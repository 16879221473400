<template>
    <div class="text-align-center">
        <h2 class="m-b-3">See you next time! ✋🏻</h2>
        <p>Thank you for using Vume!</p>
        <img src="https://media.giphy.com/media/TS4lhxfqE6Ix2/giphy.gif" width="100%" class="border-radius m-b-3" />
        <router-link :to="'/signin'" class="button button-block m-0" exact>Awesome</router-link>
    </div>
</template>

<script>
    export default {
        //
    }
</script>