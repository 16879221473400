import Vue from 'vue'
import router from '@/router'
import vClickOutside from 'v-click-outside'
import VTooltip from 'v-tooltip'
import VueClone from '@/plugins/vue-clone'
import VueCan from '@/plugins/vue-can'
import VueSlug from '@/plugins/vue-slug'
import VueClipboard from '@/plugins/vue-clipboard'
import VueResponse from '@/plugins/vue-response'
import VueAutofocus from 'vue-autofocus-directive'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueObserveVisibility from 'vue-observe-visibility'
import VuePageTitle from 'vue-page-title'
import VueShortKey from 'vue-shortkey'
import axios from 'axios'
import moment from 'moment-timezone'
import feather from 'vue-icon'
import VueViewer from 'v-viewer'

// Import base components
import BaseSymbol from '@/components/BaseSymbol.vue'
import BasePreloader from '@/components/BasePreloader.vue'
import BaseNoResults from '@/components/BaseNoResults.vue'

// Vue use
Vue.use(VueViewer)
Vue.use(feather, 'v-icon')
Vue.use(vClickOutside)
Vue.use(VTooltip)
Vue.use(VueClone)
Vue.use(VueCan)
Vue.use(VueSlug)
Vue.use(VueClipboard)
Vue.use(VueResponse)
Vue.use(VueConfirmDialog)
Vue.use(VueObserveVisibility)
Vue.use(VuePageTitle, { suffix: '| Vume', router })
Vue.use(VueShortKey)

// Vue directives
Vue.directive('autofocus', VueAutofocus)

// Base components
Vue.component('BaseSymbol', BaseSymbol)
Vue.component('BasePreloader', BasePreloader)
Vue.component('BaseNoResults', BaseNoResults)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

// Axios defaults
axios.defaults.withCredentials = true
axios.defaults.headers.common['Agent'] = 'Vume'
axios.defaults.baseURL = process.env.VUE_APP_API

axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401 && !['/signin', '/signout', '/bye', '/passwordforgot', '/passwordreset'].includes(window.location.pathname) && !window.location.pathname.includes('/invitations/project/') && !window.location.pathname.includes('/invitations/team/')) {
        window.location.replace("/signin");
    } else {
        throw(error)
    }
})

// Moment defaults
moment.tz.setDefault('utc')