<template>
    <div class="modal-box-contents modal-box-contents-padding">
        <div class="fade-in form">
            <div class="form-group">
                <label>Role</label>
                <select v-if="roles" class="select-input" v-bind:class="{ 'select-input-error': errors.role_id }" v-model="form.role_id">
                    <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                </select>
            </div>
            <div>
                <a class="button" v-on:click="save">Change role</a>
            </div>
        </div>
    </div>
</template>

<script>
import Event from '@/libraries/eventbus.js'

import Project from '@/models/Project.js'
import Role from '@/models/Role.js'
import Team from '@/models/Team.js'

export default {
    props: ['modal', 'close'],
    data: function () {
        return {
            form: {
                role_id: ''
            },
            errors: {
                role_id: false
            }
        }
    },
    created: function () {
        this.loadRoles()
    },
    mounted: function() {
        this.form.role_id = this.member.role_id
    },
    computed: {
        data: function () {
            return this.modal.data
        },
        member: function () {
            return this.data.member
        },
        team: function () {
            return Team.find(this.data.team_id)
        },
        project: function () {
            return Project.find(this.data.project_id)
        },
        roles: function () {
            return Role.query()
                .where(role => this.available_roles.includes(role.identifier))
                .orderBy('position')
                .get()
        },
        type: function () {
            return this.data.team_id ? 'team' : 'project'
        },
        available_roles: function () {
            return this.data.available_roles ? this.data.available_roles : []
        },
    },
    methods: {
        loadRoles: function () {
            Role.dispatch('query').then(response => {
                if (response.success) {
                    if(!this.form.role_id && this.roles.length) {
                        this.form.role_id = this.roles[0].id
                    }
                }
            })
        },
        save: function () {
            let params
            switch(this.type) {
                case 'team':
                    params = { ... { team_id: this.team.id, member_id: this.member.id }, ... this.form }
                    Team.dispatch('changeTeamRole', params).then(response => {
                        if(response.success) {
                            Event.dispatch('team_member.role_updated')
                        } else {
                            Event.dispatch('team_member.errors', response.data.errors)
                        }
                    })
                    break
                case 'project':
                    params = { ... { project_id: this.project.id, user_id: this.member.id }, ... this.form }
                    Project.dispatch('changeProjectRole', params).then(response => {
                        if(response.success) {
                            Event.dispatch('project_user.role_updated')
                        } else {
                            Event.dispatch('project_user.errors', response.data.errors)
                        }
                    })
                    break
            }
            this.close(this.modal.id)
        }
    }
}
</script>
