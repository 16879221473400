<template>
    <div class="page" v-if="project_editor && can('update-project')">
        <div class="page-contents">
            <div class="limit-content-width">
                <div class="padding">
                    <h2>Project settings</h2>
                    <p class="lead">Here you can update your project settings. Be carreful when editing values, updating certain properties can break your implementation.</p>
                    <div class="box box-no-shadow box-border">
                        <div class="box-padding box-padding-icon">
                            <div class="box-icon-inside">
                                <BaseSymbol :initials="project.initials" :color="project.color" variant="box-icon"></BaseSymbol>
                            </div>
                            <div class="form">
                                <h3>Basic settings</h3>
                                <SingleFieldForm label="Name" paragraph="What is the name of the project." v-on:save="saveField('name')" v-model="project_editor.name"></SingleFieldForm>
                                <SingleFieldForm label="Project language" paragraph="The default language of this project." v-on:save="saveField('language_code')" v-model="project_editor.language_code" :options="project_language_options"></SingleFieldForm>
                                <SingleFieldForm label="Project slug" paragraph="The slug is used to build queries to fetch the data. <b>Be careful when updating this value.</b>" v-on:save="saveField('slug')" v-model="project_editor.slug"></SingleFieldForm>
                                <SingleFieldForm label="Project type" paragraph="What is the programming language of this project." v-on:save="saveField('type')" v-model="project_editor.type" :options="type_options"></SingleFieldForm>
                                <SingleFieldForm label="Project initials" paragraph="The initials used in the icon." v-on:save="saveField('initials')" v-model="project_editor.initials"></SingleFieldForm>
                                <div class="form-group">
                                    <label>Project color</label>
                                    <VueColorPicker v-model="project_editor.color" @input="saveColor" width="280px"></VueColorPicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box box-no-shadow box-border">
                        <div class="box-padding box-padding-icon">
                            <div class="box-icon-inside"><v-icon name="globe"></v-icon></div>
                            <div class="form">
                                <h3 class="m-b-3">Project translations</h3>
                                <p>If you enable translations for this project, the webmaster has the ability to fill in translations for specific fields.</p>
                                <form class="form" action="submit" v-on:submit.prevent="saveTranslationLanguages">
                                    <div class="form-group m-0">
                                        <Toggle v-model="translations.enabled">Enable translations</Toggle>
                                        <div v-if="translations.enabled" class="fade-in m-t-5">
                                            <div class="available-languages">
                                                <div class="available-languages-language" v-for="language in translation_language_options" :key="language.code">
                                                    <label>
                                                        <input type="checkbox" class="checkbox" v-model="project_editor.project_languages" :value="language.code">
                                                        <div class="language-box">
                                                            {{ language.name }}
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <p class="small m-0">If you are missing a language in this list please <a href="mailto:support@vume.io">contact support</a> and we will add this in our future release.</p>
                                        </div>
                                        <input class="button m-t-5 fade-in" v-if="show_save_translation_languages" type="submit" :value="'Save settings'">
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="box box-no-shadow box-border">
                        <div class="box-padding box-padding-icon">
                            <div class="box-icon-inside"><v-icon name="shield"></v-icon></div>
                            <div class="form">
                                <h3 class="m-b-3">Access token</h3>
                                <p>This is the token you can use to access the data in your application/website.</p>
                                <div class="form">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="text" class="text-input" :value="project.access_token" disabled="disabled" id="accessToken">
                                            <div class="input-group-append">
                                                <span class="input-group-text cursor-pointer" v-tooltip="'Copy to clipboard'" v-on:click="copy(project.access_token)">
                                                    <v-icon name="copy"></v-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box box-no-shadow box-border">
                        <div class="box-padding box-padding-icon">
                            <div class="box-icon-inside"><v-icon name="link"></v-icon></div>
                            <form class="form" action="submit" v-on:submit.prevent="saveCacheWebhook">
                                <h3 class="m-b-3">Webhook</h3>
                                <p>Here you can set the webhook to clear your cache after a data update.</p>
                                <div class="form">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="text" class="text-input" v-model="project_editor.cache_webhook">
                                        </div>
                                    </div>

                                    <input class="button" type="submit" value="Save">
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="box box-no-shadow box-border" v-if="can('delete-project')">
                        <div class="box-padding box-padding-icon">
                            <div class="box-icon-inside"><v-icon name="cloud-lightning" class="icon-danger"></v-icon></div>
                            <div class="form">
                                <h3>Danger zone</h3>
                                <div class="box box-no-shadow box-border box-border-light">
                                    <div class="box-padding box-padding-small">
                                        <a class="button button-danger button-small m-t-1 float-right" v-on:click="deleteProject">Delete project</a>
                                        <h5 class="m-b-1">Delete this project</h5>
                                        <p class="m-0">Once you delete a project, there is no going back. Please be certain.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Twitter as VueColorPicker } from 'vue-color'
import SingleFieldForm from '@/components/SingleFieldForm.vue'
import Project from '@/models/Project.js'
import Event from '@/libraries/eventbus.js'
import Toggle from '@/components/FormElements/Toggle.vue'
import Languages from '@/definitions/languages.js'
import { mapGetters } from 'vuex'

export default {
    props: ['project'],
    data: function () {
        return {
            project_editor: null,
            available_languages: Languages,
            form: {
                slug_disabled: true,
                type_disabled: true,
                initials_disabled: true,
            },
            translations: {
                enabled: false
            }
        }
    },
    created: function () {
        this.project_editor = this.clone(this.project)
        this.translations.enabled = this.project.project_languages.length ? true : false
    },
    computed: {
        type_options: function () {
            return [
                {
                    value: 'PHP - Laravel',
                    label: 'PHP - Laravel'
                },
                {
                    value: 'PHP',
                    label: 'PHP'
                }
            ]
        },
        project_language_options: function () {
            return this.available_languages.filter(function (language) {
                return this.project.project_languages.includes(language.code) ? false : true
            }.bind(this)).map(function (language) {
                return {
                    value: language.code,
                    label: language.name
                }
            })
        },
        translation_language_options: function () {
            return this.available_languages.filter(function (language) {
                return this.project.language_code === language.code ? false : true
            }.bind(this))
        },
        show_save_translation_languages: function () {
            return JSON.stringify(this.project.project_languages) !== JSON.stringify(this.project_editor.project_languages)
        },
        ...mapGetters({
            capabilities: 'entities/projects/capabilities'
        })
    },
    methods: {
        saveColor: function () {
            this.project_editor.color = this.project_editor.color.hex
            this.saveProject()
        },
        saveProject: function (project) {
            project = typeof project !== 'undefined' ? project : this.project_editor
            Project.dispatch('patch', project).then(response => {
                if(!response.data.errors) {
                    Event.dispatch('project.saved', this.project)
                    if(this.$route.params.project !== response.data.slug) {
                        this.$router.push(this.project.link.replace(this.project.slug, response.data.slug) + '/settings')
                    }
                } else {
                    Event.dispatch('project.errors', response.data.errors)
                }
            })
        },
        saveField: function (field) {
            let project = this.clone(this.project)
            project[field] = this.project_editor[field]

            this.saveProject(project)
        },
        saveTranslationLanguages: function () {
            this.saveField('project_languages')
        },
        saveCacheWebhook: function() {
            this.saveField('cache_webhook')
        },
        toggleFormEditor: function(field) {
            this.form[field] = ! this.form[field]
        },
        deleteProject: function () {
            this.$confirm({
                message: 'Are you sure you want to delete this project forever?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        Project.dispatch('remove', this.project).then(response => {
                            if(response.success) {
                                Event.dispatch('project.deleted', this.project)
                                this.$router.push({
                                    path: '/projects'
                                })
                            } else {
                                Event.dispatch('project.errors', response.data.errors)
                            }
                        })
                    }
                }
            })
        },
        copy: function (access_token) {
            this.clipboard(access_token)
            Event.dispatch('clipboard.access_token')
        }
    },
    watch: {
        'translations.enabled': function (state) {
            if(!state) this.project_editor.project_languages = []
            if(state) this.project_editor.project_languages = this.project.project_languages
        }
    },
    components: {
        VueColorPicker,
        SingleFieldForm,
        Toggle
    }
}
</script>
