import { Model } from '@vuex-orm/core'

export default class RelationEntry extends Model {
    static entity = 'relationEntry'

    static primaryKey = ['relation_id', 'entry_id']

    static fields() {
        return {
            relation_id: this.attr(null),
            entry_id: this.attr(null),
            target_entry_id: this.attr(null)
        }
    }
}