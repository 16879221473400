<template>
    <div class="form-group">
        <div class="grid grid-2">
            <div>
                <label class="m-b-1" v-text="label"></label>
                <p class="small m-0" v-html="paragraph"></p>
            </div>
            <SingleFieldFormInput v-model="inputVal" v-on:save="save()" :options="options"></SingleFieldFormInput>
        </div>
    </div>
</template>

<script>

import SingleFieldFormInput from '@/components/SingleFieldFormInput.vue'

export default {
    props: ['label', 'paragraph', 'value', 'options'],
    data: function () {
        return {
            disabled: true
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        save: function() {
            this.$emit('save', this.inputVal)
        }
    },
    components: {
        SingleFieldFormInput
    }
}
</script>