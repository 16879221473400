import { Model } from '@vuex-orm/core'

export default class Invitation extends Model {
    static get entity () {
        return 'invitations'
    }

    static state () {
        return {
            //
        }
    }

    static fields () {
        return {
            id: this.attr(),
            invitation_type: this.attr(),
            token: this.attr({})
        }
    }
}