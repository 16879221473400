import { Model } from '@vuex-orm/core'

import Field from './Field'
import FieldData from './FieldData'

export default class File extends Model {
    static get entity () {
        return 'files'
    }

    static state () {
        return {
            //
        }
    }

    static fields () {
        return {
            id: this.attr(),

            name: this.attr(),
            type: this.attr(),
            file: this.attr(null),
            extension: this.attr(null),
            url: this.attr(null),
            position: this.attr(),

            // Relations
            field_id: this.attr(null),
            field_data_id: this.attr(null),

            // API file id
            file_id: this.attr(),

            field: this.belongsTo(Field, 'field_id'),
            field_data: this.belongsTo(FieldData, 'field_data_id'),
        }
    }

    static beforeCreate (model)
    {
        model.file_id = model.id
        const duplicate = File.query().where('file_id', model.id).first()
        if(duplicate) {
            File.delete(duplicate.id)
        }
    }

    static beforeUpdate (model)
    {
        const duplicate = File.query().where('file_id', model.id).first()
        if(duplicate) {
            File.delete(duplicate.id)
        }
    }
}