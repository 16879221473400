import { Model } from '@vuex-orm/core'
import Project from './Project'
import User from './User'

export default class Team extends Model {
    static get entity () {
        return 'teams'
    }

    static state () {
        return {
            loading: false
        }
    }

    static fields () {
        return {
            id: this.attr(),
            user_id: this.attr(),
            name: this.attr(),
            initials: this.attr(),
            color: this.attr(),
            role: this.attr(),
            capabilities: this.attr([]),
            available_roles: this.attr([]),
            members: this.attr([]),

            // Relations
            user: this.belongsTo(User, 'user_id'),
            projects: this.hasMany(Project, 'team_id')
        }
    }
}
