<template>
    <div id="app">
        <TheLockPage v-if="!user"></TheLockPage>
        <TheApplication v-if="user"></TheApplication>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import TheApplication from '@/components/TheApplication.vue'
    import TheLockPage from '@/components/TheLockPage.vue'
    
    export default {
        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
        },
        // created: function () {
        //     this.loadProjects()
        // },
        // methods: {
        //     loadProjects: function () {
        //         Project.dispatch('query')
        //     }
        // },
        components: {
            TheApplication,
            TheLockPage
        }
    }
</script>