<template>
    <div class="not-found">
        <v-icon name="cloud-lightning"></v-icon>
        <h2 class="m-b-3"><slot name="title"></slot></h2>
        <p><slot name="p"></slot></p>
    </div>
</template>

<script>
export default {
	//
}
</script>
