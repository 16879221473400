<template>
    <NotFound>
        <div slot="title">Page not found</div>
        <div slot="p">The requested page has not been found.</div>
    </NotFound>
</template>

<script>
    import NotFound from '@/components/NotFound.vue'

    export default {
        components: {
            NotFound
        }
    }
</script>