<template>
    <div class="contentbar-contents">
        <div class="fade-in">
            <div class="padding">
                <h3>Fields</h3>
                <ul class="field-types">
                    <li class="field-type" v-for="field_type in field_types" v-bind:key="field_type.type" v-on:click="createField(field_type)">
                        <div class="field-type-icon"><v-icon :name="field_type.icon"></v-icon></div>
                        <div class="field-type-name">{{ field_type.type }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import FieldDefinitions from '@/definitions/fields.js'
import Field from '@/models/Field.js'

export default {
    props: ['data'],
    computed: {
        field_types: function () {
            return FieldDefinitions.filter(type => type.category === 'field')
        },
        relation_types: function () {
            return FieldDefinitions.filter(type => type.category === 'relation')
        },
        group: function () {
            return this.data.group ? Field.find(this.data.group) : null
        }
    },
    methods: {
        createField: function (field_type) {
            Field.insert({
                data: {
                    type: field_type.type,
                    module_id: this.data.module.id,
                    module_type: this.data.module.module_type,
                    group: this.group ? this.group.id : null,
                    unsaved_changes: true
                }
            }).then((entities) => {
                this.$store.commit('ui/setContentBar', { type: 'field.edit', data: entities['fields'][0].id })
            })
        }
    }
}
</script>