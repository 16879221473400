<template>
    <VueEditor v-model="inputVal" :editorToolbar="customToolbar"></VueEditor>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
    props: ['field', 'value', 'rows'],
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        customToolbar: function () {
            return [
                ['bold', 'italic', 'underline'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'color': [] }, { 'background': [] }]
            ]
        }
    },
    components: {
        VueEditor
    }
}
</script>