<template>
    <div>
        <div class="message message-dashed m-b-3" v-if="!options_editor.length">No options created</div>
        <div v-else>
            <div class="field-options">
                <Draggable :list="options_editor" handle=".draggable-handle" ghost-class="ghost" group="options" v-on:end="updatePositions">
                    <FieldEditOptionsOption v-for="(option, index) in options_editor" :option="option" :key="index" :index="index" :save="saveOption"></FieldEditOptionsOption>
                </Draggable>
            </div>
        </div>
        <a class="link-icon" v-on:click="addOption"><v-icon name="plus-square"></v-icon> Option</a>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import FieldEditOptionsOption from '@/components/ContentBar/FieldEditOptionsOption.vue'

export default {
    props: ['field', 'save'],
    data: function () {
        return {
            options_editor: []
        }
    },
    created: function () {
        this.options_editor = this.clone(this.field.options.options)
    },
    methods: {
        addOption: function () {
            this.options_editor.push({
                name: null,
                value: null,
                default: false
            })
        },
        saveOption: function (option, index) {
            if (!option.name || !option.value) this.options_editor.splice(index, 1)
            if(option.default) this.options_editor = this.options_editor.map((editor_option, option_index) => {
                editor_option.default = option_index === index
                return editor_option
            })
            this.field.options.options = this.options_editor.filter(option => option.name && option.value)
            this.save()
        },
        updatePositions: function () {
            this.field.options.options = this.options_editor.filter(option => option.name && option.value)
            this.save()
        }
    },
    components: {
        Draggable,
        FieldEditOptionsOption
    }
}
</script>
