<template>
    <div>
        <h3>Please add your credentials</h3>
        <ul class="message message-clickable message-danger fade-in" v-if="errors" v-on:click="hideErrors()">
            <li v-for="(error, errorIndex) in errors" :key="'error_' + errorIndex" v-text="error"></li>
        </ul>
        <form class="form" action="submit" v-on:submit.prevent="saveCredentials">
            <div class="form-group">
                <label>Firstname</label>
                <input class="text-input" type="text" name="firstname" placeholder="" v-model="form.firstname">
            </div>

            <div class="form-group">
                <label>Lastname</label>
                <input class="text-input" type="text" name="lastname" placeholder="" v-model="form.lastname">
            </div>

            <div class="form-group">
                <label>Password</label>
                <input class="text-input" type="password" name="password" placeholder="" v-model="form.password">
            </div>
            <div class="form-group">
                <label>Confirm password</label>
                <input class="text-input" type="password" name="password_confirmation" placeholder="" v-model="form.password_confirmation">
            </div>

            <input class="button" type="submit" value="Save">
        </form>
    </div>
</template>

<script>

export default {
    data: function () {
        return {
            errors: null,
            form: {
                firstname: '',
                lastname: '',
                password: '',
                password_confirmation: ''
            }
        }
    },
    computed: {
        token: function() {
            return this.$route.params ? this.$route.params.token : null
        }
    },
    methods: {
        saveCredentials: function () {
            this.hideErrors()

            this.$store.dispatch('auth/registerByInvitation', { ...{ token: this.token }, ... this.form })
            .then(response => {
                if(response.success) {
                    this.$store.dispatch('auth/user').then(res => {
                        if(res) {
                            this.$router.push('/')
                        } else {
                            this.errors = ['Something went wrong. Please try again later']
                        }
                    })
                } else {
                    this.errors = Object.keys(response.data.errors).reduce(function (r, k) {
                        return r.concat(response.data.errors[k]);
                    }, [])
                }
            })
        },
        hideErrors: function() {
            this.errors = null
        }
    }
}
</script>
