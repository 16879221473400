<template>
    <div class="topbar-usermenu" v-click-outside="closeUserMenu">
        <div class="topbar-usermenu-badge" v-on:click="toggleUserMenu">
            <BaseSymbol :color="user.team ? user.team.color : null" :initials="initials" :avatar="user.avatar"></BaseSymbol>
            <div class="topbar-usermenu-badge-company">
                <div class="topbar-usermenu-badge-company-name">
                    {{ user.team ? user.team.name : user.name }}
                </div>
                <div class="topbar-usermenu-badge-company-user">
                    {{ user.team ? user.name : user.email }}
                </div>
            </div>
        </div>
        <DropDownMenu class="right" :active="user_menu.open">
            <ul>
                <li :class="{ 'active': isActiveTeam(null) }">
                    <a v-on:click.stop.prevent="switchTeam(null)">
                        <div class="menu-icon"><BaseSymbol variant="menu" :avatar="user.avatar"></BaseSymbol></div>
                        <div class="menu-label">{{ user.name }}</div>
                    </a>
                </li>
                <li v-for="team in accesible_teams" :key="team.id" :class="{ 'active': isActiveTeam(team.id) }">
                    <a v-on:click.stop.prevent="switchTeam(team.id)">
                        <div class="menu-icon"><BaseSymbol :color="team.color" variant="menu" :initials="team.initials"></BaseSymbol></div>
                        <div class="menu-label" v-text="team.name"></div>
                    </a>
                </li>
            </ul>
            <ul>
                <li v-if="user.team">
                    <a v-on:click="navigateTo('/team')">
                        <div class="menu-icon"><BaseSymbol variant="menu" icon="settings"></BaseSymbol></div>
                        <div class="menu-label">Team settings</div>
                    </a>
                </li>
                <li v-if="user.team">
                    <a v-on:click="navigateTo('/billing')">
                        <div class="menu-icon"><BaseSymbol variant="menu" icon="credit-card"></BaseSymbol></div>
                        <div class="menu-label">Billing</div>
                    </a>
                </li>
                <li>
                    <a v-on:click="navigateTo('/signout')">
                        <div class="menu-icon"><BaseSymbol variant="menu" icon="log-out"></BaseSymbol></div>
                        <div class="menu-label">Sign out</div>
                    </a>
                </li>
            </ul>
        </DropDownMenu>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import DropDownMenu from '@/components/DropDownMenu.vue'
    import User from '@/models/User.js'

    export default {
        computed: {
            ...mapState({
                user_menu: state => state.ui.user_menu
            }),
            ...mapGetters({
                user: 'auth/user'
            }),
            initials: function() {
                if(!this.user) {
                    return null
                }
                return this.user && this.user.team ? this.user.team.initials : this.user.initals
            },
            accesible_teams: function () {
                return this.user.teams.filter(team => team.role !== 'project_user')
            }
        },
        methods: {
            closeUserMenu: function () {
                this.$store.commit('ui/setUserMenuOpen', false)
            },
            toggleUserMenu: function () {
                this.$store.commit('ui/setUserMenuOpen', !this.user_menu.open)
            },
            switchTeam: function (team_id) {
                this.closeUserMenu()
                if(this.isActiveTeam(team_id)) {
                    return
                }
                User.dispatch('patch', { selected_team_id: team_id }).then(() => {
                    this.$router.push('/').catch(() => {})
                })
            },
            isActiveTeam: function (team_id) {
                return team_id === this.user.selected_team_id
            },
            navigateTo: function (link) {
                this.closeUserMenu()
                this.$router.push(link).catch(() => {})
            }
        },
        components: {
            DropDownMenu
        }
    }
</script>