import _ from 'lodash'
import { Model } from '@vuex-orm/core'
import Field from './Field'
import Entry from './Entry'
import File from './File'
import truncate from 'truncate'

export default class FieldData extends Model {
    static get entity() {
        return 'field_data'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            value: this.attr(null),
            translations: this.attr({}),

            field_id: this.attr(null),
            field: this.belongsTo(Field, 'field_id'),

            entry_id: this.attr(null),
            entry: this.belongsTo(Entry, 'entry_id'),

            field_data_images: this.attr([]),
            field_data_files: this.attr([]),
            files: this.hasMany(File, 'field_data_id'),

            deleting_file: this.boolean(false)
        }
    }

    static beforeCreate(model) {
        if (model.value) {
            return
        }
        if (typeof model.field_data_images === 'undefined' && typeof model.field_data_files) {
            return
        }

        if (!model.field_data_images.length && !model.field_data_files.length) {
            return
        }
        model.value = []
        for (var i = 0; i < model.field_data_images.length; i++) {
            let file = model.field_data_images[i]
            if (!file.persisted) {
                continue
            }

            model.value.push(file.id)
            file.file_id = file.id
            file.field_data_id = model.id
            file.field_id = model.field_id
            file.url = file.preview ? file.preview : file.url
            File.insertOrUpdate({ data: file })
        }
        for (var j = 0; j < model.field_data_files.length; j++) {
            let file = model.field_data_files[j]
            if (!file.persisted) {
                continue
            }

            model.value.push(file.id)
            file.file_id = file.id
            file.field_data_id = model.id
            file.field_id = model.field_id
            File.insertOrUpdate({ data: file })
        }
    }

    static beforeUpdate(model) {
        if (model.value) {
            return
        }
        if (Array.isArray(model.value) && !model.value.length) {
            return
        }

        if (typeof model.field_data_images === 'undefined' && typeof model.field_data_files === 'undefined') {
            return
        }
        const field = Field.find(model.field_id)

        if (!field || !['image', 'file'].includes(field.type)) {
            return
        }
        model.value = []

        switch (field.type) {
            case 'image':
                for (var i = 0; i < model.field_data_images.length; i++) {
                    let file = model.field_data_images[i]
                    if (!file.persisted) {
                        continue
                    }

                    model.value.push(file.id)
                    file.file_id = file.id
                    file.field_data_id = model.id
                    file.field_id = model.field_id
                    file.url = file.preview ? file.preview : file.url
                    const original_file = File.query().where('field_id', field.id).where('file_id', file.id).where('field_data_id', model.id).first()
                    if (!original_file) {
                        File.insertOrUpdate({ data: file })
                    }
                }
                break
            case 'file':
                for (var j = 0; j < model.field_data_files.length; j++) {
                    let file = model.field_data_files[j]
                    if (!file.persisted) {
                        continue
                    }

                    model.value.push(file.id)
                    file.file_id = file.id
                    file.field_data_id = model.id
                    file.field_id = model.field_id
                    File.insertOrUpdate({ data: file })
                }
                break
        }
    }

    get creating() {
        return this.id.includes('$')
    }

    get table_value() {
        if (!this.field) return this.value

        switch (this.field.type) {
            case 'text':
                return truncate(this.value, 20)
            case 'textarea':
                return truncate(this.value, 50)
            case 'select':
                var options = typeof this.field.options !== 'undefined' && Array.isArray(this.field.options.options) ? this.field.options.options : null
                if(!options) return truncate(this.value, 50)
                var option = options.find(option => option.value === this.value)
                return option && option.name ? option.name : truncate(this.value, 50)
            case 'image':
            case 'file':
                return this.files ? _.orderBy(this.files, ['position']).map(file => file.url) : []
            default:
                return this.value
        }
    }
}