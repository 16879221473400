<template>
    <div class="fade-in">
        <div class="padding">
            <div v-if="entry_count">
                <div class="actions flex flex-center">
                    <SearchField class="m-r-2" v-on:search="searchEntries"></SearchField>
                    <router-link :to="module.link + '/entries/new'" class="button" v-if="can('create-entry')">Add entry</router-link>
                </div>
                <h3>Entries <small v-if="pagination.total_results">{{ pagination.total_results }}</small></h3>
                <div v-if="entries.length">
                    <EntriesTable :list="module" :entries="entries"></EntriesTable>
                    <LoadMore v-if="!loading" :pagination="pagination" :loadMore="loadAdditionalResults" :autoload="true"></LoadMore>
                    <Pagination v-if="0" :pagination="pagination" :setPage="loadPage"></Pagination>
                </div>
                <BaseNoResults v-else-if="query && !loading">
                    <v-icon name="triangle"></v-icon>
                    <h4 class="m-b-3">No search results</h4>
                    <p>We could not find any matches for your query <b>{{ query }}</b>.</p>
                    <a class="button" v-on:click="resetSearch">Reset search</a>
                </BaseNoResults>
            </div>

            <BaseNoResults v-if="!loading && fields.length && !entry_count">
                <v-icon name="layers"></v-icon>
                <h4 class="m-b-3">Add your first entry</h4>
                <p>Create you first entry in this list.</p>
                <router-link :to="module.link + '/entries/new'" class="button">Add entry</router-link>
            </BaseNoResults>

            <BaseNoResults v-if="!loading && !fields.length">
                <v-icon name="book"></v-icon>
                <div v-if="can('create-field')">
                    <h4 class="m-b-3">Go to fields</h4>
                    <p>Please add at least one field first.</p>
                    <router-link :to="module.link + '/fields?add=true'" class="button">To fields</router-link>
                </div>
                <div v-else>
                    <h4 class="m-b-3">No fields created</h4>
                    <p>Please contact an administrator.</p>
                </div>
            </BaseNoResults>

            <BasePreloader class="preloader-less-height" v-if="loading"></BasePreloader>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Field from '@/models/Field.js'
import FieldData from '@/models/FieldData.js'
import File from '@/models/File.js'
import Entry from '@/models/Entry.js'
import EntriesTable from '@/components/EntriesTable.vue'
import Pagination from '@/components/Pagination.vue'
import LoadMore from '@/components/LoadMore.vue'
import SearchField from '@/components/SearchField.vue'

export default {
    props: ['module'],
    data: function () {
        return {
            entry_count: 0,
            entries: [],
            query: null,
            pagination: {
                current_page: 1,
                results_page: 30,
                total_results: 0
            }
        }
    },
    created: function () {
        this.resetAll()
        this.pagination.current_page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
        this.loadEntries()
    },
    beforeDestroy: function() {
        this.resetAll()
    },
    computed: {
        ...mapState({
            loading: state => state.entities.entries.loading
        }),
        entry_list: function () {
            return Entry.query()
                .where('list_id', this.module.id)
                .orderBy('real_position')
                .with(['list', 'list.project', 'field_data', 'field_data.field', 'field_data.files'])
                .limit(this.pagination.results_page * this.pagination.current_page)
                .get()
        },
        fields: function () {
            return Field.query()
                .where('module_id', this.module.id)
                .where('module_type', this.module.module_type)
                .where('group_id', null)
                .orderBy('position')
                .withAllRecursive()
                .get()
        }
    },
    methods: {
        loadEntries: function () {
            let params = {
                where_has: {
                    list: this.module.id
                },
                search: this.query,
                with: ['list', 'field_data.field', 'field_data.parent_field_data_images', 'field_data.field_data_files'],
                page: this.pagination.current_page,
                results_page: this.pagination.results_page
            }

            if(this.module && this.module.sort_direction && this.module.sort_direction == 'recent_first') {
                params.sort_by = 'created_at'
                params.sort_direction = 'DESC'
            } else {
                params.sort_by = 'position'
            }

            Entry.dispatch('query', params).then(response => {
                if(response.success) {
                    if(!this.entry_count) this.entry_count = response.meta.total
                    this.entries = this.entry_list
                    this.pagination.total_results = response.meta.total
                }
            })
        },
        searchEntries: function (query) {
            this.pagination.current_page = 1
            this.query = query
        },
        resetSearch: function () {
            this.query = null
        },
        resetAll: function () {
            Entry.deleteAll()
            FieldData.deleteAll()
            File.deleteAll()
        },
        loadPage: function (page) {
            if(page === this.pagination.current_page) return
            this.$router.push({query: {page: page}})
        },
        loadAdditionalResults: function () {
            if(!this.loading) {
                this.pagination.current_page++
                this.loadEntries()
            }
        }
    },
    watch: {
        query: function(query) {
            this.query = query
            Entry.deleteAll()
            this.loadEntries()
        },
        '$route': function(to) {
            this.pagination.current_page = to.query.page ? parseInt(to.query.page) : 1
            this.loadEntries()
        }
    },
    components: {
        EntriesTable,
        Pagination,
        LoadMore,
        SearchField
    }
}
</script>
