<template>
    <div class="box-actions" v-click-outside="closeMenu">
        <div class="box-actions-dots" v-on:click.stop="openMenu">
            <div class="box-actions-dots-dot"></div>
            <div class="box-actions-dots-dot"></div>
            <div class="box-actions-dots-dot"></div>
        </div>
        <div class="box-actions-menu" v-if="open">
            <ul>
                <slot></slot>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            open: false
        }
    },
    methods: {
        openMenu: function () {
            this.open = true
        },
        closeMenu: function () {
            this.open = false
        }
    }
}
</script>
