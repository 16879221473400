<template>
    <div class="sidebar" v-bind:class="{ 'sidebar-open': sidebar_open, 'notification-open': notification_open }">
        <router-link to="/" class="sidebar-logo"></router-link>
        <div class="sidebar-menu" v-if="sidebar_open">

            <div v-if="can('create-section') || sections.length">
                <h6 class="cursor-pointer" v-on:click="linkTo(project.link + '/sections')">Sections <v-icon name="chevron-right"></v-icon></h6>
                <ul>
                    <li v-for="section in sections" :key="section.id">
                        <router-link :to="section.link">
                            <v-icon :name="section.icon"></v-icon>{{ section.name }} <div class="label" v-if="section.admin_only">Admin</div>
                        </router-link>
                    </li>
                    <li v-if="!sections.length && can('create-section')">
                        <router-link :to="project.link + '/add/section'"><v-icon name="plus-circle"></v-icon>Add section</router-link>
                    </li>
                </ul>
           </div>

            <div v-if="can('create-list') || lists.length">
                <h6 class="cursor-pointer" v-on:click="linkTo(project.link + '/lists')">Lists <v-icon name="chevron-right"></v-icon></h6>
                <ul>
                    <li v-for="list in lists" :key="list.id"><router-link :to="list.link"><v-icon :name="list.icon"></v-icon>{{ list.name }} <div class="label" v-if="list.admin_only">Admin</div></router-link></li>
                    <li v-if="!lists.length && can('create-list')">
                        <router-link :to="project.link + '/add/list'"><v-icon name="plus-circle"></v-icon>Add list</router-link>
                    </li>
                </ul>
           </div>
            <div v-if="can('update-project')" class="fade-in">
                <h6>Project</h6>
                <ul>

                    <li><router-link :to="project.link + '/settings'" exact><v-icon name="settings"></v-icon>Settings</router-link></li>
                    <li v-if="can('view-project-users')"><router-link :to="project.link + '/settings/members'" exact><v-icon name="users"></v-icon>Members</router-link></li>
                    <li v-if="false"><router-link :to="project.link + '/log'" exact><v-icon name="terminal"></v-icon>Log</router-link></li>

                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Project from '@/models/Project.js'
    import Section from '@/models/Section.js'
    import List from '@/models/List.js'

    export default {
        computed: {
            project: function () {
                return Project.slug(this.$route.params.project)
            },
            sections: function () {
                return Section.query().where('project_id', this.project.id).withAllRecursive().orderBy('position').get()
            },
            lists: function () {
                return List.query().where('project_id', this.project.id).withAllRecursive().orderBy('position').get()
            },
            sidebar_open: function () {
                return this.project ? true : false
            },
            ...mapState({
                notification_open: state => state.ui.notification,
            })
        },
        methods: {
            linkTo: function (link) {
                this.$router.push({
                    path: link
                }, null, function() {
                    //
                })
            }
        }
    }
</script>