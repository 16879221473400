<template>
    <div class="symbol" :class="classes" :style="style">
        <div class="symbol-initials" v-if="initials">{{ initials }}</div>
        <div class="symbol-icon" v-if="icon">
            <v-icon :name="icon"></v-icon>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['initials', 'color', 'variant', 'avatar', 'icon'],
        computed: {
            style: function () {
                return {
                    'background-image': this.background_image,
                    'background-color': this.background_color
                }
            },
            background_image: function () {
                if(this.avatar && !this.initials) return 'url(' + this.avatar + ')'
                return 'none'
            },
            background_color: function () {
                if(this.icon) return 'transparent'
                if(this.color) return this.color
                return '#4D71EE'
            },
            classes: function () {
                return {
                    'symbol-menu': this.variant && this.variant === 'menu',
                    'symbol-large': this.variant && this.variant === 'large'
                }
            }
        }
    }
</script>