<template>
    <div class="table-images">
        <div class="table-images-image" v-for="(image, index) in images_to_show" v-bind:key="index">
            <div class="table-images-image-bg" v-bind:style="{ 'background-image': 'url(' + image + ')' }"></div>
        </div>
        <div class="table-images-more" v-if="show_more_indicator">{{ total_indicator }}</div>
    </div>
</template>

<script>
export default {
    props: ['images'],
    computed: {
        visible_images: function () {
            return 3
        },
        images_to_show: function () {
            return this.images.slice(0, this.visible_images)
        },
        show_more_indicator: function () {
            return this.images.length > this.visible_images
        },
        total_indicator: function () {
            return '+' + (this.images.length - this.visible_images)
        }
    }
}
</script>