import { Model } from '@vuex-orm/core'
import List from './List'
import FieldData from './FieldData'
import Relation from './Relation'
import RelationEntry from './RelationEntry'

export default class Entry extends Model {
    static get entity() {
        return 'entries'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            position: this.number(1),
            real_position: this.number(1),
            relation_position: this.number(1),

            // Relations
            list_id: this.attr(null),
            list: this.belongsTo(List, 'list_id'),
            field_data: this.hasMany(FieldData, 'entry_id'),
            relations: this.belongsToMany(Relation, RelationEntry, 'entry_id', 'relation_id')
        }
    }

    get link() {
        if (!this.list) return null
        return this.list.link + '/entries/' + this.id
    }
}