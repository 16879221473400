<template>
    <div>
        <div class="message message-dashed m-b-3" v-if="!validation_rules_editor.length">No rules created</div>
        <div v-else>
            <div class="field-validation">
                <FieldEditValidationRule v-for="(rule, index) in validation_rules_editor" :rule="rule" :key="index" :index="index" :save="saveRule"></FieldEditValidationRule>
            </div>
        </div>
        <a class="link-icon" v-on:click="addRule"><v-icon name="plus-square"></v-icon> Rule</a>
    </div>
</template>

<script>
import FieldEditValidationRule from '@/components/ContentBar/FieldEditValidationRule.vue'

export default {
    props: ['field', 'save'],
    data: function () {
        return {
            validation_rules_editor: []
        }
    },
    created: function () {
        this.validation_rules_editor = this.clone(this.field.options.validation_rules)
    },
    methods: {
        addRule: function () {
            this.validation_rules_editor.push({
                rule: null,
                value: null,
                options: {}
            })
        },
        saveRule: function (rule, index) {
            console.log(rule, index)
        }
    },
    components: {
        FieldEditValidationRule
    }
}
</script>
