import axios from 'axios'
import Qs from 'qs'

export default {
    get (resource, params) {
        if (typeof params !== 'undefined') {
            params = serializeParams(omit(params, 'id'))
        }
        return axios.get(resource, params).then(function (response) {
            return response
        }).catch(function () {
            throw new Error('stop_script')
        })
    },

    patch (resource, params) {
        return axios.patch(resource, params)
    },

    post (resource, params) {
        return axios.post(resource, params)
    },

    put (resource, params) {
        return axios.put(resource, params)
    },

    delete (resource, params) {
        return axios.delete(resource, { data: params })
    }
}

function serializeParams (params) {
    return {
        params,
        paramsSerializer: function (params) {
            return Qs.stringify(omit(params.params, 'id'), { arrayFormat: 'brackets' })
        }
    }
}

function omit (obj, key) {
    if (typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, key)) {
        delete obj[key]
    }
    return obj
}
