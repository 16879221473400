<template>
    <div class="modal-box-contents modal-box-contents-padding">
        <form class="form" action="submit" v-on:submit.prevent="saveCard">
            <div class="form-group">
                <label>Name on card</label>
                <input class="text-input" type="text">
            </div>
            <div class="form-group">
                <label>Card number</label>
                <div class="fake-input">
                <StripeElements :stripe-key="stripeKey" :instance-options="instanceOptions" :elements-options="elementsOptions" #default="{ elements }" ref="elms">
                    <StripeElement type="card" :elements="elements" :options="cardOptions" ref="card"></StripeElement>
                </StripeElements>
                </div>
            </div>
            <input type="submit" class="button" value="Save">
        </form>
    </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'

export default {
    props: ['modal', 'close'],
    data: function () {
        return {
            stripeKey: 'pk_test_51JRwEBBhWuKxuRe2nG6tvgRuCWhk1DdJhh9O2F0wNvIYgBEyGXs6faYgbsKSOGf3hsUMBGAe1WnlbphAx0YnrYUb00x6uiGIUS',
        }
    },
    computed: {
        //
    },
    methods: {
        saveCard: function () {
           
        //
        }
    },
    components: {
        StripeElements,
        StripeElement
    }
}
</script>

// visa
// - mastercard
// - american-express
// - diners-club
// - discover
// - jcb
// - unionpay
// - maestro
// - mir
// - elo
// - hiper
// - hipercard