<template>
    <div class="page page-light">
        <div class="page-contents">
            <div class="padding">
                <router-link :to="'/'" class="back">
                    <v-icon name="chevron-left"></v-icon>
                    Back to projects
                </router-link>
                <h2 class="m-b-10">Billing information</h2>
                <div class="box box-no-shadow box-border">
                    <div class="box-padding box-padding-icon">
                        <div class="box-icon-inside">
                            <v-icon name="credit-card"></v-icon>
                        </div>
                        <h4 class="m-b-2">Payment method</h4>
                        <p>Please add your preferred payment method. You can use a credit / debit card.</p>
                        <a class="button button-small" v-on:click="openPaymentMethodModal">Add card</a>
                    </div>
                    
                </div>
                <div class="box box-no-shadow box-border">
                    <div class="box-padding box-padding-icon">
                        <div class="box-icon-inside">
                            <v-icon name="tag"></v-icon>
                        </div>
                        <h4 class="m-b-2">Billing information</h4>
                        <p>Provide additional billing info such as company name, address, or VAT number.</p>
                        <a class="button button-small button-line">Edit information</a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/models/Modal.js'

export default {
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        team: function () {
            return this.user.team
        }
    },
    methods: {
        openPaymentMethodModal: function () {
            Modal.dispatch('launch', {
                type: 'team.paymentmethod',
                variant: 'small',
                name: 'Add card',
                data: {
                    type: 'project',
                    //project_id: this.project.id,
                }
            })
        }
    }
}
</script>
