<template>
    <div>
        <div v-if="hasContentType('plain')">
            <textarea v-show="!active_language" class="text-textarea" v-bind:class="{ 'text-textarea-error': field.has_error }" v-model="inputVal" v-if="field.type == 'textarea'" :rows="rows"></textarea>
            <div v-for="(translation, language) in translations" :key="language">
                <div v-show="language === active_language">
                    <textarea class="text-textarea" v-model="inputTranslations[language]" :rows="rows" :disabled="!isTranslatable"></textarea>
                </div>
            </div>
        </div>
        <div v-if="hasContentType('wysiwyg')">
            <TextAreaRich v-show="!active_language" field="field" :rows="rows" v-model="inputVal"></TextAreaRich>
            <div v-for="(translation, language) in translations" :key="language">
                <div v-show="language === active_language">
                    <TextAreaRich field="field" :rows="rows" v-model="inputTranslations[language]" :disabled="!isTranslatable"></TextAreaRich>
                </div>
            </div>
        </div>
        <div v-if="hasContentType('markdown')">
            <TextareaMarkdown v-show="!active_language" field="field" value="value" rows="rows" v-model="inputVal"></TextareaMarkdown>
            <div v-for="(translation, language) in translations" :key="language">
                <div v-show="language === active_language">
                    <TextareaMarkdown field="field" :rows="rows" v-model="inputTranslations[language]" :disabled="!isTranslatable"></TextareaMarkdown>
                </div>
            </div>
        </div>
        <div v-if="hasContentType('json')">
            <TextareaJson v-show="!active_language" field="field" :rows="rows" v-model="inputVal"></TextareaJson>
            <div v-for="(translation, language) in translations" :key="language">
                <div v-show="language === active_language">
                    <TextareaJson field="field" :rows="rows" v-model="inputTranslations[language]" :disabled="!isTranslatable"></TextareaJson>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import TextAreaRich from '@/components/FieldForms/TextareaRich.vue'
import TextareaMarkdown from '@/components/FieldForms/TextareaMarkdown.vue'
import TextareaJson from '@/components/FieldForms/TextareaJson.vue'

export default {
    props: ['field', 'value', 'translations', 'active_language'],
    computed: {
        rows: function () {
            switch(this.field.options.editor_height) {
                case 'small':
                    return 4
                case 'large':
                    return 15
                case 'xlarge':
                    return 30
                default:
                    return 10
            }
        },
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        inputTranslations: {
            get() {
                return this.translations
            }
        },
        isTranslatable: function () {
            if (!this.field.options.translatable) return false
            if (!_.size(this.translations)) return false

            return true
        }
    },
    methods: {
        hasContentType: function (type) {
            return this.field.options.content_type === type
        }
    },
    components: {
        TextAreaRich,
        TextareaMarkdown,
        TextareaJson
    }
}
</script>