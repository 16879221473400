<template>
    <div class="no-results fade-in">
        <slot></slot>
    </div>
</template>

<script>
export default {
    //
}
</script>
