import Modal from '@/models/Modal.js'

export default {
    getters: {
        //
    },
    actions: {
        launch: function (store, payload) {
            Modal.insert({
                name: payload.name,
                data: payload
            })
        },
    }
}