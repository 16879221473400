export default [
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'nl',
        name: 'Dutch',
    },
    {
        code: 'fr',
        name: 'French',
    },
    {
        code: 'de',
        name: 'German',
    },
    {
        code: 'es',
        name: 'Spanish',
    },
    {
        code: 'it',
        name: 'Italian',
    }
]