<template>
    <div class="page" v-if="section_editor && can('create-section')">
        <div class="page-contents">
            <div class="limit-content-width">
                <div class="padding">
                    <h2>Add section</h2>
                    <p class="lead">A section is a single form of fields that can represent a website page.</p>
                    <form class="form" action="submit" v-on:submit.prevent="saveSection">
                        <div class="form-group">
                            <label>Name<small>Required</small></label>
                            <input class="text-input" type="text" v-model="section_editor.name" v-autofocus>
                            <div class="slug-input fade-in" v-if="section_editor.slug && !slug_editor">Slug: <span class="slug-input-slug" v-on:click="toggleSlugEditor">{{ section_editor.slug ? section_editor.slug : '' }}</span></div>
                        </div>
                        <div class="form-group">
                            <label>Icon</label>
                            <div class="iconbox fade-in" v-on:click="toggleSelectIcon" v-if="!select_icon"><v-icon :name="section_editor.icon" width="40"></v-icon></div>
                            <IconSelector :selected="section_editor.icon" :select="selectIcon" v-else class="fade-in"></IconSelector>
                        </div>
                        <input class="button" type="submit" value="Save section">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Section from '@/models/Section.js'
import Event from '@/libraries/eventbus.js'
import IconSelector from '@/components/IconSelector.vue'

export default {
    props: ['project'],
    data: function () {
        return {
            slug_editor: false,
            section_editor: null,
            select_icon: false
        }
    },
    created: function () {
        if(!this.can('create-section')) {
            this.$router.push(this.project.link + '/sections')
        }

        this.section_editor = this.clone(new Section({
            project_id: this.project.id
        }))
    },
    methods: {
        toggleSlugEditor: function () {
            this.slug_editor = !this.slug_editor
        },
        toggleSelectIcon: function () {
            this.select_icon = !this.select_icon
        },
        selectIcon: function (icon) {
            this.section_editor.icon = icon
            this.select_icon = false
        },
        saveSection: function () {
            Section.dispatch('post', this.section_editor).then(response => {
                if(! response.data.errors) {
                    Event.dispatch('section.saved', response.data)
                    this.$router.push(Section.slug(response.data.slug).link + '/fields')
                } else {
                    Event.dispatch('section.errors', response.data.errors)
                }
            })
        }
    },
    components: {
        IconSelector
    }
}
</script>
