<template>
    <div class="modal-box-contents">
        <div v-if="!loading" class="fade-in">
            <EntryEditor v-if="module" :module="module" :entry_id="entry_id" :modal="true" v-on:saved="saved" v-on:loaded="formLoaded"></EntryEditor>
            <!-- <div v-if="!form_loading">
                <a class="button" v-on:click="save">Save entry</a>
            </div> -->
        </div>
    </div>
</template>

<script>
import Event from '@/libraries/eventbus.js'
import List from '@/models/List.js'
import Relation from '@/models/Relation.js'

import EntryEditor from '@/components/EntryEditor.vue'

export default {
    props: ['modal', 'close'],
    data: function () {
        return {
            loading: true,
            form_loading: true
        }
    },
    created: function () {
        this.loadList()
    },
    computed: {
        data: function () {
            return this.modal.data
        },
        relation: function () {
            return Relation.find(this.data.relation_id)
        },
        module: function () {
            return this.relation ? List.query().where('id', this.relation.related_module_id).withAll().first() : null
        },
        entry_id: function () {
            return typeof this.data.entry_id !== 'undefined' ? this.data.entry_id : null
        }
    },
    methods: {
        loadList: function () {
            List.dispatch('single', {
                id: this.module.id,
                with: ['fields', 'relations.related_module']
            }).then(() => {
                this.loading = false
            })
        },
        formLoaded: function () {
            this.form_loading = false
        },
        save: function () {
            Event.dispatch('entry_editor.save.' + this.module.id, { module_id: this.module ? this.module.id : null })
        },
        saved: function (response) {
            if(response.success) this.close(this.modal.id)
            if(response.success && response.new_entry) {
                if(this.modal.data.target_entry_id && this.modal.data.target_entry_id.includes('$')) {
                    Event.dispatch('relation.entry.created.' + this.relation.id, { entry_id: response.entry_id })
                } else {
                    Relation.dispatch('saveEntry', {
                        id: this.relation.id,
                        entry_id: response.entry_id,
                        target_entry_id: this.modal.data.target_entry_id
                    }).then(() => {
                        Event.dispatch('relation.entry.created.' + this.relation.id, { entry_id: response.entry_id })
                    })
                }
            }
        }
    },
    components: {
        EntryEditor
    }
}
</script>
