<template>
    <div class="field-versions-version fade-in" v-if="version_editor">
        <div class="field-versions-version-display" v-if="!editor">
            <div class="tile-actions field-versions-version-tile-actions">
                <ul>
                    <li v-tooltip="'Edit'" v-on:click="openEditor"><v-icon name="edit"></v-icon></li>
                    <li v-tooltip="'Delete'" v-on:click="deleteVersion"><v-icon name="trash"></v-icon></li>
                </ul>
            </div>
            <h6 class="m-0">{{ version.name }}<small>{{ version.identifier }}</small></h6>
        </div>
        <div class="field-versions-version-editor" v-else>
            <form class="form m-0" action="submit" v-on:submit.prevent="submit">
                <div class="form-group m-b-2">
                    <label>Version name</label>
                    <input class="text-input" type="text" v-model="version_editor.name" v-autofocus>
                    <div class="slug-input fade-in" v-if="version_editor.identifier">Identifier: <span class="slug-input-slug">{{ version_editor.identifier }}</span></div>
                </div>
                <div class="form-group m-b-2">
                    <div class="grid grid-small-gap grid-2 m-b-5">
                        <div class="form-group m-0">
                            <label>Width</label>
                            <div class="input-with-label">
                                <input class="text-input" type="text" v-model="version_editor.resize.width">
                                <div class="label">PX</div>
                            </div>
                        </div>
                        <div class="form-group m-0">
                            <label>Height</label>
                            <div class="input-with-label">
                                <input class="text-input" type="text" v-model="version_editor.resize.height">
                                <div class="label">PX</div>
                            </div>
                        </div>
                    </div>
                    <label class="m-b-3">Resize logic</label>
                    <label class="radiobutton"><input type="radio" v-model="version_editor.resize.type" value="fit"> <span>Best fit</span></label>
                    <label class="radiobutton"><input type="radio" v-model="version_editor.resize.type" value="crop"> <span>Crop</span></label>
                    <!-- <label class="radiobutton"><input type="radio" v-model="version_editor.resize.type" value="crop-exact"> <span>Crop to exact size</span></label> -->
                </div>
                <input class="button button-small float-right" type="submit" value="Save">
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['version', 'index', 'save'],
    data: function () {
        return {
            editor: false,
            version_editor: null
        }
    },
    created: function () {
        this.version_editor = this.clone(this.version)
        if(!this.version_editor.name) this.editor = true
    },
    methods: {
        submit: function () {
            this.editor = false
            this.save(this.version_editor, this.index)
        },
        openEditor: function () {
            this.editor = true
        },
        deleteVersion: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this version?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        this.save({}, this.index)
                    }
                }
            })
        }
    },
    watch: {
        'version_editor.name': function (value) {
            if (!value) return
            this.version_editor.identifier = this.slug(value)
        }
    },
    components: {
        //
    }
}
</script>