import { Model } from '@vuex-orm/core'
import Team from './Team'
import Project from './Project'

export default class User extends Model {
    static get entity () {
        return 'users'
    }

    static state () {
        return {
            loading: false
        }
    }

    get name () {
        return this.firstname + ' ' + this.lastname
    }

    static fields () {
        return {
            id: this.attr(),
            team_id: this.attr(),
            selected_team_id: this.attr(null),
            firstname: this.attr(),
            lastname: this.attr(),
            initials: this.attr('MP'),
            email: this.attr(),
            avatar: this.attr(),

            // Relations
            team: this.belongsTo(Team, 'team_id'),
            teams: this.hasMany(Team, 'user_id'),
            subscribed_projects: this.hasMany(Project, 'user_id')
        }
    }

    static beforeCreate (model) {
        if(model.team_id) return

        if(model.selected_team_id) {
            model.team = Team.find(model.selected_team_id)
            model.team_id = model.team ? model.team.id : null
        }
    }

    static beforeUpdate (model) {
        if(model.selected_team_id === model.team_id) return

        if(model.selected_team_id) {
            model.team = Team.find(model.selected_team_id)
            model.team_id = model.team ? model.team.id : null
        } else {
            model.team = null
            model.team_id = null
        }
    }
}
