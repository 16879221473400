<template>
    <div v-if="team">
        <div class="page page-light">
            <div class="page-contents">
                <div class="padding">
                    <router-link :to="'/'" class="back">
                        <v-icon name="chevron-left"></v-icon>
                        Back to projects
                    </router-link>
                    <h2>Add project</h2>
                    <p class="lead">Add new project in the {{ team.name }} team.</p>
                    <form class="form" action="submit" v-on:submit.prevent="saveProject">
                        <div class="form-group">
                            <label>Name<small>Required</small></label>
                            <input class="text-input" type="text" v-model="project_editor.name" v-autofocus>
                            <div class="slug-input fade-in" v-if="project_editor.slug && !slug_editor">Slug: <span class="slug-input-slug" v-on:click="toggleSlugEditor">{{ project_editor.slug ? project_editor.slug : '' }}</span></div>
                        </div>
                        <div class="form-group fade-in" v-if="slug_editor">
                            <label>Slug<small>Unique</small></label>
                            <input class="text-input" type="text" v-model="project_editor.slug" autofocus>
                            <p class="small m-t-1">Unique field for building queries</p>
                        </div>
                        <div class="form-group">
                            <label class="m-b-1">Project type</label>
                            <p class="small m-b-2">What is the programming language of this project.</p>

                            <select class="select-input" v-model="project_editor.type">
                                <option value="PHP">PHP</option>
                                <option value="PHP">PHP - Laravel</option>
                                <option value="PHP">Node</option>
                            </select>
                        </div>


                        <input class="button" type="submit" value="Save project">
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Event from '@/libraries/eventbus.js'
import Project from '@/models/Project.js'
import project_colors from '@/definitions/colors.js'

export default {
    data: function () {
        return {
            project_editor: {
                name: '',
                slug: '',
                type: 'PHP',
                team_id: '',
                color: ''
            },
            slug_editor: false,
            saving: false
        }
    },
    created: function () {
        this.project_editor.color = project_colors[Math.floor(Math.random() * project_colors.length)]
    },
    methods: {
        toggleSlugEditor: function () {
            this.slug_editor = !this.slug_editor
        },
        saveProject: function () {
            this.saving = true
            this.project_editor.team_id = this.team.id

            Project.dispatch('post', this.project_editor).then(response => {
                this.saving = false
                if (response.success) {
                    this.$router.push({
                        path: '/projects/' + response.data.slug
                    })

                } else {
                    Event.dispatch('project.errors', response.data.errors)
                }
            })
        },
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        team: function () {
            return this.user.team
        }
    },
    watch: {
        'project_editor.name': function (input) {
            if(!input) return null
            if (this.slug_editor) return null
            return this.project_editor.slug = this.slug(input ? input : '')
        }
    }
}
</script>
