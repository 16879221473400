<template>
    <div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            //
        }
    },
    mounted: function () {
        this.signOut()
    },
    methods: {
        async signOut () {
            await this.$store.dispatch('auth/signOut')
            this.$router.push({
                path: '/bye'
            }, null, function() {
                //
            })
        }
    }
}
</script>
