<template>
    <div class="box box-border text-align-center cursor-pointer" v-on:click="openProject">
        <div class="box-padding p-t-12 p-b-12">
            <BoxActions v-if="!webmaster">
                <li v-on:click.stop><router-link :to="project.link">Open project</router-link></li>
                <li v-on:click.stop><router-link :to="project.link + '/sections'">Edit sections</router-link></li>
                <li v-on:click.stop><router-link :to="project.link + '/settings'">Project Settings</router-link></li>
            </BoxActions>
            <BaseSymbol variant="large" :initials="project.initials" :color="project.color" class="m-b-7"></BaseSymbol>
            <h4 class="m-b-1">{{ project.name }}</h4>
            <p class="m-0">
                <span v-if="!webmaster">{{ project.type }}</span>
                <span v-else>{{ project.team ? project.team.name : 'TEAM NOG TE LADEN' }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import BoxActions from '@/components/BoxActions' 

export default {
    props: ['project', 'webmaster'],
    methods: {
        openProject: function () {
            this.$router.push({
                path: this.project.link
            })
        }
    },
    components: {
        BoxActions
    }
}
</script>
