import { Model } from '@vuex-orm/core'
import RelationEntry from './RelationEntry'
import Entry from './Entry'

export default class Relation extends Model {
    static get entity() {
        return 'relations'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            name: this.attr(null),
            description: this.attr(null),
            slug: this.attr(),
            type: this.attr('hasMany'),
            sort_direction: this.attr('recent_first'),
            position: this.attr(),
            icon: this.attr('help-circle'),

            // Relations
            module_id: this.attr(null),
            module_type: this.attr(null),
            module: this.morphTo('module_id', 'module_type'),

            related_module_id: this.attr(null),
            related_module_type: this.attr(null),
            related_module: this.morphTo('related_module_id', 'related_module_type'),

            entries: this.belongsToMany(Entry, RelationEntry, 'relation_id', 'entry_id'),
            target_entries: this.belongsToMany(Entry, RelationEntry, 'relation_id', 'target_entry_id')
        }
    }

    get creating() {
        return this.id.includes('$')
    }

    get identifier() {
        return this.slug
    }

}
