
import ApiService from '@/libraries/api'
import Role from '@/models/Role'

export default {
	state: {
        active: null
    },
    actions: {
        async query(state, parameters) {
            Role.commit((state) => {
                state.loading = true
            })

            return ApiService.get('/roles', { params: parameters }).then(response => {
                if (!response.error) {
                    Role.insertOrUpdate(response.data)
                }

                return response
            })
            .catch(error => error.response)
            .then(response => {
                Role.commit(state => state.loading = false)
                return this._vm.response(response)
            })

        },
    }
}