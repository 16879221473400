import ApiService from '@/libraries/api'
import Entry from '@/models/Entry'
import Event from '@/libraries/eventbus'

export default {
    state: {
        active: null
    },
    actions: {
        async query(state, parameters) {
            Entry.commit((state) => {
                state.loading = true
            })
            return ApiService.get('/entries', { params: parameters }).then(response => {
                if (!response.error) Entry.insertOrUpdate(response.data)
                return response
            })
            .catch(error => error.response)
            .then(response => {
                Entry.commit(state => state.loading = false)
                return this._vm.response(response)
            })
        },

        async single(state, parameters) {
            Entry.commit(state => {
                state.loading = true
            })

            return ApiService.get(`/entries/${parameters.id}`, { params: parameters }).then(response => {
                Entry.insertOrUpdate(response.data)

                return response.data.data
            })
        },

        async remove(store, params) {
            return ApiService.delete('/entries/' + params.id).then(response => {
                if (!response.error) Entry.delete(params.id)
            }).catch(error => error.response)
        },

        async updatePositions(store, payload) {

            const params = {
                entries: payload.entries.map(entry => entry.id)
            }

            const data = payload.entries.map((entry, index) => {
                return {
                    id: entry.id,
                    position: index + 1
                }
            })

            Entry.update({ data })

            return ApiService.post('/entries/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('entry.positions_updated')
                    // Entry.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        },
    }
}