<template>
    <div class="topbar-project" v-if="active_project">
        <div class="topbar-project-badge" v-click-outside="closeProjectMenu" v-on:click="toggleProjectMenu">
            <BaseSymbol :initials="active_project.initials" :color="active_project.color"></BaseSymbol>
            <div class="topbar-project-badge-name">{{ active_project.name }}</div>
        </div>
        <DropDownMenu :active="project_menu.open">
            <ul>
                <li v-for="project in projects" :key="project.id" :class="{ 'active': project.id === active_project.id }">
                    <router-link :to="project.link">
                        <div class="menu-icon"><BaseSymbol variant="menu" :color="project.color" :initials="project.initials"></BaseSymbol></div>
                        <div class="menu-label">{{ project.name }}</div>
                    </router-link>
                </li>
            </ul>
        </DropDownMenu>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import Project from '@/models/Project.js'
    import DropDownMenu from '@/components/DropDownMenu.vue'

    export default {
        computed: {
            projects: function () {
                return Project.query().orderBy('position').get()
            },
            active_project: function () {
                return Project.slug(this.$route.params.project)
            },
            ...mapState({
                project_menu: state => state.ui.project_menu
            }),
            ...mapGetters({
                user: 'auth/user'
            }),
        },
        methods: {
            closeProjectMenu: function () {
                this.$store.commit('ui/setProjectMenuOpen', false)
            },
            toggleProjectMenu: function () {
                this.$store.commit('ui/setProjectMenuOpen', !this.project_menu.open)
            }
        },
        components: {
            DropDownMenu
        }
    }
</script>