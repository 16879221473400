<template>
    <div>
        <FileUploader :field="field" :field_data="field_data" type="image" v-model="inputVal" :multiple="field.options.gallery"></FileUploader>
    </div>
</template>

<script>
import FileUploader from '@/components/FileUploader.vue'

export default {
    props: ['field', 'field_data', 'value'],
    computed: {
        inputVal: {
            get() {
                if(this.value === 'true') return true
                if(this.value === 'false') return false
                return this.value
            },
            set(val) {
                this.$emit('input', val.length ? val : [])
            }
        },
        is_gallery: function () {
            return this.field && this.field.options && this.field.options.gallery
        }
    },
    components: {
        FileUploader
    }
}
</script>