<template>
    <div class="page page-no-animation page-dark">
        <div class="page-contents">
            <div class="padding padding-small">
                <div class="log">
                    <div class="entry"><span class="d">[13:17:39] IP4:27.37.182.2 23ms</span> [GET] sections:homepage  { limit: 2, search: "Hello" }</div>
              
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['project'],
    created: function() {
        this.$store.commit('ui/setDarkMode', true)
    },
    beforeDestroy: function () {
        this.$store.commit('ui/setDarkMode', false)
    }
}
</script>
