<template>
    <form class="text-align-right" v-on:submit.prevent="save()">
        
        <select v-if="options" class="select-input" v-model="inputVal" :disabled="disabled">
            <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <input v-else class="text-input" type="text" v-model="inputVal" :disabled="disabled" ref="input">
        <div v-if="disabled">
            <button type="button" class="button button-tiny button-line m-t-2" v-on:click="toggleDisabled()">Edit</button>
        </div>
        <div v-else>
            <button type="button" class="button button-tiny button-text m-t-2" v-on:click="toggleDisabled()">Cancel</button>
            <button type="submit" class="button button-tiny m-t-2">Save</button>
        </div>
    </form>
</template>

<script>
export default {
    props: ['value', 'options'],
    data: function () {
        return {
            disabled: true
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        toggleDisabled: function () {
            this.disabled = ! this.disabled
            this.$nextTick(() => {
                if(!this.disabled && !this.options) this.$refs.input.focus()
            })
        },
        save: function() {
            this.$emit('save', this.inputVal)
            this.disabled = true
        }
    }
}
</script>