<template>
    <div class="modal" v-if="active">
        <div class="modal-background"></div>
        <div class="modal-holder">
            <div class="modal-box" :class="bind_classes">
                <div class="modal-box-instance" v-for="modal in modals" v-show="isLast(modal.id)" :key="modal.id">
                    <div class="modal-box-instance-level" v-bind:class="{ 'modal-box-instance-level-light': !modal.name }">
                        <div class="close" v-on:click="closeModal()">
                            <v-icon name="x"></v-icon>
                        </div>
                        <span v-if="modal.name">
                            <span v-for="modal in modals" :key="modal.id">
                                <a v-on:click="toModalInstance(modal.id)" v-if="!isLast(modal.id)">{{ modal.name }}</a>
                                <span v-else>{{ modal.name }}</span>
                            </span>
                        </span>
                    </div>
                    <RelationEditorModal v-if="isType(modal, 'relation.editor')" :modal="modal" :close="closeModal"></RelationEditorModal>
                    <AddMemberModal v-if="isType(modal, 'member.editor')" :modal="modal" :close="closeModal"></AddMemberModal>
                    <ChangeMemberRoleModal v-if="isType(modal, 'member.change_role')" :modal="modal" :close="closeModal"></ChangeMemberRoleModal>
                    <PaymentMethodModal v-if="isType(modal, 'team.paymentmethod')" :modal="modal" :close="closeModal"></PaymentMethodModal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/models/Modal.js'
import RelationEditorModal from '@/components/Modals/RelationEditorModal.vue'
import AddMemberModal from '@/components/Modals/AddMemberModal.vue'
import ChangeMemberRoleModal from '@/components/Modals/ChangeMemberRoleModal.vue'
import PaymentMethodModal from '@/components/Modals/PaymentMethodModal.vue'

export default {

	computed: {
        modals: function () {
            return Modal.all()
        },
        last_modal: function () {
            return Modal.query().orderBy('id').last()
        },
        active: function () {
            return Modal.query().count()
        },
        bind_classes: function () {
            if (!this.last_modal) return ''
            switch(this.last_modal.variant) {
                case 'confirm':
                    return 'modal-box-confirm'
                case 'small':
                    return 'modal-box-small'
            }
            return ''
        }
    },
    methods: {
        isLast: function (id) {
            return this.last_modal && id === this.last_modal.id
        },
        isType: function (modal, type) {
            return modal.type === type
        },
        closeModal: function (id) {
            if(id) {
                Modal.delete(id)
            } else {
                Modal.deleteAll()
            }
        },
        toModalInstance: function (id) {
            while(id !== Modal.query().orderBy('id').last().id) {
                this.closeModal(Modal.query().orderBy('id').last().id)
            }
        }
    },
    components: {
        RelationEditorModal,
        AddMemberModal,
        ChangeMemberRoleModal,
        PaymentMethodModal
    }
}
</script>
