import ApiService from '@/libraries/api'
import Role from '@/models/Role'
import Team from '@/models/Team'

export default {
	state: {
        active: null,
        team_capabilities: []
    },
    getters: {
        capabilities(state) {
            return state.team_capabilities
        }
    },
    actions: {
        async single({ commit }, parameters) {
            commit('setCapabilities', null)
            await ApiService.get(`/teams/${parameters.id}`, { params: parameters }).then(response => {
                Team.insertOrUpdate(response.data)
                commit('setCapabilities', response.data.data.capabilities)
            })
        },
        changeTeamRole(store, params) {
            return ApiService.post('/teams/' + params.team_id + '/members/' + params.member_id + '/roles/' + params.role_id)
            .then(response => {
                const role = Role.find(params.role_id)
                const team = Team.find(params.team_id)
                if(!response.error && team) {
                    const members = team ? team.members.map(member => {
                            if(member.id === params.member_id) {
                                member.role_id = params.role_id
                                member.role_name = role ? role.name : ''
                            }
                            return member
                        }) : []

                    Team.update({
                        where: team.id,
                        data: { members: members }
                    })
                }
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        removeMember(store, params) {
            return ApiService.delete('/teams/' + params.id + '/members', params)
            .then(response => {
                const team = Team.find(params.id)
                const members = team ? team.members : []

                Team.update({
                    where: team.id,
                    data: { members: members.filter(member => member.id != params.member_id) }
                })

                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        }
    },
    mutations: {
        setCapabilities: function (state, value) {
            state.team_capabilities = value
        }
    }
}