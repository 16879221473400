import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMSlug from '@/store/plugins/slug'

// Models
import User from '@/models/User'
import Role from '@/models/Role'
import Team from '@/models/Team'
import Project from '@/models/Project'
import Section from '@/models/Section'
import List from '@/models/List'
import Entry from '@/models/Entry'
import Field from '@/models/Field'
import FieldData from '@/models/FieldData'
import Relation from '@/models/Relation'
import RelationEntry from '@/models/RelationEntry'
import Notification from '@/models/Notification'
import Invitation from '@/models/Invitation'
import Modal from '@/models/Modal'
import File from '@/models/File'

// Modules
import ui from '@/store/modules/ui'
import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import roles from '@/store/modules/roles'
import teams from '@/store/modules/teams'
import projects from '@/store/modules/projects'
import sections from '@/store/modules/sections'
import lists from '@/store/modules/lists'
import fields from '@/store/modules/fields'
import entries from '@/store/modules/entries'
import relations from '@/store/modules/relations'
import notifications from '@/store/modules/notifications'
import invitations from '@/store/modules/invitations'
import modals from '@/store/modules/modals'
import files from '@/store/modules/files'

Vue.use(Vuex)
VuexORM.use(VuexORMSlug)
const database = new VuexORM.Database()

// Register models
database.register(User, users)
database.register(Role, roles)
database.register(Team, teams)
database.register(Project, projects)
database.register(Section, sections)
database.register(List, lists)
database.register(Entry, entries)
database.register(Field, fields)
database.register(FieldData)
database.register(Relation, relations)
database.register(Notification, notifications)
database.register(Modal, modals)
database.register(File, files)
database.register(RelationEntry)
database.register(Invitation, invitations)

export default new Vuex.Store({
	plugins: [
        VuexORM.install(database)
    ],
    modules: {
        ui,
        auth
    }
})
