<template>
    <div class="field-tile" v-bind:class="{ 'field-tile-creating': field.creating, 'field-tile-editing': editing }">
        <div class="tile-actions field-tile-actions">
            <ul v-if="!field.creating">
                <li v-tooltip="'Edit'" v-on:click="openEditor"><v-icon name="edit"></v-icon></li>
                <li v-tooltip="'Show list'" v-if="field.type=='list'"><v-icon name="layers"></v-icon></li>
                <li v-tooltip="'Add field'" v-if="field.type=='group'" v-on:click="addField"><v-icon name="plus-square"></v-icon></li>
                <li v-tooltip="'Remove'" v-on:click="removeField"><v-icon name="trash"></v-icon></li>
                <li v-tooltip="'Reorder'" class="draggable-handle"><v-icon name="menu"></v-icon></li>
            </ul>
        </div>
        <div class="field-tile-title">
            <v-icon :name="field.icon" v-tooltip="field.type"></v-icon>
            <h5 class="m-0 font-weight-lighter" v-bind:class="{ 'lightest': field.creating }">{{ field.creating ? field.creating_label : field.name }} <small>{{ field.identifier }}</small></h5>
            <div v-if="field.type=='list'" class="field-tile-title-relation">
                <div class="field-tile-title-relation-type">{{ field.options.relation_type }}</div>
                <div class="field-tile-title-relation-slug">{{ field.options.relation_slug }}</div>
            </div>
        </div>
        <div class="field-tile-folder" v-if="is_group">
            <Draggable class="draggable" v-model="fields" v-if="draggable_visible" v-bind:class="{ 'draggable-accepting': dragging }" handle=".draggable-handle" ghost-class="ghost" group="fields" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                <FieldTile v-for="field in fields" :key="field.id" :field="field"></FieldTile>
            </Draggable>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Event from '@/libraries/eventbus'
import Field from '@/models/Field.js'
import Draggable from 'vuedraggable'

export default {
    name: 'FieldTile',
    props: ['field'],
    computed: {
        ...mapState({
            dragging: state => state.ui.dragging,
            content_bar: state => state.ui.content_bar
        }),
        fields: {
            get() {
                if(this.field.type !== 'group') return null
                return Field.query().where('group_id', this.field.id).withAllRecursive().orderBy('position').get()
            },
            set(fields) {
                Field.dispatch('updatePositions', { fields: fields, group_id: this.field.id })
            }
        },
        is_group: function () {
            return this.field.type === 'group'
        },
        draggable_visible: function () {
            if (this.dragging) return true
            if (!this.fields.length) return false
            return true
        },
        editing: function () {
            return this.content_bar.type === 'field.edit' && this.content_bar.data === this.field.id
        }
    },
    methods: {
        setDragging: function (state) {
            this.$store.commit('ui/setDragging', state)
        },
        openEditor: function () {
            this.$store.commit('ui/setContentBar', { type: 'field.edit', data: this.field.id })
        },
        addField: function () {
            this.$store.commit('ui/setContentBar', { type: 'field.add', data: { module: this.field.module, group: this.field.id } })
        },
        removeField: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this field?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        Field.dispatch('remove', this.field).then(() => {
                            Event.dispatch('field.deleted', this.field)
                        })
                    }
                }
            })
        }
    },
    components: {
        Draggable
    }
}
</script>
