import _ from 'lodash'
import Event from '@/libraries/eventbus.js'
import ApiService from '@/libraries/api'
import Relation from '@/models/Relation'
import RelationEntry from '@/models/RelationEntry'
import Entry from '@/models/Entry'

export default {
    state: {
        active: null
    },
    actions: {
        async query(state, parameters) {
            Relation.commit((state) => {
                state.loading = true
            })
            Relation.deleteAll()
            return ApiService.get('/relations', { params: parameters }).then(response => {
                if (!response.error) {
                    Relation.insertOrUpdate(response.data)
                    return response
                }
                return response
            })
                .catch(error => error.response)
                .then(response => {
                    Relation.commit((state) => {
                        state.loading = false
                    })
                    return this._vm.response(response)
                })
        },

        async post(store, params) {
            return ApiService.post('/relations', params).then(response => {
                if (!response.error) Relation.insert(response.data)
                return response
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async patch(store, params) {
            return ApiService.patch('/relations/' + params.id, params).then(response => {
                if (!response.error) Relation.insertOrUpdate(response.data)
                return response
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async remove(store, params) {
            return ApiService.delete('/relations/' + params.id).then(response => {
                if (!response.error) Relation.delete(params.id)
            }).catch(error => error.response)
        },

        async updatePositions(store, payload) {
            const params = {
                relations: payload.relations.map(relation => {
                    return relation.id
                })
            }

            _.each(payload.relations, function (relation, index) {
                Relation.update({
                    where: relation.id,
                    data: {
                        position: index + 1
                    }
                })
            })

            return ApiService.post('/relations/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('relation.positions_updated')
                    Relation.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        },

        async saveEntry(store, params) {
            return ApiService.post('/relations/' + params.id + '/entries', params).then(response => {
                if (!response.error) Relation.insertOrUpdate(response.data)
                return response
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async unlinkEntry(store, params) {
            return ApiService.delete('/relations/' + params.id + '/entries', params).then(response => {
                if (!response.error) {
                    RelationEntry.delete([params.id, params.entry_id])
                }
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        async updateEntryPositions(store, payload) {
            const params = {
                target_entry_id: payload.target_entry_id ? payload.target_entry_id : null,
                entries: payload.entries.map(entry => entry.id)
            }

            const data = payload.entries.map((entry, index) => {
                return {
                    id: entry.id,
                    relation_position: index + 1
                }
            })

            Entry.update({ data })

            return ApiService.post('/relations/'+payload.relation.id+'/entries/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('entry.positions_updated')
                    // Entry.insertOrUpdate(response.data)
                    return response.data
                }
            }).catch(error => error.response)
        }
    }
}