import ApiService from '@/libraries/api'
import Project from '@/models/Project'
import Role from '@/models/Role'
import Event from '@/libraries/eventbus'
import Section from '../../models/Section'
import List from '../../models/List'

export default {
    state: {
        project_capabilities: []
    },
    getters: {
        slug(state, slug) {
            console.log(slug)
        },
        capabilities(state) {
            return state.project_capabilities
        }
    },
    actions: {
        async query(state, parameters) {
            Project.commit((state) => {
                state.loading = true
            })

            await ApiService.get('/projects', { params: parameters }).then(response => Project.insertOrUpdate(response.data)).catch().then(response => {
                Project.commit((state) => {
                    state.loading = false
                })
                return response
            })

        },

        async post(store, params) {
            return ApiService.post('/projects', params).then(response => {
                if (!response.error) Project.insert(response.data)
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        async patch(store, params) {
            return ApiService.patch('/projects/' + params.id, params).then(response => {
                if (!response.error) {
                    Project.insertOrUpdate(response.data)
                }
                return response
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async single({ commit }, parameters) {
            Section.deleteAll()
            List.deleteAll()
            commit('setCapabilities', null)
            await ApiService.get(`/projects/${parameters.id}`, { params: parameters }).then(response => {
                Project.insertOrUpdate(response.data)
                commit('setCapabilities', response.data.data.capabilities)
            })
        },

        async remove(store, params) {
            return ApiService.delete('/projects/' + params.id).then(response => {
                if (!response.error) Project.delete(params.id)
                return response
            })
                .catch(error => error.response)
                .then(response => this._vm.response(response))
        },

        async updatePositions(store, payload) {

            const params = {
                projects: payload.projects.map(project => project.id)
            }

            const data = payload.projects.map((project, index) => {
                return {
                    id: project.id,
                    position: index + 1
                }
            })

            Project.update({ data })

            return ApiService.post('/projects/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('project.positions_updated')
                    return response.data
                }
            }).catch(error => error.response)
        },

        changeProjectRole(store, params) {
            return ApiService.post('/projects/' + params.project_id + '/users/' + params.user_id + '/roles/' + params.role_id)
            .then(response => {
                const role = Role.find(params.role_id)
                const project = Project.find(params.project_id)
                if(!response.error && project) {
                    const users = project ? project.users.map(user => {
                            if(user.id === params.user_id) {
                                user.role_id = params.role_id
                                user.role_name = role ? role.name : ''
                            }
                            return user
                        }) : []

                    Project.update({
                        where: project.id,
                        data: { users: users }
                    })
                }
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        removeUser(store, params) {
            return ApiService.delete('/projects/' + params.id + '/users', params)
            .then(response => {
                const project = Project.find(params.id)
                const users = project ? project.users : []

                Project.update({
                    where: project.id,
                    data: { users: users.filter(user => user.id != params.user_id) }
                })

                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        }
    },
    mutations: {
        setCapabilities: function (state, value) {
            state.project_capabilities = value
        }
    }
}