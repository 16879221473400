<template>
    <div>
        <h2 class="m-b-3 text-align-center">Reset password</h2>

        <div class="message message-clickable message-success m-0" v-if="success" v-on:click="success=null" v-text="success"></div>
        <ul class="message message-clickable message-danger" v-if="errors" v-on:click="error=null">
            <li v-for="(error, index) in errors" :key="index" v-text="error[0]"></li>
        </ul>

        <form class="form" autocomplete="off" v-on:submit.prevent="resetPassword" method="post" v-if="!success">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" class="text-input" v-model="form.email" required v-autofocus>
            </div>

            <div class="form-group">
                <label for="password">Password</label>
                <input class="text-input" type="password" id="password" name="password" v-model="form.password">
            </div>

            <div class="form-group">
                <label for="password_confirmation">Password confirmation</label>
                <input class="text-input" type="password" id="password_confirmation" name="password_confirmation" v-model="form.password_confirmation">
            </div>

            <button type="submit" class="button button-block m-0">Reset password</button>
        </form>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                form: {
                    token: null,
                    email: null,
                    password: null,
                    password_confirmation: null
                },
                errors: null,
                success: null
            }
        },
        created: function () {
            this.form.token = typeof this.$route.query.token !== 'undefined' ? this.$route.query.token : null
        },
        methods: {
            resetPassword: function() {
                this.success = this.errors = null
                this.$store.dispatch('auth/passwordReset', this.form)
                .then(result => {
                    if(typeof result.data !== 'undefined' && result.data.errors !== 'undefined') {
                        this.errors = result.data.errors
                    } else {
                        this.success = result.status

                        this.$store.dispatch('auth/signIn', this.form).then(() => {
                            this.$router.replace({ path: '/' })
                        })

                    }
                }).catch(() => {
                    this.errors = ['Something went wrong. Please try again later.']
                })
            }
        }
    }
</script>