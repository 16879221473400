<template>
    <div>
        <div class="message message-clickable message-danger" v-if="error" v-on:click="error=false">Credentials mismatch</div>
        <form class="form" action="submit" v-on:submit.prevent="submit">
            <div class="form-group">
                <label>Email</label>
                <input class="text-input" type="text" name="email" placeholder="" v-model="form.email" v-autofocus>
            </div>
            <div class="form-group">
                <label>Password</label>
                <input class="text-input" type="password" name="password" placeholder="" v-model="form.password">
            </div>
            <input class="button" type="submit" value="Sign in">

            <router-link :to="'/passwordforgot'" class="m-l-5 text-decoration-none">Forgot password</router-link>
        </form>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                error: false,
                form: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            async submit () {
                var result = await this.$store.dispatch('auth/signIn', this.form)
                if (result) {
                    this.$router.replace({ path: '/' })
                } else {
                    this.error = true
                }
            }
        },
        components: {
            //
        }
    }
</script>