<template>
    <div class="pagination" v-bind:class="{ 'pagination-compact': compact }">
        <div class="pagination-pages">
            <ul>
                <li v-if="show_first">
                    <a v-on:click="setPage(1)">
                        First
                    </a>
                </li>
                <li v-for="page in page_links" v-bind:key="page">
                    <a v-on:click="setPage(page)" v-bind:class="{ 'active': pagination.current_page === page }">
                        {{ page }}
                    </a>
                </li>
                <li v-if="show_last">
                    <a v-on:click="setPage(total_pages)">
                        Last
                    </a>
                </li>
            </ul>
        </div>
        <div class="pagination-current-page">
            Page <b>{{ pagination.current_page }}</b> of <b>{{ total_pages }}</b>
        </div>
    </div>
</template>

<script>
export default {
    props: ['pagination', 'setPage', 'compact'],
    computed: {
        digits_before_and_after: function () {
            return 3
        },
        total_pages: function () {
            return Math.ceil(this.pagination.total_results / this.pagination.results_page)
        },
        page_links: function () {
            var start = Math.max(1, this.pagination.current_page - this.digits_before_and_after)
            var end = Math.min(this.total_pages, this.pagination.current_page + this.digits_before_and_after)
            if (this.compact) {
                start = 1
                end = this.total_pages
            }
            var output = []
        
            for(var i=start;i<=end;i++) output.push(i)
            return output
        },
        show_first: function () {
            if (this.compact) return false
            return this.pagination.current_page != 1
        },
        show_last: function () {
            if (this.compact) return false
            return this.pagination.current_page != this.total_pages
        }
    }
}
</script>