<template>
    <div class="markdown-editor">
        <div class="editor">
            <textarea v-model="inputVal"></textarea>
        </div>
        <div class="markdown" v-html="markdown"></div>
    </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'

export default {
    props: ['field', 'value', 'rows'],
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        markdown: function() {
            return sanitizeHtml(marked(this.value ? this.value : ''))
        }
    }
}
</script>