export default function (Vue) {
    Vue.prototype.response = (response) => {
        const default_response = {
            data: {
                success: false,
                errors: {
                    500: ['Unknown error']
                }
            }
        }

        if (typeof response === 'undefined') {
            return default_response
        }

        switch (response.status) {
            case 200:
            case 201:
            case 204:
                return { ...{ success: true }, ...response.data }
            default:
                if (response.data.errors) return response
                return default_response
        }
    }
}