<template>
    <div class="topbar" v-bind:class="{ 'sidebar-open': sidebar_open, 'notification-open': notification_open, 'topbar-darkmode': dark_mode }">
        <router-link :to="'/'" class="topbar-logo"></router-link>
        <TheTopbarProjectMenu></TheTopbarProjectMenu>
        <TheTopbarUserMenu></TheTopbarUserMenu>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Project from '@/models/Project.js'
    import TheTopbarProjectMenu from '@/components/TheTopbarProjectMenu.vue'
    import TheTopbarUserMenu from '@/components/TheTopbarUserMenu.vue'

    export default {
        components: {
            TheTopbarProjectMenu,
            TheTopbarUserMenu
        },
        computed: {
            ...mapState({
                notification_open: state => state.ui.notification,
                dark_mode: state => state.ui.dark_mode
            }),
            project: function () {
                return Project.slug(this.$route.params.project)
            },
            sidebar_open: function () {
                return this.project ? true : false
            }
        }
    }
</script>