<template>
    <div class="notifications">
        <NotificationTile v-for="notification in notifications" v-bind:key="notification.id" :notification="notification"></NotificationTile>
    </div>
</template>

<script>
    import Notification from '@/models/Notification.js'
    import NotificationTile from '@/components/Notifications/NotificationTile.vue'

    export default {
        computed: {
            notifications: function () {
                return Notification.all()
            }
        },
        components: {
            NotificationTile
        }
    }
</script>