<template>
    <div class="locked">
        <div class="locked-box">
            <div class="locked-box-logo"></div>
            <div class="locked-box-contents">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            //
        }
    }
</script>