<template>
    <div class="fade-in">
        <div class="padding">
            <div class="fade-in" v-if="fields.length">
                <div class="actions">
                    <a class="button" v-on:click="addField">Add field</a>
                </div>
                <h3>Fields <small>{{ fields.length }}</small></h3>
                <div class="field-tiles">
                    <Draggable v-model="fields" handle=".draggable-handle" ghost-class="ghost" group="fields" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                        <FieldTile v-for="field in fields" :key="field.id" :field="field"></FieldTile>
                    </Draggable>
                </div>
            </div>
            <div v-else>
                <BaseNoResults>
                    <v-icon name="zap"></v-icon>
                    <div v-if="can('create-field')">
                        <h4 class="m-b-3">Add your first field</h4>
                        <p>Create you first field in this section.</p>
                        <a class="button" v-on:click="addField">Add field</a>
                    </div>
                    <div v-else>
                        <h4 class="m-b-3">No fields created</h4>
                        <p>Please contact an administrator.</p>
                    </div>
                </BaseNoResults>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Field from '@/models/Field.js'
import FieldTile from '@/components/FieldTile.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['module'],
    data: function () {
        return {
            field_positions: null
        }
    },
    created: function () {
        if(!this.can_manage_fields) {
            const link = this.is_list ? 'entries' : 'entry'
            this.$router.push(this.module.link + '/' + link)
        }
        this.field_positions = _.map(this.fields, function (field) {
            return field.id
        })
        if(this.$route.query.add) this.addField()
    },
    computed: {
        fields: {
            get() {
                return Field.query()
                    .where('module_id', this.module.id)
                    .where('module_type', this.module.module_type)
                    .where('group_id', null)
                    .withAll()
                    // .withAllRecursive()
                    .orderBy('position')
                    .get()
            },
            set(fields) {
                Field.dispatch('updatePositions', { fields: fields, group_id: null })
            }
        },
        is_list: function () {
            return this.module && this.module.module_type === 'lists'
        },
        can_manage_fields: function () {
            return this.can('create-field') || this.can('update-field')
        }
    },
    methods: {
        addField: function () {
            this.$store.commit('ui/setContentBar', { type: 'field.add', data: { module: this.module } })
        },
        setDragging: function (state) {
            this.$store.commit('ui/setDragging', state)
        }
    },
    components: {
        FieldTile,
        Draggable
    }
}
</script>
