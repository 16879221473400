<template>
    <div class="fade-in" v-if="module_editor">
        <div class="padding">
            <div class="box box-no-shadow box-border" v-if="can_update_module">
                <div class="box-padding box-padding-icon">
                    <div class="box-icon-inside"><v-icon name="settings"></v-icon></div>
                    <h3 class="m-b-3">Settings</h3>
                    <p>Here you can update the settings of this {{ type }}.</p>
                    <form class="form" action="submit" v-on:submit.prevent="saveModule">
                        <div class="form-group">
                            <label>Name<small>Required</small></label>
                            <input class="text-input" type="text" v-model="module_editor.name">
                            <div class="slug-input fade-in" v-if="module_editor.slug && !slug_editor">Slug: <span class="slug-input-slug" v-on:click="toggleSlugEditor">{{ module_editor.slug ? module_editor.slug : '' }}</span></div>
                        </div>
                        <div class="form-group fade-in" v-if="slug_editor">
                            <label>Slug<small>Unique</small></label>
                            <input class="text-input" type="text" v-model="module_editor.slug" autofocus>
                            <p class="small m-t-1">Unique field for building queries</p>
                        </div>
                        <div class="form-group">
                            <label>Icon</label>
                            <div class="iconbox fade-in" v-on:click="toggleSelectIcon" v-if="!select_icon"><v-icon :name="module_editor.icon" width="40"></v-icon></div>
                            <IconSelector :selected="module_editor.icon" :select="updateIcon" class="fade-in" v-else></IconSelector>
                        </div>
                        <div class="grid">
                            <div class="form-group" v-if="module.module_type === 'lists'">
                                <label>Sort values by</label>
                                <select class="select-input" v-model="module_editor.sort_direction">
                                    <option value="recent_first">Most recent first</option>
                                    <option value="recent_last">Most recent last</option>
                                    <option value="manually">Sort manually</option>
                                </select>
                            </div>
                        </div>
                        <input class="button" type="submit" :value="'Save ' + type">
                    </form>
                </div>
            </div>

            <div class="box box-no-shadow box-border" v-if="is_list && has_fields">
                <div class="box-padding box-padding-icon">
                    <div class="box-icon-inside"><v-icon name="filter"></v-icon></div>
                    <div class="form">
                        <h3 class="m-b-3">Custom entries view</h3>
                        <p>Change the table view for this {{ type }}</p>
                        <form class="form" action="submit" v-on:submit.prevent="saveEntryViewPositions">
                            <div class="form-group m-0">
                                <Toggle v-model="custom_entries_view.enabled">Enable custom entries view</Toggle>
                                <div v-if="custom_entries_view.enabled" class="fade-in m-t-5">
                                    <Draggable class="available-languages draggable-placeholder" :list="custom_entry_fields" group="fields">
                                        <div class="available-languages-language m-b-0" v-for="custom_entry_field in custom_entry_fields" :key="custom_entry_field.id">
                                            <label class="m-b-0">
                                                <div class="language-box language-box-active">
                                                    {{ custom_entry_field.name }}
                                                </div>
                                            </label>
                                        </div>
                                    </Draggable>

                                    <div class="available-languages" :class="{ 'm-t-6': available_fields.length}">
                                        <Draggable v-if="available_fields.length" :list="available_fields" group="fields">
                                            <div class="available-languages-language m-b-0" v-for="field in available_fields" :key="field.id">
                                                <label class="m-b-0">
                                                    <div class="language-box language-box-active">
                                                        {{ field.name }}
                                                    </div>
                                                </label>
                                            </div>
                                        </Draggable>
                                    </div>
                                </div>
                                <input class="button m-t-5 fade-in" v-if="show_save_custom_entries_view" type="submit" :value="'Save settings'">
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="box box-no-shadow box-border" v-if="can_delete_module">
                <div class="box-padding box-padding-icon">
                    <div class="box-icon-inside"><v-icon name="cloud-lightning" class="icon-danger"></v-icon></div>
                    <h3 class="m-b-5">Danger zone</h3>
                    <div class="box box-no-shadow box-border">
                        <div class="box-padding box-padding-small">
                            <a class="button button-danger button-small m-t-1 float-right" v-on:click="deleteModule">Delete {{ type }}</a>
                            <h5 class="m-b-1">Delete this {{ type }}</h5>
                            <p class="m-0">Once you delete a {{ type }}, there is no going back. Please be certain.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Event from '@/libraries/eventbus.js'
import Section from '@/models/Section.js'
import List from '@/models/List.js'
import Field from '@/models/Field.js'
import IconSelector from '@/components/IconSelector.vue'
import Toggle from '@/components/FormElements/Toggle.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['module'],
    data: function () {
        return {
            module_editor: null,
            slug_editor: false,
            custom_slug: false,
            select_icon: false,
            custom_entries_view: {
                enabled: false
            },
            custom_entry_fields: [],
            available_fields: []
        }
    },
    created: function () {
        if(!this.can_update_module && !this.can_delete_module) {
            this.$router.push(this.module.link)
        }

        this.module_editor = this.clone(this.module)

        this.setCustomEntryFields(this.module.table_fields)
    },
    computed: {
        is_section: function () {
            return this.module && this.module.module_type === 'sections'
        },
        is_list: function () {
            return this.module && this.module.module_type === 'lists'
        },
        type: function () {
            return this.is_section ? 'section' : 'list'
        },
        has_fields: function () {
            return this.module.fields && this.module.fields.length
        },
        can_update_module: function() {
            return this.can(`update-${this.type}`)
        },
        can_delete_module: function() {
            return this.can(`delete-${this.type}`)
        },
        show_save_custom_entries_view: function () {
            if(!this.is_list) {
                return false
            }

            if(this.module.table_fields.length && !this.custom_entries_view.enabled) {
                return true
            }

            if(!this.module.table_fields.length && this.custom_entries_view.enabled) {
                return true
            }

            return JSON.stringify(this.module.table_fields) !== JSON.stringify(this.custom_entry_fields.map(field => field.id))
        },
        show_draggable_placeholder_text: function () {
            return !this.custom_entry_fields.length
        }
    },
    methods: {
        saveModule: function () {
            if(this.is_section) this.saveSection()
            if(this.is_list) this.saveList()
        },
        saveSection: function () {
            this.select_icon = false
            Section.dispatch('patch', this.module_editor).then(response => {
                if(!response.data.errors) {
                    Event.dispatch('list.saved', this.module)
                    if(this.$route.params.section !== response.data.slug) {
                        this.$router.push('/projects/' + this.module.project.slug + '/sections/' + response.data.slug + '/settings')
                    }
                } else {
                    Event.dispatch('list.errors', response.data.errors)
                }
            })
        },
        saveList: function () {
            this.select_icon = false
            List.dispatch('patch', this.module_editor).then(response => {
                if(!response.data.errors) {
                    Event.dispatch('list.saved', this.module)
                    if(this.$route.params.list !== response.data.slug) {
                        this.$router.push('/projects/' + this.module.project.slug + '/lists/' + response.data.slug + '/settings')
                    }
                } else {
                    Event.dispatch('list.errors', response.data.errors)
                }
            })
        },
        saveEntryViewPositions: function () {
            if(!this.is_list) {
                return
            }

            const params = {
                list_id: this.module.id,
                fields: this.custom_entries_view.enabled ? this.custom_entry_fields : []
            }

            Field.dispatch('updateEntryViewPositions', params).then(response => {
                if(!response.data.errors) {
                    this.module.options.table_fields = params.fields.map(field => field.id)
                    this.module.$save()
                    this.setCustomEntryFields(this.module.table_fields)
                    Event.dispatch('list.saved', this.module)
                } else {
                    Event.dispatch('list.errors', response.data.errors)
                }
            })
        },
        deleteModule: function () {
            if(this.is_section) this.deleteSection()
            if(this.is_list) this.deleteList()
        },
        deleteSection: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this section?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: (confirm) => {
                    if (confirm) {
                        Section.dispatch('remove', this.module).then(response => {
                            if(response.success) {
                                Event.dispatch('section.deleted')
                                this.$router.push(this.module.project.link + '/sections')
                            } else {
                                Event.dispatch('section.errors', response.data.errors)
                            }
                        })
                    }
                }
            })
        },
        deleteList: function () {
            this.$confirm({
                message: 'Are you sure you want to remove this list?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: (confirm) => {
                    if (confirm) {
                        List.dispatch('remove', this.module).then(response => {
                            if(response.success) {
                                Event.dispatch('list.deleted')
                                this.$router.push(this.module.project.link + '/lists')
                            } else {
                                Event.dispatch('list.errors', response.data.errors)
                            }
                        })
                    }
                }
            })
        },
        toggleSlugEditor: function () {
            this.slug_editor = true
        },
        toggleSelectIcon: function () {
            this.select_icon = !this.select_icon
        },
        updateIcon: function (icon) {
            this.module_editor.icon = icon
            this.select_icon = false
        },
        setCustomEntryFields: function (fields) {
            this.custom_entry_fields = Field.query()
                .with('list')
                .where('module_id', this.module.id)
                .where('module_type', 'lists')
                .whereIdIn(fields)
                .get()

            this.available_fields = Field.query()
                .with('list')
                .where('module_id', this.module.id)
                .where('module_type', 'lists')
                .where(field => !fields.includes(field.id))
                .get()

            this.custom_entries_view.enabled = fields.length
        }
    },
    components: {
        IconSelector,
        Toggle,
        Draggable
    }
}
</script>
