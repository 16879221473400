<template>
    <div>
        <Toggle v-model="inputVal"><span class="small">{{ this.field.options.checkbox_label }}</span></Toggle>
    </div>
</template>

<script>
import Toggle from '@/components/FormElements/Toggle.vue'

export default {
    props: ['field', 'value'],
    computed: {
        inputVal: {
            get() {
                if(this.value === 'true') return true
                if(this.value === 'false') return false
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    components: {
        Toggle
    }
}
</script>