import { Model } from '@vuex-orm/core'
import Project from './Project'
import Field from './Field'
import Entry from './Entry'
import Relation from './Relation'

export default class List extends Model {
    static get entity() {
        return 'lists'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            project_id: this.attr(),
            name: this.attr(),
            slug: this.attr(),
            position: this.number(),
            menu: this.boolean(),
            icon: this.attr('layers'),
            sort_direction: this.attr('recent_first'),
            options: this.attr({}),

            // Relations
            project: this.belongsTo(Project, 'project_id'),
            fields: this.morphMany(Field, 'module_id', 'module_type'),
            entries: this.hasMany(Entry, 'list_id'),
            relations: this.morphMany(Relation, 'module_id', 'module_type')
        }
    }

    get table_fields() {
        return this.options.table_fields ? this.options.table_fields : []
    }

    get module_type() {
        return 'lists'
    }

    get link() {
        if (!this.project) return null
        return this.project.link + '/lists/' + this.slug
    }

    get identifier() {
        return this.slug
    }
}
