import { Model } from '@vuex-orm/core'

export default class Notification extends Model {
    static get entity () {
        return 'notifications'
    }

    static state () {
        return {
            //
        }
    }

    static fields () {
        return {
            id: this.attr(),
            type: this.attr(),
            data: this.attr({})
        }
    }
}
