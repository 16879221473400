<template>
    <div style="display:contents;">
        <div class="table-td">
            <span v-if="full_name">{{ full_name }} - </span>
            {{ member.email }}
        </div>
        <div class="table-td" v-text="member.role_name"></div>
        <div class="table-td">
            <div class="table-label p-l-0 p-r-0" style="width: 65px;" :class="label_class">
                {{ member.status }}
            </div>
        </div>
        <div class="table-td text-align-right">
            <div v-if="has_actions">
                <a class="button button-line button-tiny button-icon" v-on:click="toggleDropDown"> <v-icon name="chevron-down"></v-icon>Actions</a>
                <DropDownMenu class="right dropdown-menu-td" :active="member.actions_active" v-click-outside="toggleDropDown">
                    <ul>
                        <li>
                            <a v-if="can_invite" v-on:click="openConfirmResendInvitationModal">
                                <div class="menu-icon"><v-icon name="send"></v-icon></div>
                                <div class="menu-label">Resend invitation</div>
                            </a>

                            <a v-if="can_update_role" v-on:click="openChangeRoleModal">
                                <div class="menu-icon"><v-icon name="shield"></v-icon></div>
                                <div class="menu-label">Change role</div>
                            </a>

                            <a v-if="can_delete" v-on:click="deleteMember">
                                <div class="menu-icon"><v-icon name="trash"></v-icon></div>
                                <div class="menu-label">Delete member</div>
                            </a>
                        </li>
                    </ul>
                </DropDownMenu>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Event from '@/libraries/eventbus.js'

import DropDownMenu from '@/components/DropDownMenu.vue'
import Modal from '@/models/Modal.js'
import Team from '@/models/Team.js'
import Invitation from '@/models/Invitation.js'

export default {
    props: ['team', 'member'],
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        full_name: function () {
            if(!this.member.firstname && !this.member.lastname) return null
            const name = (this.member.firstname + ' ' + this.member.lastname).trim()
            return name ? name : ''
        },
        has_actions: function () {
            if(this.user.id === this.member.id) {
                return false
            }

            return this.can_invite || this.can('remove-from-team', true)
        },
        can_update_role: function () {
            return this.can('remove-from-team', true) && this.team.available_roles.length > 1
        },
        can_invite: function () {
            return this.can('invite-to-team', true) && this.member.status != 'accepted'
        },
        can_delete: function () {
            return this.can('remove-from-team', true)
        },
        label_class: function () {
            switch(this.member.status) {
                case 'accepted': return 'table-label-success'
                case 'declined': return 'table-label-danger'
            }

            return ''
        }
    },
    methods: {
        toggleDropDown: function () {
            this.$emit('toggle-dropdown', this.member.id)
        },
        openConfirmResendInvitationModal: function() {

            const params = {
                'team_id': this.team.id,
                'user_id': this.member.id
            }

            this.$emit('toggle-dropdown', false)

            this.$confirm({
                message: 'Do you want to send another invitation to this member?',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: confirm => {
                    if (confirm) {
                        Invitation.dispatch('reinviteToTeam', params).then(response => {
                            if (response.success) {
                                Event.dispatch('team_member.reinvited', params)
                            } else {
                                Event.dispatch('team_member.errors', response.data.errors)
                            }
                        })
                    }
                }
            })
        },
        openChangeRoleModal: function() {
            Modal.dispatch('launch', {
                type: 'member.change_role',
                name: 'Change member role',
                variant: 'small',
                data: {
                    type: 'team',
                    team_id: this.team.id,
                    member: this.member,
                    available_roles: this.team.available_roles
                }
            })
        },
        deleteMember: function () {
            const params = {
                'id': this.team.id,
                'member_id': this.member.id
            }

            this.$emit('toggle-dropdown', false)

            this.$confirm({
                message: 'Are you sure you want to delete this member?',
                button: {
                    no: 'Keep',
                    yes: 'Remove'
                },
                callback: confirm => {
                    if (confirm) {
                        Team.dispatch('removeMember', params).then(response => {
                            if (response.success) {
                                Event.dispatch('team_member.deleted', params)
                            } else {
                                Event.dispatch('team_member.errors', response.data.errors)
                            }
                        })
                    }
                }
            })
        }
    },
    components: {
        DropDownMenu
    }

}
</script>