<template>
    <div class="fileuploader" v-bind:class="{ 'fileuploader-big': !multiple, 'fileuploader-empty': !files.length }" v-on:dragover.prevent="dragOver" v-on:dragleave.prevent="dragLeave" v-on:drop.prevent="addFiles('drop', $event)">
        <div class="fileuploader-click-to-upload" v-on:click="selectFile()" v-if="!files.length"></div>
        <input class="fileuploader-button" type="file" ref="select_file_button" :multiple="multiple" v-on:change="addFiles('button', $event)" :accept="accept">
        <div class="fileuploader-label fade-in" v-if="!files.length">
            <v-icon name="download"></v-icon>
            <div class="label">{{ label }}</div>
        </div>
        <div class="fileuploader-files" v-else>
            <Draggable v-model="files" handle=".draggable-handle" ghost-class="ghost" group="files" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                <FileTile
                    v-for="(file) in files"
                    :key="file.id"
                    :type="type"
                    :multiple="multiple"
                    :file="file"
                    :deleteFile="deleteFile"
                    :selectFile="selectFile"
                    :gallery="getOtherImages(file.id)"
                >
                </FileTile>
            </Draggable>
            <div class="fileuploader-files-file-add" v-if="multiple" v-on:click="selectFile()" v-tooltip="'Click to add'">
                <v-icon name="plus"></v-icon>
            </div>
        </div>
        <div class="fileuploader-footer" v-if="files.length && multiple">{{ files.length }} {{ files.length === 1 ? 'file' : 'files' }}</div>
    </div>
</template>

<script>
import Event from '@/libraries/eventbus.js'
import Field from '@/models/Field.js'
import FieldData from '@/models/FieldData.js'
import File from '@/models/File.js'
import FileTile from '@/components/FileUploader/FileTile.vue'
import Draggable from 'vuedraggable'
import Extension from 'ext-name'

export default {
    props: ['value', 'field', 'field_data', 'type', 'multiple'],
    data: function () {
        return {
            label: ''
        }
    },
    created: function () {
        this.label = this.default_label

        // Reappend unsaved file uploads
        this.emitValue()
    },
    computed: {
        inputVal: {
            get() {
                if(this.value === 'true') return true
                if(this.value === 'false') return false
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        files: {
            get() {
                if(this.field_data) {
                    return File.query()
                        .where(file => file.type == this.type && (file.field_data_id == this.field_data.id || (!file.field_data_id && file.id.includes('$'))))
                        .orderBy('position')
                        .get()
                }

                return File.query().where('type', this.type).where('field_id', this.field.id).orderBy('position').get()
            },
            set(files) {
                files.forEach((file, index) => {
                    File.update({
                        where: file.id,
                        data: {
                            position: index + 1
                        }
                    })
                })
            }
        },
        accept: function () {
            if(this.is_image_uploader) return 'image/*'
            return '*'
        },
        max_filesize: function () {
            return 10 * 1000000
        },
        is_image_uploader: function () {
            return this.type === 'image' ? true : false
        },
        default_label: function () {
            switch(this.type) {
                case 'image':
                    return this.multiple ? 'Drag & drop your images' : 'Drag & drop your image'
                case 'file':
                default:
                    return this.multiple ? 'Drag & drop your files' : 'Drag & drop your file'
            }
        }
    },
    methods: {
        selectFile: function () {
            this.$refs.select_file_button.click()
        },
        dragOver: function () {
            this.label = 'Release to upload'
        },
        dragLeave: function () {
            this.label = this.default_label
        },
        setDragging: function (state) {
            if(!state) this.emitValue()
        },
        getOtherImages: function(id) {
            let files_before_index = []
            let files_after_index = []
            if(!this.files || !this.files.length) return []

            const index = this.files.findIndex(file => file.id === id)
            if(index < 0) return []

            for(let i = 0; i < this.files.length; i++) {
                if(this.files[i].type != 'image' || i == index) {
                    continue
                }

                if(i < index) {
                    files_before_index.push(this.files[i])
                } else {
                    files_after_index.push(this.files[i])
                }
            }

            return files_after_index.concat(files_before_index)
        },
        addFiles: function (type, e) {
            this.label = this.default_label
            const files = []
            const event_files = type === 'button' ? e.target.files : e.dataTransfer.files
            if(! event_files.length) return

            // Create new list in case event would be overwritten
            for(var k = 0; k < event_files.length; k++) {
                files.push(event_files[k])
            }

            if(!this.multiple && (files.length) > 1) {
                return Event.dispatch('fileupload.filelimit_exceeded')
            }
            if(!this.multiple && this.files.length > 0) {
                this.files.forEach(file => {
                    this.deleteFile(file.id)
                })
            }
            for(var i = 0; i < files.length; i++) {
                let file = files[i]
                if(!this.isValidFile(file)) continue
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = e => {
                    this.addFile(file, e.target.result)
                }
            }
        },
        deleteFile: function (id) {
            this.$refs.select_file_button.value=null

            File.delete(id).then(file => {
                switch(this.type) {
                    case 'image':
                        this.deleteFieldDataImage(file.id)
                        break
                    case 'file':
                        this.deleteFieldDataFile(file.id)
                        break
                }
                this.emitValue()
            })
        },
        deleteFieldDataImage: function(id) {
            if(this.field_data && Array.isArray(this.field_data.field_data_images)) {
                FieldData.update({
                    where: this.field_data.id,
                    data: { field_data_images : this.deleteFieldDataMediaFile(this.field_data.field_data_images, id) }
                })
            }
        },
        deleteFieldDataFile: function(id) {
            if(this.field_data && Array.isArray(this.field_data.field_data_files)) {
                const items = this.deleteFieldDataMediaFile(this.field_data.field_data_files, id)
                FieldData.update({
                    where: this.field_data.id,
                    data: {
                        field_data_files: items,
                        deleting_file: true
                    }
                })
            }
        },
        deleteFieldDataMediaFile: function(items, id) {
            const index = items.findIndex(item => item.id === id)
            if(index < 0) return items
            items.splice(index, 1)
            return items
        },
        isValidFile: function (file) {
            if(this.is_image_uploader) {
                if(!file.type.includes('image')) {
                    Event.dispatch('fileupload.invalid_filetype')
                    return false
                }
            }
            if(file.size > this.max_filesize) {
                Event.dispatch('fileupload.filesize_exceeded')
                return false
            }
            return true
        },
        addFile: function (uploaded_file, src) {

            let name = uploaded_file.name
            const extension = name.split('.').pop().toLowerCase()
            name = name.split('.').slice(0, -1).join('.') + '.' + extension
            File.insert({
                data: {
                    name: name,
                    type: this.type,
                    extension: this.extension(name),
                    url: src,
                    position: this.files.length+1,
                    field_id: this.field.id,
                    field_data_id: this.field_data ? this.field_data.id : null
                }
            }).then(entities => {
                this.uploadFile(uploaded_file, entities['files'][0])
            })
        },
        uploadFile: function (uploaded_file, file_instance) {
            let formData = new FormData()
            formData.append('id', this.field.id)
            formData.append('uploaded_file', uploaded_file)

            Field.dispatch('uploadFile', formData).then(response => {
                if(!response.success) {
                    File.delete(file_instance.id)
                    Event.dispatch('fileupload.upload_failed')
                }
                File.update({
                    where: file_instance.id,
                    data: {
                        file_id: response.data ? response.data.id : 'failed'
                    }
                })
                this.emitValue()
            })
        },
        emitValue: function () {
            this.inputVal = this.files.map(file => {
                return file.file_id
            })
        },
        extension: function (file) {
            return Extension(file)[0].ext
        }
    },
    components: {
        FileTile,
        Draggable
    }
}
</script>