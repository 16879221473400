<template>
    <div>
        <h2 class="m-b-3 text-align-center">Reset password request</h2>

        <div class="message message-clickable message-success m-0" v-if="success" v-on:click="success=null" v-text="success"></div>
        <div class="message message-clickable message-danger" v-if="error" v-on:click="error=null" v-text="error"></div>

        <form class="form" autocomplete="off" v-on:submit.prevent="requestResetPassword" method="post" v-if="!success">
              <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" id="email" class="text-input" v-model="form.email" required v-autofocus>
              </div>

              <button type="submit" class="button button-block m-0">Send password reset link</button>
        </form>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                form: {
                    email: null
                },
                error: null,
                success: null
            }
        },
        methods: {
            requestResetPassword: function() {
                this.success = this.error = null
                this.$store.dispatch('auth/requestPasswordReset', this.form)
                .then(result => {
                    if(typeof result.data !== 'undefined' && result.data.errors !== 'undefined') {
                        this.error = result.data.errors
                    } else {
                        this.success = result.status
                    }
                }).catch(() => {
                    this.error = 'Something went wrong. Please try again later.'
                });
            }
        }
    }
</script>