<template>
    <div>
        <div v-if="field.options.selection_type == 'dropdown'">
            <select class="select-input" v-bind:class="{ 'select-input-error': field.has_error }" v-model="inputVal">
                <option value="" disabled v-if="show_placeholder">{{ field.options.placeholder }}</option> 
                <option v-for="option in field.options.options" :key="option.value" :value="option.value">{{ option.name }}</option>
            </select>  
        </div>
        <div v-if="field.options.selection_type == 'radio-buttons'" class="m-t-4">
            <label class="radiobutton" v-for="option in field.options.options" :key="option.value"><input type="radio" v-model="inputVal" :value="option.value"> <span>{{ option.name }}</span></label>
        </div>
    </div>
</template>

<script>
export default {
    props: ['field', 'value'],
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        show_placeholder: function () {
            return this.field.options.placeholder ? true : false
        }
    },
    created: function () {
        this.selectDefaultValue()
    },
    methods: {
        selectDefaultValue: function () {
            if(this.value) return
            this.field.options.options.forEach(option => {
                if(option.default) this.inputVal = option.value
            })
        }
    }
}
</script>