<template>
    <label class="toggle">
        <input type="checkbox" v-model="checkbox" v-on:change="emitChange">
        <div class="toggle-slider">
            <div class="toggle-slider-handle"></div>
        </div>
        <div class="label">
            <slot></slot>
        </div>
    </label>
</template>

<script>
    export default {
        props: ['value'],
        data: function () {
            return {
                checkbox: false
            }
        },
        created: function () {
            this.checkbox = this.value
        },
        methods: {
            emitChange: function () {
                this.$emit('input', this.checkbox)
            }
        },
        watch: {
            value: function (state) {
                this.checkbox = state
            }
        }
    }
</script>