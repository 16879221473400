<template>
    <div class="field-validation-rule fade-in">

        <div class="box cursor-pointer box-no-shadow box-border">
            <div class="box-padding">
                <b>{{ rule.rule }}:</b> {{ rule.value }}
                <ul v-if="options">
                    <li v-for="(option_value, option) in options" :key="option"><b>{{getTranslatedOption(option) }}:</b> {{ option_value }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['rule'],
    computed: {
        options: function () {
            return this.rule && this.rule.options ? this.rule.options : {}
        }
    },
    methods: {
        getTranslatedOption: function(option) {
            switch(option) {
                case 'show_indicator':
                    return 'Show indicator'
            }
            return option
        }
    }
}
</script>