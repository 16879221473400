<template>
    <div>
        <div class="page page-light" v-if="module">
            <div class="page-header">
                <div class="page-header-contents">
                    <h2>{{ module.name }}<small>{{ module.identifier }}</small></h2>
                </div>
                <div class="page-header-menu">
                    <ul>
                        <li v-if="is_list"><router-link :to="module.link + '/entries'">Entries</router-link></li>
                        <li v-else><router-link :to="module.link + '/entry'">Data</router-link></li>
                        <li v-if="can_manage_fields"><router-link :to="module.link + '/fields'">Fields</router-link></li>
                        <li v-if="can_manage_relations"><router-link :to="module.link + '/relations'">Relations</router-link></li>
                        <li v-if="can_manage_module"><router-link :to="module.link + '/settings'">Settings</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="page-contents page-contents-with-header" v-on:scroll="contentScroll" ref="contents">
                <BasePreloader v-if="loading"></BasePreloader>
                <router-view class="fade-in" :module="module" :key="module.id" :entry="entry" v-else></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Section from '@/models/Section.js'
import List from '@/models/List.js'
import Entry from '@/models/Entry.js'

export default {
    props: ['project'],
    data: function () {
        return {
            loading: false
        }
    },
    created: function () {
        this.loadModule()
    },
    computed: {
        is_list: function () {
            return this.module && this.module.module_type === 'lists'
        },
        module: function () {
            return this.$route.params.section ? Section.slug(this.$route.params.section) : (this.$route.params.list ? List.slug(this.$route.params.list) : null)
        },
        entry: function () {
            return this.$route.params.entry ? Entry.find(this.$route.params.entry) : null
        },
        can_manage_module: function () {
            const type = this.is_list ? 'list' : 'section'
            return this.can(`update-${type}`) || this.can(`delete-${type}`)
        },
        can_manage_fields: function () {
            return this.can('create-field') || this.can('update-field')
        },
        can_manage_relations: function () {
            return this.can('create-relation') || this.can('update-relation')
        }
    },
    methods: {
        contentScroll: function () {
            this.$store.commit('ui/setContentScrollPosition', this.$refs.contents.scrollTop)
        },
        loadModule: function () {
            this.loading = true
            switch(this.module.module_type) {
            case 'sections':
                return this.loadSection()
            case 'lists':
                return this.loadList()
            }
        },
        loadSection: function () {
            Section.dispatch('single', {
                id: this.module.id,
                with: [
                    'fields.field_data.field_data_files',
                    'fields.field_data.parent_field_data_images',
                    'fields.field_data.field_data_translations.project_language',
                    'relations.related_module',
                    'relations.entries.field_data.field',
                ]
            }).then(() => {
                this.loading = false
            })
        },
        loadList: function () {
            List.dispatch('single', {
                id: this.module.id,
                with: ['fields', 'relations.related_module']
            }).then(() => {
                this.loading = false
            })
        }
    },
    watch: {
        module: function (newModule, oldModule) {
            if(typeof newModule === 'undefined' || !newModule) {
                return
            }
            if(typeof oldModule === 'undefined' || newModule.id !== oldModule.id || newModule.module_type !== oldModule.module_type) {
                this.loadModule()
            }
        }
    }
}
</script>
