import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import AddProject from '../views/AddProject.vue'
import SettingsProject from '../views/Project/Settings/Project.vue'
import SettingsMembers from '../views/Project/Settings/Members.vue'
import Sections from '../views/Project/Sections.vue'
import AddSection from '../views/Project/AddSection.vue'
import Lists from '../views/Project/Lists.vue'
import AddList from '../views/Project/AddList.vue'
import Module from '../views/Project/Module.vue'
import Entries from '../views/Project/Module/Entries.vue'
import ModuleEntry from '../views/Project/Module/Entry.vue'
import ModuleFields from '../views/Project/Module/Fields.vue'
import ModuleRelations from '../views/Project/Module/Relations.vue'
import ModuleSettings from '../views/Project/Module/Settings.vue'
import Log from '../views/Project/Log.vue'
import Invitation from '../views/Invitation.vue'
import Team from '../views/Team.vue'
import Billing from '../views/Billing.vue'
import PageNotFound from '../views/PageNotFound.vue'
import SignIn from '../views/SignIn.vue'
import SignOut from '../views/SignOut.vue'
import Bye from '../views/Bye.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'projects',
    },
    {
        path: '/projects',
        component: Projects,
        meta: {
            title: 'Projects'
        }
    },
    {
        path: '/add/project',
        component: AddProject,
        meta: {
            title: 'Add project'
        }
    },
    {
        path: '/projects/:project',
        component: Project,
        children: [
            {
                path: '/',
                redirect: 'sections'
            },
            {
                path: 'sections',
                component: Sections,
            },
            {
                path: 'add/section',
                component: AddSection,
            },
            {
                path: 'sections/:section',
                component: Module,
                children: [
                    {
                        path: '/',
                        redirect: 'entry'
                    },
                    {
                        path: 'entry',
                        component: ModuleEntry
                    },
                    {
                        path: 'fields',
                        component: ModuleFields
                    },
                    {
                        path: 'relations',
                        component: ModuleRelations
                    },
                    {
                        path: 'settings',
                        component: ModuleSettings
                    },
                ]
            },
            {
                path: 'lists',
                component: Lists,
            },
            {
                path: 'add/list',
                component: AddList,
            },
            {
                path: 'lists/:list',
                component: Module,
                children: [
                    {
                        path: '/',
                        redirect: 'entries'
                    },
                    {
                        path: 'entries',
                        component: Entries
                    },
                    {
                        path: 'entries/new',
                        component: ModuleEntry
                    },
                    {
                        path: 'entries/:entry',
                        component: ModuleEntry
                    },
                    {
                        path: 'fields',
                        component: ModuleFields
                    },
                    {
                        path: 'relations',
                        component: ModuleRelations
                    },
                    {
                        path: 'settings',
                        component: ModuleSettings
                    },
                ]
            },
            {
                path: 'settings',
                component: SettingsProject,
            },
            {
                path: 'settings/members',
                component: SettingsMembers,
            },
            {
                path: 'log',
                component: Log,
            },
        ]
    },
    {
        path: '/team',
        component: Team,
        meta: {
            title: 'Team'
        }
    },
    {
        path: '/billing',
        component: Billing,
        meta: {
            title: 'Billing'
        }
    },

    // Auth
    {
        path: '/signin',
        component: SignIn,
        meta: {
            title: 'Welcome',
            guest: true
        }
    },
    {
        path: '/signout',
        component: SignOut
    },
    {
        path: '/passwordforgot',
        component: ForgotPassword,
        meta: {
            title: 'Reset password request',
            guest: true
        }
    },
    {
        path: '/passwordreset',
        component: ResetPassword,
        meta: {
            title: 'Reset password',
            guest: true
        }
    },

    // Misc
    {
        path: '/bye',
        component: Bye,
        meta: {
            title: 'See you next time!',
            guest: true
        }
    },
    {
        path: '/invitations/team/:token',
        component: Invitation,
         meta: {
            title: 'Team invitation',
            guest: true
        }
    },
    {
        path: '/invitations/project/:token',
        component: Invitation,
         meta: {
            title: 'Project invitation',
            guest: true
        }
    },
    {
        path: "*",
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
