<template>
    <div class="field-form" v-if="visible">
        <div class="form-group form-group-with-icon">
            <div class="form-group-icon"><v-icon :name="field.icon"></v-icon></div>
            <label>
                {{ field.name }}
                <small v-if="field.options && field.options.required">Required</small>
            </label>
            <TextFieldForm v-if="isType('text')" :field="field" v-model="value" :translations="translations" v-on:translate="updateTranslations" :active_language="active_language"></TextFieldForm>
            <TextareaFieldForm v-if="isType('textarea')" :field="field" v-model="value" :translations="translations" v-on:translate="updateTranslations" :active_language="active_language"></TextareaFieldForm>
            <CheckboxFieldForm v-if="isType('checkbox')" :field="field" v-model="value"></CheckboxFieldForm>
            <SelectFieldForm v-if="isType('select')" :field="field" v-model="value"></SelectFieldForm>
            <ImageFieldForm v-if="isType('image')" :field="field" :field_data="field_data" v-model="value"></ImageFieldForm>
            <FileFieldForm v-if="isType('file')" :field="field" :field_data="field_data" v-model="value"></FileFieldForm>

            <span v-if="field.description"><p class="small m-t-1 m-b-3">{{ field.description }}</p></span>
            <div class="field-form-group" v-if="isType('group')">
                <FieldForm v-for="grouped_field in grouped_fields" :entry="entry" :key="grouped_field.id" :field="grouped_field" :active_language="active_language"></FieldForm>
            </div>
        </div>
    </div>
</template>

<script>
import Project from '@/models/Project.js'
import Field from '@/models/Field.js'
import FieldData from '@/models/FieldData.js'
import TextFieldForm from '@/components/FieldForms/Text.vue'
import TextareaFieldForm from '@/components/FieldForms/Textarea.vue'
import CheckboxFieldForm from '@/components/FieldForms/Checkbox.vue'
import SelectFieldForm from '@/components/FieldForms/Select.vue'
import ImageFieldForm from '@/components/FieldForms/Image.vue'
import FileFieldForm from '@/components/FieldForms/File.vue'
import Event from '@/libraries/eventbus'

export default {
    name: 'FieldForm',
    props: ['field', 'entry', 'active_language'],
    data: function () {
        return {
            value: '',
            translations: {}
        }
    },
    created: function () {
        this.insertFieldData()
        this.inheritValue()
        this.initListeners()
    },
    beforeDestroy: function () {
        Event.off(this.event_listener_name)
    },
    computed: {
        project: function () {
            return Project.slug(this.$route.params.project)
        },
        entry_id: function () {
            return this.entry ? this.entry.id : null
        },
        event_listener_name: function () {
            return 'field_form.update_field_data.' + this.field.module_id
        },
        field_data: function () {
            const field_data = FieldData.query().where('field_id', this.field.id).where('entry_id', this.entry_id).get()
            if(!field_data || !field_data.length) return null
            field_data.sort((a,b) => {
                if(b.creating) return -1
                if(a.creating) return 1
                return 0
            })
            return field_data[0]
        },
        grouped_fields: function () {
            return Field.query().where('group_id', this.field.id).orderBy('position').withAll().get()
        },
        visible: function () {
            if(!this.field_data) return false
            if(this.isType('group') && !this.grouped_fields.length) return false
            return true
        }
    },
    methods: {
        insertFieldData: function () {
            if(this.field_data) {
                this.field_data.translations = this.project.project_languages.reduce((o, key) => ({ ...o, [key]: typeof this.field_data.translations[key] !== 'undefined' ? this.field_data.translations[key] : null }), {})
                return
            }

            FieldData.insert({
                data: {
                    field_id: this.field.id,
                    entry_id: this.entry_id,
                    value: this.field.options.default_value ? this.field.options.default_value : null,
                    translations: this.project.project_languages.reduce((o, key) => ({ ...o, [key]: null }), {})
                }
            })
        },
        inheritValue: function () {
            switch(this.field.type) {
                case 'checkbox':
                    var default_value = this.field.options.default_state ? this.field.options.default_state : false
                    break
                default:
                    default_value = this.field.options.default_value ? this.field.options.default_value : null
                    break
            }

            this.value = this.field_data.creating && !this.field_data.value ? default_value : this.getValue()
            this.translations = this.field_data.translations
        },
        initListeners: function () {
            Event.on(this.event_listener_name, () => {
                FieldData.update({ where: this.field_data.id, data: { value: this.value, translations: this.translations } })
            })
        },
        getValue: function () {
            switch(this.field.type) {
                case 'checkbox':
                    if(this.value === '' && this.field_data.value == 0) return false
                    return this.field_data.value && ['', 0].includes(this.value) ? true : false
            }
            return this.field_data.value
        },
        updateTranslations: function (translations) {
            this.translations = translations
        },
        isType: function (type) {
            return this.field.type === type
        },
        openEditor: function () {
            this.$store.commit('ui/setContentBar', { type: 'field.edit', data: this.field.id })
        }
    },
    components: {
        TextFieldForm,
        TextareaFieldForm,
        CheckboxFieldForm,
        SelectFieldForm,
        ImageFieldForm,
        FileFieldForm
    }
}
</script>