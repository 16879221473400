import { Model } from '@vuex-orm/core'

export default class Role extends Model {
    static get entity() {
        return 'roles'
    }

    static state() {
        return {
            loading: false
        }
    }

    static fields() {
        return {
            id: this.attr(),
            name: this.attr(),
            identifier: this.attr(),
            position: this.number(1),
        }
    }
}
