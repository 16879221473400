<template>
    <div class="page page-light">
        <div class="page-contents">
            <div class="padding">
                <router-link :to="'/'" class="back">
                    <v-icon name="chevron-left"></v-icon>
                    Back to projects
                </router-link>

                <div class=" flex flex-center m-b-3">
                    <h2 class="flex-1 m-b-0">Team members</h2>
                    <a class="button" v-on:click="openNewMemberModal()">Add member</a>
                </div>

                <p class="lead">Here you can add or update members to work on this team.</p>

                <div class="table table-border" v-if="has_members">
                    <div class="table-head">
                        <div class="table-tr">
                            <div class="table-td">Member</div>
                            <div class="table-td">Role</div>
                            <div class="table-td">Status</div>
                            <div class="table-td text-align-right"></div>
                        </div>
                    </div>
                    <div class="table-body">
                        <div class="table-tr" v-for="member in members" :key="member_component_key + '_' + member.id">
                            <Member :team="team" :member="member" v-on:toggle-dropdown="toggleDropDown"></Member>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Member from '@/components/Tables/TeamMember.vue'
import Modal from '@/models/Modal.js'
import Team from '@/models/Team.js'

export default {
    data: function () {
        return {
            members: [],
            member_component_key: 0,
            // modals: {
            //     new_member: false
            // }
        }
    },
    created: function () {
        this.loadTeam()
        if(this.team) {
            this.setMembers(this.team.members)
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        team: function () {
            return this.user.team
        },
        has_members: function() {
            return this.members && this.members.length
        }
    },
    methods: {
        loadTeam: function() {
            if (!this.team ) {
                return this.$router.push('/')
            }

            Team.dispatch('single', {
                id: this.user.selected_team_id,
            }).then(() => {
                this.loading = false
            })
        },
        setMembers: function(users) {
            const members = users && users.length ? users : []
            this.members = members.map(member => {
                member.actions_active = false
                return member
            })
        },
        openNewMemberModal: function () {
            Modal.dispatch('launch', {
                type: 'member.editor',
                name: 'Add new member',
                variant: 'small',
                data: {
                    type: 'team',
                    team_id: this.team.id
                }
            })
        },
        toggleDropDown: function(member_id) {
            const members = this.members.map(member => {
                member.actions_active = member.id == member_id ? !member.actions_active : false
                return member
            })

            this.$nextTick(function () {
                this.members = members
                this.member_component_key ++
            })
        }
    },
    watch: {
        'team.members': function (users) {
            this.setMembers(users)
        }
    },
    components: {
        Member
    }
}
</script>
