<template>
    <div class="box cursor-pointer box-no-shadow box-border text-align-center draggable" v-on:click="openModule">
        <div class="box-padding p-t-10 p-b-10">
            <div class="box-indicator">
                <v-icon name="lock" v-if="module.admin_only" v-tooltip="'Only admin'"></v-icon>
            </div>
            <BoxActions>
                <li v-on:click.stop><router-link :to="module.link">Open module</router-link></li>
                <li v-on:click.stop><router-link :to="module.link + '/fields'">Fields</router-link></li>
                <li v-on:click.stop><router-link :to="module.link + '/relations'">Relations</router-link></li>
                <li v-on:click.stop><router-link :to="module.link + '/settings'">Settings</router-link></li>
            </BoxActions>
            <div class="box-icon"><v-icon :name="module.icon"></v-icon></div>
            <h4 class="m-b-1">{{ module.name }}</h4>
            <h5 class="m-0"><small class="l-0">{{ module.identifier }}</small></h5>
        </div>
    </div>
</template>

<script>
import BoxActions from '@/components/BoxActions.vue'

export default {
    props: ['module'],
    methods: {
        openModule: function () {
            this.$router.push({
                path: this.module.link
            })
        }
    },
    components: {
        BoxActions
    }
}
</script>
