<template>
    <div class="fade-in">
        <div class="padding">
            <div class="fade-in" v-if="relations.length">
                <div class="actions">
                    <a class="button" v-on:click="addRelation">Add relation</a>
                </div>
                <h3>Relations <small>{{ relations.length }}</small></h3>
                <Draggable class="relation-tiles" v-model="relations" handle=".draggable-handle" ghost-class="ghost" group="relations" v-on:start="setDragging(true)" v-on:end="setDragging(false)">
                    <RelationTile v-for="relation in relations" :key="relation.id" :relation="relation"></RelationTile>
                </Draggable>
            </div>
            <div v-else>
                <BaseNoResults>
                    <v-icon name="link"></v-icon>
                    <div v-if="can('create-relation')">
                        <h4 class="m-b-3">Add your first relation</h4>
                        <p>Create you first relation in this section.</p>
                        <a class="button" v-on:click="addRelation">Add relation</a>
                    </div>
                    <div v-else>
                    <h4 class="m-b-3">No relations created</h4>
                    <p>Please contact an administrator.</p>
                </div>
                </BaseNoResults>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Relation from '@/models/Relation.js'
import RelationTile from '@/components/RelationTile.vue'
import Draggable from 'vuedraggable'

export default {
    props: ['module'],
    data: function () {
        return {
            relation_positions: null
        }
    },
    created: function () {
        this.relation_positions = _.map(this.relations, function (relation) {
            return relation.id
        })
    },
    computed: {
        relations: {
            get() {
                return Relation.query().where('module_id', this.module.id).where('module_type', this.module.module_type).withAll().orderBy('position').get()
            },
            set(relations) {
                Relation.dispatch('updatePositions', { relations: relations })
            }
        }
    },
    methods: {
        addRelation: function () {
            this.$store.commit('ui/setContentBar', { type: 'relation.add', data: { module: this.module } })
        },
        setDragging: function (state) {
            this.$store.commit('ui/setDragging', state)
        }
    },
    components: {
        RelationTile,
        Draggable
    }
}
</script>
