import _ from 'lodash'
import Event from '@/libraries/eventbus.js'
import ApiService from '@/libraries/api'
import Field from '@/models/Field'
import FieldData from '../../models/FieldData'

export default {
    state: {
        active: null,
        updating_positions: false
    },
    actions: {
        async post(store, params) {
            return ApiService.post('/fields', params).then(response => {
                if (!response.error) Field.insert(response.data)
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        async patch(store, params) {
            return ApiService.patch('/fields/' + params.id, params).then(response => {
                if (!response.error) Field.insertOrUpdate(response.data)
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        async remove(store, params) {
            return ApiService.delete('/fields/' + params.id).then(response => {
                if (!response.error) Field.delete(params.id)
            }).catch(error => error.response)
        },

        async updatePositions(store, payload) {
            const trigger_event = !store.state.updating_positions
            const params = {
                fields: payload.fields.map(field => {
                    return field.id
                }),
                group_id: payload.group_id
            }

            _.each(payload.fields, function (field, index) {
                Field.update({
                    where: field.id,
                    data: {
                        position: index + 1,
                        group_id: payload.group_id
                    }
                })
            })

            store.commit('setUpdatingPositions', true)

            return ApiService.post('/fields/updatepositions', params).then(response => {
                if (!response.error) {
                    if(trigger_event) {
                        Event.dispatch('field.positions_updated')
                    }
                    Field.insertOrUpdate(response.data)
                    return response.data
                }
            })
            .catch(error => error.response)
            .then(response => {
                store.commit('setUpdatingPositions', false)
                return response
            })
        },

        async updateEntryViewPositions(store, payload) {
            const params = {
                fields: payload.fields.map(field => field.id)
            }

            return ApiService.post('/fields/' + payload.list_id + '/updatepositions', params).then(response => {
                if (!response.error) {
                    Event.dispatch('field.entry_view_positions_updated')
                    return response.data
                }
            }).catch(error => error.response)
        },

        async saveFieldsData(store, params) {
            return ApiService.post('/fielddata', params).then(response => {
                if (!response.error) FieldData.insertOrUpdate(response.data)
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        },

        async uploadFile(store, params) {
            return ApiService.post('/fields/' + params.get('id') + '/upload', params).then(response => {
                return response
            })
            .catch(error => error.response)
            .then(response => this._vm.response(response))
        }
    },
    mutations: {
        setUpdatingPositions: function (state, value) {
            state.updating_positions = value
        }
    }
}