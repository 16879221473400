<template>
    <div class="fade-in">
        <div>
            <EntryEditor :module="module" :entry_id="entry_id" v-on:saved="saved" v-on:deleted="deleted" v-on:loaded="loaded"></EntryEditor>
        </div>
    </div>
</template>

<script>
import Field from '@/models/Field.js'
import EntryEditor from '@/components/EntryEditor.vue'

export default {
    props: ['module'],
    data: function () {
        return {
            loading: true
        }
    },
    computed: {
        entry_id: function () {
           return this.$route.params.entry ? this.$route.params.entry : null
        },
        fields_count: function () {
            return Field.query().where('module_id', this.module.id).where('module_type', this.module.module_type).count()
        },
    },
    methods: {
        saved: function (response) {
            if(response.success && response.new_entry) this.$router.push({ path: this.module.link + '/entries' })
        },
        deleted: function () {
            this.$router.push({
                path: this.module.link + '/entries'
            })
        },
        loaded: function () {
            this.loading = false
        }
    },
    components: {
        EntryEditor
    },
}
</script>
